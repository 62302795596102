import React, { Component } from 'react'
import IconService from './../../services/IconService'
import './../../assets/css/custom/widjets/SmallBoxedWidjetComponent.css'
import TranslationAgent from '../../translations/TranslationAgent';
import EncryptStorage from './../../storage/EncryptStorage'

class SmallBoxedWidjetComponent extends Component {
    constructor(props) {
        // eslint-disable-next-line
        super(props);
    }



    isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

    render() {

        const params = {
            justification: 'L',
            locales: 'it-IT',
        };

        let customStyles = {};
        if (this.props.color != null) {
            customStyles["color"] = this.props.color;
        }

        let buttons = [];
        if (this.props.buttons != null) {
            this.props.buttons.forEach((item, i) => {
                buttons.push(
                    <div key={i} className="button-boxed-widjet" onClick={() => {
                        window.location.href = "#/" + item.redirectUrl;
                    }}>{item.buttonLabel}</div>);
            });
        }

        let processedTitle = title;
        let title = this.props.title;
        if (title != null && title != undefined) {

            if (title.indexOf("CO₂") != -1) {

                processedTitle = title.replace("CO₂", "<span style='color:var(--eon-blue)'>CO₂</span>");
            } else if (title.indexOf("CYTD") != -1) {
                processedTitle = title.replace("CYTD", "<span style='color:var(--eon-blue)'>CYTD</span>");
            } else if (title.indexOf("YTD") != -1) {
                processedTitle = title.replace("YTD", "<span style='color:var(--eon-blue)'>YTD</span>");
            } else if (title.indexOf("EUR") != -1) {
                processedTitle = title.replace("EUR", "<span style='color:var(--eon-blue)'>EUR</span>");
            }
            else {
                processedTitle = title;
            }
        }



        return (
            <div>
                <div className={this.props.maxHeightclass}>
                    <div className="uppertitle-boxed-widget" style={customStyles} dangerouslySetInnerHTML={{ __html: processedTitle }}></div>
                    <div className="rebar-boxed-widget"></div>

                    <div style={this.props.hideWidget == true ? { "opacity": "0", "userSelect": "none" } : {}}>
                        {
                            <div className="biglabel-boxed-widget" style={customStyles}>{
                                this.props.label}</div>
                        }
                        <div className="underlabel-boxed-widget" style={customStyles}>{this.props.underlabel}</div>

                        {this.props.emptyExtraContentLabel == null && (
                            <div className="extracontent-boxed-widget">{this.props.extracontent}</div>                            
                        )}

                        {this.props.emptyExtraContentLabel != null && (
                            <div className="extracontent-boxed-widget"><div className="extracontent-no-data">{this.props.emptyExtraContentLabel}</div></div>
                        )}

                        {this.props.largerSubtitle != null && this.props.largerSubtitle != undefined && (
                            <div className="larger-subtitle-boxed-widget">{this.props.largerSubtitle}</div>
                        )}

                        <div className="subtitle-boxed-widget">{this.props.subtitle}</div>
                        <div className="image-boxed-wrapper">
                            {this.props.imageSrc != null && (<img className="image-boxed-widget" src={IconService[this.props.imageSrc]} />)}
                        </div>
                    </div>

                </div>

                <div className="button-boxed-widjet-wrapper">
                    {buttons}
                </div>
            </div>
        )
    }
}

export default SmallBoxedWidjetComponent