import React, { Component } from 'react'
import ColoredTableComponent from './../ColoredTableComponent'
import ErrorService from './../../services/ErrorService'
import ApiService from './../../services/ApiService'
import IconService from './../../services/IconService'
import { toast } from 'react-toastify';
import TranslationAgent from './../../translations/TranslationAgent'
import moment from 'moment'
import PDFViewer from 'pdf-viewer-reactjs'
import './../../assets/css/custom/widjets/ListaFattureWidjetComponent.css'
import 'bulma/css/bulma.css'
import 'material-design-icons/iconfont/material-icons.css'
import { parseJwt } from './../../services/ApiService'
import EncryptStorage from './../../storage/EncryptStorage'

class ListaFattureWidjetComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: [],
            pdfContent: null,
            currentPDFViewedPath: null,
            currentPDFViewedName: null,
            toggleSelect: true,
            indexToDelete: -1,
            invoceIdToDelete: ""
        }

        window.ListaFattureWidjetComponent = this;
        this.handleChangedRowItem = this.handleChangedRowItem.bind(this);
        this.expandRelevantFile = this.expandRelevantFile.bind(this);
        this.handleEreaseRowItem = this.handleEreaseRowItem.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.downloadMultiple = this.downloadMultiple.bind(this);
        this.toggleSelect = this.toggleSelect.bind(this);
        this.updateInvoice = this.updateInvoice.bind(this);
    }

    updateInvoice(){
        ApiService.toggleSpinner(true);
        const selElements = document.getElementsByTagName("select");
        const elements = [];

        for (let i = 0; i < selElements.length; i++) {
            const currSel = document.getElementById("sel-opt-" + i)
            if (currSel) {
                elements.push({
                    InvoiceId: this.state.data[i].invoiceId,
                    Status: currSel.value === "true" ? 'Paid' : 'Not Paid'                    
                })
            }

        }

        const payload = {
            UserId: this.getUserId(),
            InputXML: {
                Elements: elements,
            }
        }

        ApiService.updateInvoice(payload).then((res) => {
            if (res.raw.status === 200) {
                toast.success(TranslationAgent.localizeString("invoice_updated"), {
                    hideProgressBar: true,
                    autoClose: true,
                    draggable: true,
                })
            } else {
                let msg = ErrorService.extractError(res);
                if (msg)
                toast.error(msg, {
                    hideProgressBar: true,
                    autoClose: true,
                    draggable: true,
                })
            }
        })
            .finally(() => {
                ApiService.toggleSpinner(false);
            });
    }

    confirmEraseRow(rowindex) {
        let invoiceId = this.state.data[rowindex].invoiceId;
        let userId = JSON.parse(EncryptStorage.getItem("session-data"))?.userId
        let proformaPath = this.state.data[rowindex].proformaPath;
        let countPath = this.state.data[rowindex].countPath;
        /* ApiCall to update status */
        ApiService.toggleSpinner(true);
        ApiService.deleteInvoice({
            InvoiceId: invoiceId,
            UserId: +userId,
            InvoicePath: proformaPath,
            CountPath: countPath
        }).then((res) => {
            if (this.props.onIsReady) { this.props.onIsReady(); }

            if (res.raw.status === 200) {

                setTimeout(() => {
                    window.App.reloadApp();

                }, 1000);

                toast.success(TranslationAgent.localizeString("invoice_deleted"), {
                    hideProgressBar: true,
                    autoClose: true,
                    draggable: true,
                })
            } else {

                let msg = ErrorService.extractError(res);
                if (msg)
                toast.error(msg, {
                    hideProgressBar: true,
                    autoClose: true,
                    draggable: true,
                })
            }
        }).finally(() => {
            ApiService.toggleSpinner(false);
        })
    }

    handleEreaseRowItem(rowindex, invoiceId) {
        let v = document.getElementById("confirmDelete");
        if (v != null) {
            v.style.display = "block";
        }

        this.setState({ indexToDelete: rowindex, invoiceIdToDelete: invoiceId });
    }

    handleChangedRowItem(node, rowindex) {
        // let invoiceId = this.state.data[rowindex].invoiceId;
        // let userId = JSON.parse(EncryptStorage.getItem("session-data"))?.userId
        // let status = node.value.toString() == "true" ? "Paid" : "Not Paid";

        // /* ApiCall to update status */
        // ApiService.toggleSpinner(true);
        // ApiService.UpdateInvoiceState({
        //     InvoiceId: invoiceId,
        //     UserId: +userId,
        //     State: status
        // }).then((res) => {



        //     if (res.raw.status === 200) {

        //         toast.success(TranslationAgent.localizeString("invoice_updated"), {
        //             hideProgressBar: true,
        //             autoClose: true,
        //             draggable: true,
        //         })
        //     } else {
        //         let msg = ErrorService.extractError(res);
        //         toast.error(msg, {
        //             hideProgressBar: true,
        //             autoClose: true,
        //             draggable: true,
        //         })
        //     }
        // }).finally(() => {
        //     ApiService.toggleSpinner(false);
        // })
    }

    getUserId() {
        return JSON.parse(EncryptStorage.getItem("session-data"))?.userId;
    }

    base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }


    saveByteArray(reportName, byte, type = "application/pdf") {
        var blob = new Blob([byte], { type: type });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = reportName;
        link.download = fileName;
        link.click();
    };

    downloadFile(filePath, fileName, invoiceId) {
        ApiService.toggleSpinner(true)
        ApiService.downloadFileInvoice({
            UserId: this.getUserId(),
            FilePath: filePath,
            Filename: fileName,
            InvoiceId: invoiceId
        }).then((res) => {
            var sampleArr = this.base64ToArrayBuffer(res.parsed?.value);
            this.saveByteArray(fileName, sampleArr);
        }).finally(() => {
            ApiService.toggleSpinner(false);
        })
    }

    expandRelevantFile(filePath, fileName, invoiceId) {
        ApiService.toggleSpinner(true)
        ApiService.downloadFileInvoice({
            UserId: this.getUserId(),
            FilePath: filePath,
            Filename: fileName,
            InvoiceId: invoiceId
        }).then((res) => {
            this.setState({
                currentPDFViewedPath: filePath,
                currentPDFViewedName: fileName,
                pdfContent: res.parsed?.value
            });

            let v = document.getElementById("pdfPreview");
            if (v != null) {
                v.style.display = "block";
            }
        }).finally(() => {
            ApiService.toggleSpinner(false);
        })

    }

    isVisibleAdmin() {

        const b2cToken = JSON.parse(EncryptStorage.getItem("session-data"))?.B2CToken;
        if (b2cToken) {
            const b2cToken = JSON.parse(EncryptStorage.getItem("session-data")).B2CToken;
            if (b2cToken) {
                const parsedToken = parseJwt(b2cToken);
                return parsedToken.idp != null;
            }

            return false;
        }
    }

    componentDidMount() {

        let plantId = EncryptStorage.getItem('requested-plant-location');
        let userId = JSON.parse(EncryptStorage.getItem("session-data"))?.userId
        let role = this.isVisibleAdmin() ? EncryptStorage.getItem("requested-show-mode") : "";
        if (plantId != null) {

            /* ApiCall to update status */
            ApiService.widgetApi({
                "name": "ListaFattureWidjetComponent", payload: {
                    PlantId: +plantId,
                    UserId: +userId,
                    Start: EncryptStorage.getItem('requested-max-billing-from'),
                    End: EncryptStorage.getItem('requested-max-billing-to'),
                    Role: role,
                }
            }).then((res) => {
                let data = [];
                if (res != null) {
                    if (res.parsed != null) {
                        data = res.parsed;
                    }
                }
                this.setState({ data: data, isLoading: false });
            });
        }
    }



    toggleSelect() {
        const checks = document.getElementsByTagName("input");

        for (let i = 0; i < checks.length; i++) {
            if (checks[i].id.indexOf("check-download-") >= 0) {
                const invoiceId = checks[i].id.split('-')[2];
                const elem = document.getElementById("check-download-" + invoiceId)
                if (elem) {
                    elem.checked = this.state.toggleSelect
                }
            }
        }

        this.setState({
            toggleSelect: !this.state.toggleSelect
        })
    }


    downloadMultiple() {
        const checks = document.getElementsByTagName("input");
        const files = []
        for (let i = 0; i < checks.length; i++) {
            if (checks[i].checked) {
                const invoiceId = checks[i].id.split('-')[2];
                const elem = this.state.data.find((value) => { return value.invoiceId == invoiceId })
                if (elem && elem) {
                    files.push({
                        FilePath: elem.proformaPath,
                        Filename: elem.proformaName,
                        InvoiceId: elem.invoiceId,                        
                    })
                    files.push({
                        FilePath: elem.countPath,
                        Filename: elem.proformaName,
                        InvoiceId: elem.invoiceId,                        
                    })
                }
            }
        }
        if (files.length > 0) {
            ApiService.toggleSpinner(true);
            ApiService.multipleDownloadFileInvoice({
                UserId: this.getUserId(),
                Files: files
            }).then((res) => {
                if (res && res.parsed && res.parsed.value) {
                    var sampleArr = this.base64ToArrayBuffer(res.parsed?.value);
                    this.saveByteArray("Invoices.zip", sampleArr);
                }
            }).finally(() => {
                ApiService.toggleSpinner(false);
            })
        }
    }


    render() {

        let isAdmin = this.isVisibleAdmin() && !window.testIsSuperAdmin();

        let headers = [
            TranslationAgent.localizeString("download_select"),
            TranslationAgent.localizeString("emission_date"),
            TranslationAgent.localizeString("n_invoice"),
            TranslationAgent.localizeString("table_plant"),
            TranslationAgent.localizeString("cost"),
            TranslationAgent.localizeString("state"),
            TranslationAgent.localizeString("state_paid"),
            TranslationAgent.localizeString("table_invoice"),
            TranslationAgent.localizeString("table_attach"),
            ""
        ];

        if (!isAdmin) {
            headers.pop();
        }

        let excludeExportHeader = [
            TranslationAgent.localizeString("download_select"),
            TranslationAgent.localizeString("state"),
            TranslationAgent.localizeString("table_invoice"),
            TranslationAgent.localizeString("table_attach"),
        ]

        let hiddenHeaders = [
            6
        ]

        let rows = [];
        let paidStr = TranslationAgent.localizeString("paid");
        let notPaidStr = TranslationAgent.localizeString("not_paid");

        this.state.data.forEach((item, i) => {

            let isPayd = item.invoiceState.toLowerCase() == "paid";

            let paydItem = isPayd ? TranslationAgent.localizeString("paid") : TranslationAgent.localizeString("not_paid");

            paydItem = "<select " + (isAdmin ? "" :"disabled") + " id=\"sel-opt-" + i + "\" onchange=\"window.ListaFattureWidjetComponent.handleChangedRowItem(this," + i + ");\"><option " + (isPayd ? "selected=\"true\"" : "") + " value=\"true\">" + paidStr + "</option><option " + (!isPayd ? "selected=\"true\"" : "") + "value=\"false\">" + notPaidStr + "</option></select>"
            
            let iconDownload = "<div onclick=\"window.ListaFattureWidjetComponent.downloadFile('" + item.proformaPath + "', '" + item.proformaName + "' , '" + item.invoiceId + "');\"><img class=\"small-in-table-icon\" src=\"" + IconService.pdf_download_icon + "\"  /></div>";
            let iconExpand = "<img class=\"small-in-table-icon\" src=\"" + IconService.search_icon + "\" onclick=\"window.ListaFattureWidjetComponent.expandRelevantFile('" + item.proformaPath + "', '" + item.proformaName + "', '" + item.invoiceId + "');\" />";

            let iconDownload2 = "<div onclick=\"window.ListaFattureWidjetComponent.downloadFile('" + item.countPath + "', '" + item.countName + "', '" + item.invoiceId + "');\"><img class=\"small-in-table-icon\" src=\"" + IconService.pdf_download_icon + "\" /></div>";
            let iconExpand2 = "<img class=\"small-in-table-icon\" src=\"" + IconService.search_icon + "\" onclick=\"window.ListaFattureWidjetComponent.expandRelevantFile('" + item.countPath + "', '" + item.countName + "', '" + item.invoiceId + "');\" />";


            let row = [
                "<input type='checkbox' id='check-download-" + item.invoiceId + "'>",
                moment(item.releaseDate).format("LL"),
                item.invoiceId,
                item.impianto,
                Intl.NumberFormat(EncryptStorage.getItem("language"), { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.invoiceValue),
                paydItem,
                isPayd ? TranslationAgent.localizeString("paid") : TranslationAgent.localizeString("not_paid"),
                item.proformaPath ? iconExpand + iconDownload + "<button style='display:none'></button>" : "<div></div>",
                item.countPath ? iconExpand2 + iconDownload2 + "<button style='display:none'></button>" : "<div></div>",
                "<button class=\"table-inrow-button\" onclick=\"window.ListaFattureWidjetComponent.handleEreaseRowItem(" + i + ", '" + item.invoiceId + "');\">" + TranslationAgent.localizeString("erease") + "</button>"
            ];

            if (!isAdmin) {
                row.pop();
            }

            rows.push(row);
        });

        return (
            <div>
                {this.isVisibleAdmin()?"CIAO":"NOO"}
                {
                    this.state.pdfContent &&
                    <div id="pdfPreview" class="modal" tabindex="-1" role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" class="close" onClick={() => { let v = document.getElementById("pdfPreview"); if (v != null) { v.style.display = "none" } }} data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body" style={{ minWidth: "auto", maxHeight: "auto", overflowY: "scroll" }}>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="button-boxed-widjet" style={{ "margin": "2em" }} onClick={() => {
                                                this.downloadFile(this.state.currentPDFViewedPath, this.state.currentPDFViewedName)
                                            }}>
                                                {TranslationAgent.localizeString("download_pdf")
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <PDFViewer document={{ base64: this.state.pdfContent }}></PDFViewer>
                                        </div>
                                        <div className="col-md-10">
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    <div id="confirmDelete" class="modal" tabindex="-1" role="dialog">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content" style={{ width: "40em" }}>
                                <div class="modal-body">
                                    <p>{TranslationAgent.localizeString("modal_delete_invoice") + this.state.invoiceIdToDelete + "?"} </p>
                                </div>
                                <div class="modal-footer" style={{ justifyContent: "flex-end" }}>
                                    <div style={{ alignSelf: "center" }} className="general-top-widjet-button" onClick={() => { this.confirmEraseRow(this.state.indexToDelete) }}>{TranslationAgent.localizeString("confirm")}</div>
                                    <div style={{ alignSelf: "center" }} className="general-top-widjet-button" onClick={() => { let v = document.getElementById("confirmDelete"); if (v != null) { v.style.display = "none" } }}>{TranslationAgent.localizeString("undo")}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-md-4" style={{ display: "flex" }}>
                        {this.state.toggleSelect && <div style={{ alignSelf: "center" }} className="general-top-widjet-button" onClick={() => this.toggleSelect()}>{TranslationAgent.localizeString("download_select_all")}</div>}
                        {!this.state.toggleSelect && <div style={{ alignSelf: "center" }} className="general-top-widjet-button" onClick={() => this.toggleSelect()}>{TranslationAgent.localizeString("download_deselect_all")}</div>}
                        {this.isVisibleAdmin() && <div style={{ alignSelf: "center" }} className="general-top-widjet-button" onClick={() => this.updateInvoice()}>{TranslationAgent.localizeString("save_lower")}</div>}
                    </div>
                    <div className="col-md-8" style={{ display: "grid", justifyContent: "right" }}>
                        <div className="row">
                            <div className="general-top-widjet-button-holder">

                                <div className="col-6 general-top-widjet-button" onClick={() => this.downloadMultiple()}><img src={IconService.pdf_icon} className="smallico-in-button" />
                                    Download PDFs</div>
                                <div className="col-6 general-top-widjet-button" onClick={() => {


                                    let list = EncryptStorage.getItem("on-page-tables");
                                    if (list == null) {
                                            toast.error(TranslationAgent.localizeString("no_tables_found"), {
                                            hideProgressBar: true,
                                            autoClose: true,
                                            draggable: true,
                                        })
                                        return;
                                    }
                                    let knownTables = JSON.parse(list);

                                    let excelPayload = {
                                        Excel: [],
                                        Language: EncryptStorage.getItem("language") == "it" ? "it-IT" : "en-US",
                                    };
                                    let excelName = "Invoices_" + (new Date().toISOString().replace("T", "_").split(".")[0]).toString() + ".xlsx";
                                    for (var key in knownTables) {
                                        if (knownTables.hasOwnProperty(key)) {
                                            excelPayload.Excel.push({
                                                Name: key,
                                                Xml: knownTables[key]
                                            });
                                        }
                                    }

                                    /* ApiCall to update status */
                                    ApiService.toggleSpinner(true);
                                    ApiService.generateExcelInvoices(excelPayload).then((res) => {

                                        if (res.raw.status === 200) {
                                            if (res && res.parsed) {
                                                var sampleArr = this.base64ToArrayBuffer(res.parsed);
                                                this.saveByteArray(excelName, sampleArr, "application/vnd.ms-excel");
                                            }

                                        } else {

                                            let msg = ErrorService.extractError(res);
                                            if (msg)
                                            toast.error(msg, {
                                                hideProgressBar: true,
                                                autoClose: true,
                                                draggable: true,
                                            })
                                        }
                                    }).finally(() => {
                                        ApiService.toggleSpinner(false);
                                    });

                                }}>
                                    <img src={IconService.excel_icon} className="smallico-in-button" />Download Excel</div>
                            </div>

                        </div>
                    </div>
                </div>
                <div style={{ "overflow": "auto" }}>
                    <ColoredTableComponent hiddenHeaders={hiddenHeaders} excludeExportHeader={excludeExportHeader} tableName="Invoices" isLoading={this.state.isLoading} headers={headers} rows={rows} />
                    {rows.length == 0 && (
                        <div style={{"marginTop": "5em"}} className="extracontent-no-data">{TranslationAgent.localizeString('no_bill_available')}</div>    
                    )}
                </div>
            </div>
        )
    }
}

export default ListaFattureWidjetComponent