import React, { Component } from 'react'
import LoadingSpinnerComponent from './../LoadingSpinnerComponent'
import ApiService from './../../services/ApiService'
import SmallBoxedWidjetComponent from './SmallBoxedWidjetComponent'
import TranslationAgent from './../../translations/TranslationAgent'
import {parseJwt} from './../../services/ApiService'
import './../../assets/css/custom/widjets/RecapSavingsWidjetComponent.css'
import EncryptStorage from './../../storage/EncryptStorage'

class RecapSavingsWidjetComponent extends Component {
    constructor(props) {
        // eslint-disable-next-line
        super(props);
        this.state = {
            isLoading: true,
            label1: "",
            label2: ""
        }
    }

    isVisibleAdmin() {

        const b2cToken = JSON.parse(EncryptStorage.getItem("session-data"))?.B2CToken;
        if (b2cToken) {
            const b2cToken = JSON.parse(EncryptStorage.getItem("session-data")).B2CToken;
            if (b2cToken) {
                const parsedToken = parseJwt(b2cToken);
                return parsedToken.idp != null;
            }

            return false;
        }
    }

    componentDidMount() {

        let userId = JSON.parse(EncryptStorage.getItem("session-data"))?.userId;
        let companyId = JSON.parse(EncryptStorage.getItem("session-data"))?.isEon ? JSON.parse(EncryptStorage.getItem("requested-company-location")) : JSON.parse(EncryptStorage.getItem("session-data"))?.companyId;
        let role = this.isVisibleAdmin() ? EncryptStorage.getItem("requested-show-mode") : "";

        if (companyId != null) {
            /* ApiCall to update status */
            ApiService.widgetApi({
                "name": "RecapSavingsWidjetComponent",
                "payload": { UserId: userId, CompanyId: companyId, Role: role }
            }).then((res) => {

                if (this.props.onIsReady) { this.props.onIsReady(); }

                let value1 = "-";
                let value2 = "-";

                if (res != null) {
                    if (res.parsed != null) {
                        value1 = res.parsed.thisYear;
                        value2 = res.parsed.total;
                    }
                }

                this.setState({ label1: (res == null) ? "" : Intl.NumberFormat(EncryptStorage.getItem("language"), { maximumFractionDigits: 1 }).format(parseFloat(value1)), label2: (res == null) ? "" : Intl.NumberFormat(EncryptStorage.getItem("language"), { maximumFractionDigits: 1 }).format(parseFloat(value2)), isLoading: false });
            })
        }
    }

    render() {
        return (
            <div>
                {this.state.isLoading && (<LoadingSpinnerComponent />)}
                {!this.state.isLoading && (
                    <div style={{ "fontSize": "0.55em" }}>
                        <SmallBoxedWidjetComponent
                            label={this.state.label1 != null ? this.state.label1 : ""}
                            title={TranslationAgent.localizeString("analisys_co2")}
                            underlabel={"t"}
                            largerSubtitle={TranslationAgent.localizeString("co2_savings_all_plants_home")} />
                        <SmallBoxedWidjetComponent
                            label={this.state.label2 != null ? this.state.label2 : ""}
                            underlabel={"t"}
                            largerSubtitle={TranslationAgent.localizeString("co2_savings_all_plants_start_forniture_home")} />
                    </div>
                )}
            </div>
        )
    }
}

export default RecapSavingsWidjetComponent