import React, { Component } from 'react'
import LoadingSpinnerComponent from '../components/LoadingSpinnerComponent'
import ApiService from '../services/ApiService'
import { parseJwt } from '../services/ApiService'
import TranslationAgent from '../translations/TranslationAgent'
import '../assets/css/custom/LoginView.css'
import EncryptStorage from './../storage/EncryptStorage'


class PostLoginView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            message: ""
        }
    }


    isVisibleAdmin() {

        const b2cToken = JSON.parse(EncryptStorage.getItem("session-data"))?.B2CToken;
        if (b2cToken) {
            const b2cToken = JSON.parse(EncryptStorage.getItem("session-data")).B2CToken;
            if (b2cToken) {
                const parsedToken = parseJwt(b2cToken);
                return parsedToken.idp != null;
            }

            return false;
        }
    }

    componentDidMount() {
        let spl = window.location.href.split("id_token=");
        if (spl.length > 1) {
            const token = spl[1];

            const sessionData = {}
            sessionData["B2CToken"] = token;

            EncryptStorage.setItem("session-data", JSON.stringify(sessionData));
            ApiService.postLogin().then(res => {
                if (res.raw.status === 200) {

                    EncryptStorage.setItem("logged-in", true);
                    const userData = res.parsed;
                    userData["B2CToken"] = token;
                    EncryptStorage.setItem("session-data", JSON.stringify(userData));
                    if (!this.isVisibleAdmin()) {
                        ApiService.widgetEconomicsVisibility({ UserId: userData.userId, CompanyId: userData.companyId }).then((res) => {
                            debugger;
                            EncryptStorage.setItem('can-see-economics', res?.parsed?.showEconomicsWidget);
                        }).finally(() => {
                            setTimeout(() => { window.location.href = "#/home"; }, 100);
                        })
                    }
                    else
                        setTimeout(() => { window.location.href = "#/home"; }, 100);

                } else {
                    let m = res.parsed?.message;
                    if (m == null) {
                        m = res.raw.message;
                    }
                    this.setState({ isLoading: false, message: m });
                }
            });

        } else {
            this.setState({ isLoading: false, message: TranslationAgent.localizeString('cannot_find_b2c_token') });
        }
    }

    render() {
        return (
            <div>
                {this.state.isLoading && (<LoadingSpinnerComponent />)}
                {!this.state.isLoading && (<div style={{
                    "height": "100vh",
                    "textAlign": "center",
                    "padding": "5em"
                }}>{this.state.message}</div>)}
            </div>
        )
    }
}

export default PostLoginView