import React, { Component } from 'react'
import GaugeChartComponent from './../GaugeChartComponent'
import TranslationAgent from './../../translations/TranslationAgent'
import ApiService from './../../services/ApiService'
import {parseJwt} from './../../services/ApiService'
import './../../assets/css/custom/widjets/EnergiaElettricaQMGPercentWidjetComponent.css'
import EncryptStorage from './../../storage/EncryptStorage'

class EnergiaElettricaQMGPercentWidjetComponent extends Component {
  constructor(props) {
    // eslint-disable-next-line
      super(props);
        this.state = {
            percent: 0,
            isLoading: true
        }
    }

    isVisibleAdmin() {

        const b2cToken = JSON.parse(EncryptStorage.getItem("session-data"))?.B2CToken;
        if (b2cToken) {
            const b2cToken = JSON.parse(EncryptStorage.getItem("session-data")).B2CToken;
            if (b2cToken) {
                const parsedToken = parseJwt(b2cToken);
                return parsedToken.idp != null;
            }

            return false;
        }
    }


    componentDidMount() {
        let plantId = EncryptStorage.getItem('requested-plant-location');
        let userId = JSON.parse(EncryptStorage.getItem("session-data"))?.userId
        let periodId = EncryptStorage.getItem('requested-reference-year');
        let enType = "Electrical";
        let role = this.isVisibleAdmin() ? EncryptStorage.getItem("requested-show-mode") : "";

        if (plantId != null && periodId != null) {

            /* ApiCall to update status */
            ApiService.widgetApi({
                "name": "EnergiaElettricaQMGPercentWidjetComponent",
                "payload": {Role: role, PlantId: (+plantId), UserId: userId, ReferencePeriodId: (+periodId), EnergyType: enType }
            }).then((res) => {
                if (this.props.onIsReady) { this.props.onIsReady(); }

                let value = 0;
                if (res != null) {
                    if (res.parsed != null) {
                        value = res.parsed.percentage;

                        EncryptStorage.setItem("QMG_EletttricaPerc", value)
                    }
                }
                this.setState({ percent: value, isLoading: false });
            });
        }
    }

    render() {
        return (this.state.percent > 0 ? <GaugeChartComponent title={TranslationAgent.localizeString("electro_gauge_title")} subtitle={TranslationAgent.localizeString("electro_gauge_subtitle")} isLoading={this.state.isLoading} percent={this.state.percent}/> : <></>)
  }
}

export default EnergiaElettricaQMGPercentWidjetComponent