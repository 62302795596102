import React, { Component } from 'react'
import ApiService from '../services/ApiService'
import BackButtonComponent from '../components/BackButtonComponent'
import TranslationAgent from '../translations/TranslationAgent'
import { toast } from 'react-toastify';
import '../assets/css/custom/PlantDetailView.css'
import ErrorService from '../services/ErrorService'
import IconService from '../services/IconService'
import EncryptStorage from './../storage/EncryptStorage'

class AddCustomerView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customer: null
        }

        this.dateFormat = this.dateFormat.bind(this);
        this.onSaveCustomer = this.onSaveCustomer.bind(this);
        this.checkBeforeSave = this.checkBeforeSave.bind(this);
    }

    dateFormat(date) {
        let spl = date.split("-");
        spl = spl.reverse();
        return spl.join("-");
    }

    componentDidMount() {

    }

    getUserId() {
        return JSON.parse(EncryptStorage.getItem("session-data"))?.userId;
    }

    onChangeProperty(propName, value) {
        this.setState(prevState => {
            let cust = Object.assign({}, prevState.customer);
            cust[propName] = value;
            return { customer: cust };
        })
    }

    checkBeforeSave() {
        if (!this.state.customer?.companyName) {
            toast.error(TranslationAgent.localizeString("customer_mandatory_name"), {
                hideProgressBar: true,
                autoClose: true,
                draggable: true,
            })
            return false;
        }

        if (!this.state.customer?.businessName) {
            toast.error(TranslationAgent.localizeString("customer_mandatory_businessname"), {
                hideProgressBar: true,
                autoClose: true,
                draggable: true,
            })
            return false;
        }

        if (!this.state.customer?.sapCode) {
            toast.error(TranslationAgent.localizeString("customer_mandatory_sap"), {
                hideProgressBar: true,
                autoClose: true,
                draggable: true,
            })
            return false;
        }

        if (!this.state.customer?.vatNumber) {
            toast.error(TranslationAgent.localizeString("customer_mandatory_vat"), {
                hideProgressBar: true,
                autoClose: true,
                draggable: true,
            })
            return false;
        }

        if (!this.state.customer?.registeredOffice) {
            toast.error(TranslationAgent.localizeString("customer_mandatory_address"), {
                hideProgressBar: true,
                autoClose: true,
                draggable: true,
            })
            return false;
        }

        return true;

    }

    onSaveCustomer() {
        if (!this.checkBeforeSave())
            return;

        ApiService.toggleSpinner(true);

        ApiService.insertCompany({
            CompanyName: this.state.customer?.companyName,
            BusinessName: this.state.customer?.businessName,
            VATNumber: this.state.customer?.vatNumber,
            SAPCode: this.state.customer?.sapCode,
            RegisteredOffice: this.state.customer?.registeredOffice,
            CreatedBy: this.getUserId(),
            ShowEconomicsWidget: document.getElementById("showEconomicsWidget").value == "true" ? "S" : "N"
        }).then((res) => {

            if (res.raw.status === 200) {
                EncryptStorage.removeItem("can-see-economics");
                setTimeout(() => {
                    window.history.back();
                }, 1000);                

                toast.success(TranslationAgent.localizeString("customer_added_success"), {
                    hideProgressBar: true,
                    autoClose: true,
                    draggable: true,
                })
            } else {
                let msg = ErrorService.extractError(res);
                if (msg)
                toast.error(msg, {
                    hideProgressBar: true,
                    autoClose: true,
                    draggable: true,
                })
            }
        }).finally(() => {
            ApiService.toggleSpinner(false);
        })
    }

    render() {

        return (
            <div className="content mt-1">
                <div className="container-fluid">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-9">
                                <BackButtonComponent backurl={null} title={TranslationAgent.localizeString("add_new_customer_title")} />
                            </div>
                            <div className="col-md-3" style={{ display: "grid", justifyContent: "right" }} >
                                <img className={"eon-logo-icon"} src={IconService.eon_logo} />
                            </div>
                        </div>
                    </div>
                    <div className="admin-content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="admin-content-title">{TranslationAgent.localizeString("new_customer_infos")}</div>
                                <div className="admin-detail-box">
                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("customers_infos_name")}</div>
                                    <div className="admin-detail-box-input-wrap">
                                        <input onChange={(e) => this.onChangeProperty("companyName", e.target?.value)} maxLength={50} defaultValue={this.state.customer?.companyName} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("customers_infos_name")} type="text" />
                                    </div>
                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("customers_infos_socialreason")}</div>
                                    <div className="admin-detail-box-input-wrap">
                                        <input onChange={(e) => this.onChangeProperty("businessName", e.target?.value)} maxLength={50} defaultValue={this.state.customer?.businessName} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("customers_infos_socialreason")} type="text" />
                                    </div>
                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("customers_infos_sap")}</div>
                                    <div className="admin-detail-box-input-wrap">
                                        <input onChange={(e) => this.onChangeProperty("sapCode", e.target?.value)} maxLength={10} defaultValue={this.state.customer?.sapCode} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("customers_infos_sap")} type="text" />
                                    </div>
                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("customers_infos_piva")}</div>
                                    <div className="admin-detail-box-input-wrap">
                                        <input onChange={(e) => this.onChangeProperty("vatNumber", e.target?.value)} maxLength={50} defaultValue={this.state.customer?.vatNumber} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("customers_infos_piva")} type="text" />
                                    </div>
                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("customers_infos_legaladdress")}</div>
                                    <div className="admin-detail-box-input-wrap">
                                        <input onChange={(e) => this.onChangeProperty("registeredOffice", e.target?.value)} maxLength={200} defaultValue={this.state.customer?.registeredOffice} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("customers_infos_legaladdress")} type="text" />
                                    </div>
                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_show_widget_economics")}</div>
                                    <div className="admin-detail-box-input-wrap">
                                        <select id="showEconomicsWidget" style={{ "height": "1.9em" }} defaultValue={this.state.customer?.showEconomicsWidget == "S"} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_show_widget_economics")} type="text">
                                            <option value="true">{TranslationAgent.localizeString("yes")} </option>
                                            <option value="false">{TranslationAgent.localizeString("no")} </option>
                                        </select>
                                    </div>
                                    <div onClick={() => this.onSaveCustomer()} className="button-boxed-widjet" style={{ "marginTop": "2em" }}>{TranslationAgent.localizeString("save")}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default AddCustomerView