import React, { Component } from 'react'
import ApiService from '../services/ApiService'
import BackButtonComponent from '../components/BackButtonComponent'
import TranslationAgent from '../translations/TranslationAgent'
import { toast } from 'react-toastify';
import '../assets/css/custom/PlantDetailView.css'
import ErrorService from '../services/ErrorService'
import IconService from '../services/IconService'
import EncryptStorage from './../storage/EncryptStorage'

class AddReferencePeriodView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            refPeriod: {
                name: "",
                start: new Date().toISOString().split('T')[0],
                end: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0]
            }
        }

        this.dateFormat = this.dateFormat.bind(this);
    }

    getPlantId() {
        const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
        return urlParams.get('plantId');
    }

    getUserId() {
        return JSON.parse(EncryptStorage.getItem("session-data"))?.userId;
    }

    dateFormat(date) {
        let spl = date.split("-");
        spl = spl.reverse();
        return spl.join("-");
    }

    componentDidMount() {

    }

    getUserId() {
        return JSON.parse(EncryptStorage.getItem("session-data"))?.userId;
    }

    onChangeProperty(propName, value) {
        this.setState(prevState => {
            let cust = Object.assign({}, prevState.customer);
            cust[propName] = value;
            return { customer: cust };
        })
    }

    onChangeProperty(propName, value) {
        this.setState(prevState => {
            let refPeriod = Object.assign({}, prevState.refPeriod);
            refPeriod[propName] = value;
            return { refPeriod: refPeriod };
        })
    }


    render() {

        return (
            <div className="content mt-1">
                <div className="container-fluid">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-9">
                                <BackButtonComponent backurl={null} title={TranslationAgent.localizeString("add_period_title")} />
                            </div>
                            <div className="col-md-3" style={{ display: "grid", justifyContent: "right" }} >
                                <img className={"eon-logo-icon"} src={IconService.eon_logo} />
                            </div>
                        </div>
                    </div>
                    <div className="admin-content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="admin-content-title">{TranslationAgent.localizeString("new_period_info")}</div>
                                <div className="admin-detail-box">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="admin-detail-box-title">{TranslationAgent.localizeString("new_period_name")}</div>
                                            <div className="admin-detail-box-input-wrap">
                                                <input onChange={(e) => this.onChangeProperty("name", e.target?.value)} value={this.state.refPeriod.name} onKeyUp={() => {

                                                    let b = document.getElementById("proceed-button");
                                                    let v = document.getElementById("Name").value;

                                                    if (b == null) {
                                                        return;
                                                    }

                                                    if (v != "") {
                                                        b.classList.remove("add_button_disabled");
                                                    } else {
                                                        b.classList.add("add_button_disabled");
                                                    }

                                                }} id="Name" className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("new_period_name")} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="admin-detail-box-title">{TranslationAgent.localizeString("new_period_start")}</div>
                                            <div className="admin-detail-box-input-wrap">
                                                <input onChange={(e) => this.onChangeProperty("start", e.target?.value)} value={this.state.refPeriod.start} id="Start" className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("new_period_start")} type="date" />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="tooltip-string">{TranslationAgent.localizeString('reference_period_create_tooltip')}</div>


                                    <div id="proceed-button" className="button-boxed-widjet add_button_disabled" style={{ "marginTop": "2em" }} onClick={() => {

                                        let el = document.getElementById("Start");
                                        if (el == null) {
                                            return;
                                        }
                                        let start = el.value;
                                        let spl = start.split("-");
                                        let end = ((+spl[0]) + 1).toString() + "-" + spl[1] + "-" + spl[2];

                                        let d = new Date(end);
                                        d.setDate(d.getDate() - 1);

                                        let endFinal = d.toISOString().split("T")[0];

                                        ApiService.toggleSpinner(true);
                                        ApiService.createReferencePeriod({
                                            PlantId: this.getPlantId(),
                                            Name: this.state.refPeriod.name,
                                            Start: this.state.refPeriod.start,
                                            End: endFinal,
                                            UserId: this.getUserId()
                                        }).then((res) => {

                                            if (res.raw.status === 200) {
                                                setTimeout(() => {
                                                    window.history.back();
                                                }, 1000);
                                                toast.success(TranslationAgent.localizeString("reference_period_created"), {
                                                    hideProgressBar: true,
                                                    autoClose: true,
                                                    draggable: true,
                                                })
                                            } else {
                                                let msg = ErrorService.extractError(res);
                                    if (msg)
                                    toast.error(msg, {
                                                    hideProgressBar: true,
                                                    autoClose: true,
                                                    draggable: true,
                                                })
                                            }

                                        }).finally(() => {
                                            ApiService.toggleSpinner(false);
                                        });

                                    }}>{TranslationAgent.localizeString("save")}</div>

                                </div>
                                <div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default AddReferencePeriodView