import React, { Component } from 'react'
import IconService from '../services/IconService'
import TranslationAgent from '../translations/TranslationAgent'
import '../assets/css/custom/LoginView.css'
import EncryptStorage from './../storage/EncryptStorage'

const disableEmailValidation = false;


class LoginView extends Component {
    constructor(props) {
        super(props);
        this.mainValidation = this.mainValidation.bind(this);
        this.validate = this.validate.bind(this);
        this.validateReset = this.validateReset.bind(this);

        window.DBlogin = function () {
            document.getElementById("second-login-sector").style.display = "block";
        }

        window.mockBrowse = function () {
            EncryptStorage.setItem("logged-in", "true");
            setTimeout(() => { window.location.href = "#/home"; }, 100);
        }
    }


    componentDidMount() {
        localStorage.clear();
    }

    validate() {
    }

    validateReset() {
        let button = document.getElementById("reset-button-hook");
        if (document.getElementById("reset_check_icon").style.opacity == "1") {
            button.className = "login-button";
        } else {
            button.className = "login-button-disabled";
        }
    }


    mainValidation() {


        let emailN = document.getElementById("email_input")
        if (emailN == null) {
            return;
        }

        let email = emailN.value;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let res = re.test(String(email).toLowerCase());
        try {
            if (res || disableEmailValidation) {
                document.getElementById("check_icon").style.opacity = "1";
            } else {
                document.getElementById("check_icon").style.opacity = "0";
            }

            this.validate();
        }
        catch (e) { }
    }


    render() {

        EncryptStorage.clear();

        let sessionExpired = (<div></div>);
        if (EncryptStorage.getItem("session-timed-out") == "true") {
            EncryptStorage.removeItem("session-timed-out");
            sessionExpired = (<div className="expired-banner">{TranslationAgent.localizeString("sess_expired")}</div>);
        }

        if (EncryptStorage.getItem("open-secondary-login-slot") == "true") {
            setTimeout(() => {
                EncryptStorage.removeItem("open-secondary-login-slot");
            }, 100);
        }

        let rememberMe = EncryptStorage.getItem("remember-me");
        rememberMe = rememberMe == "true";

        let creds = EncryptStorage.getItem("stored-login-creds");
        if (creds != null) {
            creds = JSON.parse(creds);
            setTimeout(this.mainValidation, 200);
        } else {
            creds = { "user": ""};
        }

        return (
            <div className="login-box">
                {sessionExpired}
                <div>
                    <img onClick={() => { window.location.reload(); }} className="login-logo" src={IconService.eon_logo} />
                </div>
                <div className="full-inline">
                    <div className="inline login-block-wrap" style={{textAlign:"center"}}>
                        <img className="login-forest" src={IconService.eon_forest} />
                    </div>
                    <div className="inline login-block-wrap text-section">
                        <img className="login-blob-image" src={IconService.eon_blobs} />
                        <div id="first-login-sector" style={EncryptStorage.getItem("open-secondary-login-slot") != "true" ? {} : { "display": "none" }}>
                            <div className="login-big-text">
                                <span className="red">Y</span><span>our</span>
                            </div>
                            <div className="login-big-text">
                                <span className="red">E</span><span>nergy</span>
                            </div>
                            <div className="login-big-text">
                                <span className="red">S<span className="small-2">2</span></span><span>ustainable Supplier</span>
                            </div>
                            <div className="login-exp-text">
                                <div>
                                    {TranslationAgent.localizeString("login_intro_label_1")}
                                </div>
                                <div style={{ "marginTop": "1em" }}>
                                    {TranslationAgent.localizeString("login_intro_label_2")}
                                </div>
                                {/* <div className="login-button" onClick={() => {
                            <div className="login-button" style={{"display":"none"}} onClick={() => {
                                EncryptStorage.removeItem("logged-in");
                                document.getElementById("first-login-sector").style.display = "none";
                                document.getElementById("second-login-sector").style.display = "block";
                            }}>
                                {TranslationAgent.localizeString("access")}
                            </div> */}

                                <div className="login-button" onClick={() => {
                                    window.location.href = process.env.REACT_APP_LOGIN_REDIRECT_URL;
                                }}>
                                    {TranslationAgent.localizeString("access_b2c")}
                                </div>
                                <div className="login-button" onClick={() => {
                                    window.location.href = process.env.REACT_APP_REGISTRATION_REDIRECT_URL;
                                }}>
                                    {TranslationAgent.localizeString("register_b2c")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default LoginView