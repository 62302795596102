import React, { Component } from 'react'
import LoadingSpinnerComponent from './../LoadingSpinnerComponent'
import ApiService from './../../services/ApiService'
import SmallBoxedWidjetComponent from './SmallBoxedWidjetComponent'
import TranslationAgent from './../../translations/TranslationAgent'
import {parseJwt} from './../../services/ApiService'
import './../../assets/css/custom/widjets/AlberiPiantatiWidjetComponent.css'
import IconService from '../../services/IconService'
import EncryptStorage from './../../storage/EncryptStorage'

class AlberiPiantatiWidjetComponent extends Component {
    constructor(props) {
        // eslint-disable-next-line
        super(props);
        this.state = {
            isLoading: true,
            label: 0,
            percent: 0
        }
    }

    isVisibleAdmin() {

        const b2cToken = JSON.parse(EncryptStorage.getItem("session-data"))?.B2CToken;
        if (b2cToken) {
            const b2cToken = JSON.parse(EncryptStorage.getItem("session-data")).B2CToken;
            if (b2cToken) {
                const parsedToken = parseJwt(b2cToken);
                return parsedToken.idp != null;
            }

            return false;
        }
    }

    componentDidMount() {

        let plantId = EncryptStorage.getItem('requested-plant-location');
        let userId = JSON.parse(EncryptStorage.getItem("session-data"))?.userId
        let role = this.isVisibleAdmin() ? EncryptStorage.getItem("requested-show-mode") : "";

        if (plantId != null) {

            /* ApiCall to update status */
            ApiService.widgetApi({ "name": "AlberiPiantatiWidjetComponent", payload: {Role: role, PlantId: (+plantId)} }).
                then((res) => {
                    if (this.props.onIsReady) { this.props.onIsReady(); }

                    let label = 0;
                    let percent = 0;

                    if (res != null) {
                        if (res.parsed != null) {
                            label = res.parsed.equivalentTrees
                            percent = res.parsed.treePercentage
                        }
                    }
                    this.setState({ label: Intl.NumberFormat(EncryptStorage.getItem("language")).format(label), percent: percent, isLoading: false });
                });

        }

    }

    render() {

        let imagesList = [];
        let size = "min(6vw, 2.5em)";

        for (let i = 0; i < 20; i++) {
            let isgreen = this.state.percent > i * 7.15;
            imagesList.push(<img key={i} style={isgreen ? { "height": size } : { "height": size, "opacity": "0.2" }} src={IconService.tree_image} />);
            if (i == 9) {
                imagesList.push(<div key={-1000}></div>);
            }
        }

        return (
            <div>
                {this.state.isLoading && (<LoadingSpinnerComponent />)}
                {!this.state.isLoading && (
                    <div>
                        <SmallBoxedWidjetComponent
                            label={this.state.label}
                            underlabel={TranslationAgent.localizeString("equivalent_trees")}
                            subtitle={""}                            
                            extracontent={imagesList} />                        
                    </div>)
                }
            </div>
        )
    }
}

export default AlberiPiantatiWidjetComponent