import React, { Component } from 'react'
import ApiService from '../services/ApiService'
import WidgetRenderComponent from '../components/WidgetRenderComponent'
import BackButtonComponent from '../components/BackButtonComponent'
import DropdownComponent from '../components/DropdownComponent'
import DatePickerComponent from '../components/DatePickerComponent'
import TranslationAgent from '../translations/TranslationAgent';
import moment from 'moment'
import IconService from '../services/IconService'
import EncryptStorage from './../storage/EncryptStorage'

class BillingDetailsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plants: [],
            viewDate: false,
        }

        this.changedPlant = this.changedPlant.bind(this);
        this.changedDate = this.changedDate.bind(this);

        this.changedDateFrom = this.changedDateFrom.bind(this);
        this.changedDateTo = this.changedDateTo.bind(this);
    }

    changedDateFrom(e) {
        EncryptStorage.setItem('requested-max-range-from', e);
    }

    changedDateTo(e) {
        EncryptStorage.setItem('requested-max-range-to', e);
    }

    changedDate(e) {

        EncryptStorage.setItem("change-plant", false);

        EncryptStorage.setItem('requested-reference-year-index', e.target.value);

        let currentYear = new Date().getFullYear();
        let currentMonth = new Date().getMonth() + 1;
        if (e.target.value == 0) { // current year
            EncryptStorage.setItem('requested-max-range-from', currentYear.toString() + "-01-01");
            EncryptStorage.setItem('requested-max-range-to', currentYear.toString() + "-" + currentMonth.toString().padStart(2, '0') + "-01");
        }

        if (e.target.value == 1) { // current year
            EncryptStorage.setItem('requested-max-range-from', (currentYear - 1).toString() + "-01-01");
            EncryptStorage.setItem('requested-max-range-to', (currentYear - 1).toString() + "-12-31");
        }

        if (e.target.value != -1) {
            setTimeout(() => {
                
                window.App.reloadApp();
            }, 200);
        }
        else{
            this.setState({viewDate: true});
            EncryptStorage.setItem('requested-max-range-from', currentYear.toString() + "-01-01");
            EncryptStorage.setItem('requested-max-range-to', currentYear.toString() + "-" + currentMonth.toString().padStart(2, '0') + "-01");
        }


    }

    changedPlant(e) {
        EncryptStorage.setItem("change-plant", true);
        EncryptStorage.setItem('requested-plant-location-billing', e.target.value);
        

        window.App.reloadApp()

    }

    componentDidMount() {

        let companyId = JSON.parse(EncryptStorage.getItem("session-data"))?.isEon ? JSON.parse(EncryptStorage.getItem("requested-company-location")) : JSON.parse(EncryptStorage.getItem("session-data"))?.companyId;
        this.setState({viewDate: EncryptStorage.getItem("requested-reference-year-index") == -1});

        /* ApiCall to update status */
        ApiService.getRegisteredPlantsList({ CompanyId: companyId }).then((res) => { this.setState({ plants: (res == null) ? [] : res.parsed }); })

    }

    getUserId() {
        return JSON.parse(EncryptStorage.getItem("session-data"))?.userId;
    }

    getPlantId() {
        let id = EncryptStorage.getItem('requested-plant-location-billing');
        if (id != null) {
            return +id;
        }

        return null;
    }

    render() {
        const plants = [];        
        
        let currentYear = new Date().getFullYear();
        let currentMonth = new Date().getMonth() + 1;

        if (!EncryptStorage.getItem("requested-reference-year-index")) { // current year
            EncryptStorage.setItem('requested-reference-year-index', 0);
            EncryptStorage.setItem('requested-max-range-from', currentYear.toString() + "-01-01");
            EncryptStorage.setItem('requested-max-range-to', currentYear.toString() + "-" + currentMonth.toString().padStart(2, '0') + "-01");
        }

        const years = [];
        years.push({ text: TranslationAgent.localizeString("custom_periods_current_year"), value: 0 });
        years.push({ text: TranslationAgent.localizeString("custom_periods_last_year"), value: 1 });
        years.push({ text: TranslationAgent.localizeString("custom_periods"), value: -1 });

        if (years.length > 1) {
            EncryptStorage.setItem('all-reference-periods', JSON.stringify(years));
        }

        if (this.state.plants.length > 0) {
            this.state.plants.forEach((item, i) => {
                plants.push({ text: item.plantName, value: item.plantId });
            })

            if (this.state.plants.length !== 1)
                plants.push({ text: TranslationAgent.localizeString("all_plants"), value: -1 });

            EncryptStorage.setItem('all-plants-ids', JSON.stringify(this.state.plants));

        }


        let pickedPlant = EncryptStorage.getItem('requested-plant-location-billing');
        if (pickedPlant == null) {
            pickedPlant = EncryptStorage.getItem('requested-plant-location');
            EncryptStorage.setItem('requested-plant-location-billing', pickedPlant);
        }


        return (
            <div className="content mt-1">
                <div className="container-fluid">
                    <div style={{ "marginBottom": "-1em" }}>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-9">
                                    <BackButtonComponent backurl={"#/plant"} title={TranslationAgent.localizeString("indexes_and_prices")} />
                                </div>
                                <div className="col-md-3" style={{ display: "grid", justifyContent: "right" }} >
                                    <img className={"eon-logo-icon"} src={IconService.eon_logo} />
                                </div>
                            </div>
                            <div className="row ml-2">

                                <div style={{ "display": "inline-block" }}>
                                    <DropdownComponent title={TranslationAgent.localizeString("select_plant")} items={plants} currentValue={EncryptStorage.getItem('requested-plant-location-billing')} changeCallback={this.changedPlant} />
                                </div>
                                <div style={{ "display": "inline-block" }}>
                                    <DropdownComponent title={TranslationAgent.localizeString("select_ref_period")} items={years} currentValue={EncryptStorage.getItem('requested-reference-year-index')} changeCallback={this.changedDate} />
                                </div>
                            </div>
                            {
                                this.state.viewDate &&
                                <div className="row ml-2" >
                                    <div id="custom-period-form" className="new-email-form" style={{ "maxHeight": "15em" }}>

                                        <div>
                                            <div style={{ "display": "inline-block" }}>
                                                <DatePickerComponent mode={"month"} requestMaxDate={EncryptStorage.getItem('requested-max-range-to')} currentValue={EncryptStorage.getItem('requested-max-range-from')} title={TranslationAgent.localizeString("range_from")} changeCallback={this.changedDateFrom} />
                                            </div>
                                            <div style={{ "display": "inline-block" }}>
                                                <DatePickerComponent mode={"month"} currentValue={EncryptStorage.getItem('requested-max-range-to')} title={TranslationAgent.localizeString("range_to")} changeCallback={this.changedDateTo} />
                                            </div>
                                            <div style={{ "display": "inline-block" }} className="ml-3 mt-2 button-boxed-widjet" onClick={() => {
                                                window.App.reloadApp()
                                            }}>{TranslationAgent.localizeString("apply")}</div>

                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    {
                        !this.state.isLoadingPeriods &&
                        <WidgetRenderComponent title="rates" />
                    }
                </div>
            </div>
        )
    }
}

export default BillingDetailsView