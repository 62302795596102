import React, { Component } from 'react'
import LoadingSpinnerComponent from './../LoadingSpinnerComponent'
import ApiService from './../../services/ApiService'
import ErrorService from './../../services/ErrorService'
import marker_EON from '../../assets/img/icons/marker_EON.png'
import { renderToString } from 'react-dom/server'
import DashboardTooltipResumeWidjetComponent from './DashboardTooltipResumeWidjetComponent'
import { toast } from 'react-toastify';
import EncryptStorage from './../../storage/EncryptStorage'

import './../../assets/css/custom/widjets/MappaImpiantiWidjetComponent.css'
import TranslationAgent from '../../translations/TranslationAgent'
import { Icon } from 'leaflet'
import { parseJwt } from './../../services/ApiService'

function killPopups() {
    let btns = document.getElementsByClassName("leaflet-popup-close-button");
    for (let i = 0; i < btns.length; i++) {
        //btns[i].click();
    }
};

let lock = false;
function slowKillPopups() {
    try {
        setTimeout(() => {
            if (lock) {
                return;
            }
            let btns = document.getElementsByClassName("leaflet-popup-close-button");
            for (let i = 0; i < btns.length; i++) {
                btns[i].click();
            }
        }, 500);
    }
    catch (e) {

    }
};

function lockPopup() {
    try {
        lock = true;
        setTimeout(() => {
            lock = false;
        }, 500);
    }
    catch (e) {

    }
}


function specialSetPopupDelete() {
    setTimeout(() => {
        let p = document.getElementsByClassName("leaflet-popup");
        for (let i = 0; i < p.length; i++) {
            p[i].onmouseleave = killPopups;
            p[i].onmouseenter = lockPopup;
        }
        p = document.getElementsByClassName("leaflet-marker-icon leaflet-zoom-animated leaflet-interactive");
        for (let i = 0; i < p.length; i++) {
            p[i].onmouseleave = slowKillPopups;
        }

    }, 500);
}

class MappaImpiantiWidjetComponent extends Component {
    constructor(props) {
        // eslint-disable-next-line
        super(props);
        this.state = {
            mapId: '_' + Math.random().toString(36).substr(2, 9),
            mapReference: null,
            data: [],
            windowWidth: window.innerWidth,
        }

        this.placeMap = this.placeMap.bind(this);
    }

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    };

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    isVisibleAdmin() {

        const b2cToken = JSON.parse(EncryptStorage.getItem("session-data"))?.B2CToken;
        if (b2cToken) {
            const b2cToken = JSON.parse(EncryptStorage.getItem("session-data")).B2CToken;
            if (b2cToken) {
                const parsedToken = parseJwt(b2cToken);
                return parsedToken.idp != null;
            }

            return false;
        }
    }
    componentDidMount() {

        let userId = JSON.parse(EncryptStorage.getItem("session-data"))?.userId;
        let companyId = JSON.parse(EncryptStorage.getItem("session-data"))?.isEon ? JSON.parse(EncryptStorage.getItem("requested-company-location")) : JSON.parse(EncryptStorage.getItem("session-data"))?.companyId;
        let role = this.isVisibleAdmin() ? EncryptStorage.getItem("requested-show-mode") : "";

        if (companyId != null) {

            /* ApiCall to update status */
            ApiService.widgetApi({
                "name": "MappaImpiantiWidjetComponent",
                "payload": { UserId: userId, CompanyId: companyId, Role: role }
            }).then((res) => {
                if (this.props.onIsReady) { this.props.onIsReady(); }

                if (res?.raw.status !== 200) {
                    let msg = ErrorService.extractError(res);
                    if (msg)
                    toast.error(msg, {
                        hideProgressBar: true,
                        autoClose: true,
                        draggable: true,
                    })
                }

                this.setState({ data: (res == null) ? "" : res.parsed, isLoading: false });
            })
        }

        window.addEventListener("resize", this.handleResize);
    }

    placeMap(position) {

        window.onresize = killPopups;

        if (this.state.mapReference == null) {

            if (window.L != undefined) {
                this.state.mapReference = window.L.map(this.state.mapId, {zoom: 5.5, zoomSnap: 0.1, center: position });

                window.L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                }).addTo(this.state.mapReference);                
            }
        }

        if (this.state.data.length > 0 && window.L != undefined) {

            this.state.data.forEach((item, i) => {

                let markerZone = [item.lat, item.long];
                let marker = window.L.marker(markerZone).addTo(this.state.mapReference);
                let navigationLabel = "<div ontouchstart=\"EncryptStorage.setItem('requested-plant-location', '" + item.plantId + "'); window.location.href = './#/plant'\" onclick=\"EncryptStorage.setItem('requested-plant-location', '" + item.plantId + "'); window.location.href = './#/plant'\">" + TranslationAgent.localizeString("detail") + "</div>";
                marker.bindPopup(renderToString(
                    <div className="popup-wrapper pr-4" onMouseLeave={ killPopups}>
                        <div className="upper-popup-title">
                            <div className="plant-name" >{item.plantName}</div>
                            <div className="pl-2 pr-2" style={{ color: 'var(--eon-blue)' }}> &gt; </div>
                            <div style={{ color: 'var(--eon-red)', fontWeight: "bold", cursor: "pointer" }} dangerouslySetInnerHTML={{ __html: navigationLabel }}></div>
                        </div>
                        <div className="popup-margin-fixer pr-4">
                            <DashboardTooltipResumeWidjetComponent ytdValue={Intl.NumberFormat(EncryptStorage.getItem("language"), { maximumFractionDigits: 1 }).format(parseFloat(item.co2Value))} ytdPercent={Intl.NumberFormat(EncryptStorage.getItem("language"), { maximumFractionDigits: 0 }).format(item.electricCYTDPercentage)} />
                        </div>
                    </div>));

                marker.setIcon(new Icon({ iconUrl: marker_EON, iconSize: { x: 30, y: 40 } }))

                marker.on("click", () => {
                    marker.openPopup();
                    specialSetPopupDelete();
                });

                marker.on("mouseover", () => {
                    marker.openPopup();
                    specialSetPopupDelete();
                })

            });
        }
    }


    render() {
        const position = [42.2, 11.3];

        setTimeout(() => {
            try {
                this.placeMap(position);
            } catch (e) {

            }
        }, 300);

        return (
            <div className="widjet-map-container" id={this.state.mapId}></div>
        );
    }
}

export default MappaImpiantiWidjetComponent





