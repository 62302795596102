
import DevelopMockApiService from './DevelopMockApiService'
import EncryptStorage from './../storage/EncryptStorage'

const ApiService = {
    toggleSpinner,
    loginUser,
    registerUser,
    setPageDynamicContent,
    getPageDynamicContent,
    uploadExcelFile,
    getPlantAnagraphics,
    getCustomerAnagraphics,
    getImportDataHistory,
    getAdmittedEmails,
    getCustomerProfile,
    getRegisteredPlantsList,
    getPlantProfile,
    getFormulasAnagraphics,
    getReferencePeriods,
    getReferencePeriodDetail,
    widgetApi,
    downloadFromUrl,
    getBaseUrl,
    b2cLogin,
    b2cRegister,
    getToken,
    validateToken,
    createNewUser,
    updateCompany,
    insertCompany,
    deleteEmail,
    postLogin,
    postRegistration,
    getSessionKey,
    updatePlant,
    createNewPlant,
    createReferencePeriod,
    updateUserEmail,
    createNewFormula,
    getFormulaDetail,
    updateFormula,
    setReferencePeriodData,
    widgetVisibility,
    GetPublishedPeriodList,
    setPublishedPeriodList,
    deleteInvoice,
    UpdateInvoiceState,
    downloadFileInvoice,
    generateExcel,
    multipleDownloadFileInvoice,
    sendCustomerCareMessage,
    deleteFormula,
    generateExcelRates,
    generateExcelInvoices,
    downloadInfoPrivacy,
    downloadImportedFile,
    createFileExcelGranted,
    widgetEconomicsVisibility,
    getGaugeValues,
    getCustomerCare,
    updateInvoice,
    getActivePeriod
};

if (window.location.href.indexOf("localhost") != -1) {
    window["ApiService"] = ApiService;
}

export function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    try {
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
    }
    catch (error) {
      window.location.href = "#/login";
      EncryptStorage.removeItem("session-data");
      EncryptStorage.setItem("logged-in", false);
    }
    return JSON.parse(jsonPayload);
  };

//const baseUrl = "/treelogapi/api/";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
// const baseUrl = "https://az-ebu-wapp-dev-d01.azurewebsites.net/eon/api/";

function getBaseUrl() {
    return baseUrl;
}

function setSessionData(userRole, userId, B2CToken, companyId) {
    let session = {
        "userRole": userRole,
        "userId": userId,
        "companyId": companyId,
        "B2CToken": B2CToken
    };
    EncryptStorage.setItem("session-data", JSON.stringify(session));
    return session;
}

function getSessionData() {
    let session = EncryptStorage.getItem("session-data");
    if (session != null) {
        return JSON.parse(session);
    } else {
        return null;
    }
}

function getSessionKey(key) {
    let session = getSessionData();
    if (session != null) {
        return session[key];
    }
    return null;
}

function getToken() {
    return getSessionKey("B2CToken");
}

async function setPageDynamicContent(pageContent) {
    const body = pageContent;
    return await apiCall(baseUrl + "widget/getpagecontent", "POST", body);
}

async function getPageDynamicContent(pagename) {
    return await DevelopMockApiService.fakeApi(pagename);
    //const body = { "pagename": pagename };
    //return await apiCall(baseUrl + "widget/getpagecontent", "POST", body);
}

async function loginUser(email) {
    const body = { "email": email };
    let response = await apiCall(baseUrl + "user/login", "POST", body);

    if (response.parsed != null) {
        setSessionData(
            response.parsed.roleId,
            response.parsed.userId,
            response.parsed.B2CToken,
            response.parsed.companyId
        );
    }

    return response;
}

async function b2cRegister(token) {
    return await apiCall(baseUrl + "info/postRegistration", "GET", null, null, token);
}


async function b2cLogin(token) {
    return await apiCall(baseUrl + "info/postLogin", "GET", null,null, token);
}

async function validateToken(token) {
    return await apiCall(baseUrl + "info/validateToken", "POST", null, null, token);
}

async function registerUser(email, name, surname, phone) {
    const body = {
        "Email": email,
        "Name": name,
        "Surname": surname,
        "PhoneNumber": phone,
    };
    return await apiCall(baseUrl + "user/register", "POST", body);
}

async function UpdateInvoiceState(payload) {
    return await apiCall(baseUrl + "admin/UpdateInvoiceState", "POST", payload, "application/json");
}

async function deleteInvoice(payload) {
    return await apiCall(baseUrl + "admin/DeleteInvoice", "POST", payload, "application/json");
}

async function updateUserEmail(payload) {
    return await apiCall(baseUrl + "admin/UpdateUserEmail", "POST", payload, "application/json");
}

async function downloadImportedFile(payload){
    return await apiCall(baseUrl + "file/downloadImportedFile", "POST", payload, "application/json");
}

async function uploadExcelFile(payload) {
    return await apiCall(baseUrl + "widget/readExcelFile", "POST", payload, "multipart/form-data");
}

async function getPlantAnagraphics(payload) {
    return await apiCall(baseUrl + "admin/companyPlants", "POST", payload, "application/json");
}

async function generateExcel(payload) {
    return await apiCall(baseUrl + "file/CreateExcelFile", "POST", payload, "application/json");
}

async function generateExcelRates(payload) {
    return await apiCall(baseUrl + "file/CreateExcelFileRates", "POST", payload, "application/json");
}

async function generateExcelInvoices(payload) {
    return await apiCall(baseUrl + "file/CreateExcelFileInvoices", "POST", payload, "application/json");
}

async function createFileExcelGranted(payload) {
    return await apiCall(baseUrl + "file/CreateExcelFileGranted", "POST", payload, "application/json");
}

async function getAdmittedEmails(payload) {
    return await apiCall(baseUrl + "admin/GetCompanyEmails", "POST", payload, "application/json");
}

async function getPlantProfile(payload) {
    return await await apiCall(baseUrl + "admin/plantsDetails", "POST", payload, "application/json");
}

async function getGaugeValues(payload) {
    return await await apiCall(baseUrl + "widget/GetGaugeValues", "POST", payload, "application/json");
}

async function getCustomerCare(payload) {
    return await apiCall(baseUrl + "widget/GetCustomerCare", "POST", payload, "application/json");
}

async function getCustomerProfile(payload) {
    return await apiCall(baseUrl + "admin/GetCompanyDetails", "POST", payload, "application/json");
}

async function sendCustomerCareMessage(payload) {
    return await apiCall(baseUrl + "widget/CustomerCareModel", "POST", payload, "application/json");
}

async function getReferencePeriodDetail(payload) {
    return await apiCall(baseUrl + "admin/referencePeriodDetails", "POST", payload, "application/json");
}

async function getRegisteredPlantsList(payload) {
    return await apiCall(baseUrl + "widget/GetAllPlants", "POST", payload, "application/json");
}

async function getCustomerAnagraphics(payload) {
    return await await apiCall(baseUrl + "admin/companyList", "POST", payload, "application/json");
}

async function getFormulasAnagraphics(payload) {
    return await await apiCall(baseUrl + "admin/getFormulaList", "POST", payload, "application/json");
}

async function updateCompany(payload) {
    return await apiCall(baseUrl + "admin/UpdateCompany", "POST", payload, "application/json")
}

async function createReferencePeriod(payload) {
    return await apiCall(baseUrl + "admin/createNewReferencePeriod", "POST", payload, "application/json")
}

async function updatePlant(payload) {
    return await apiCall(baseUrl + "admin/updatePlant", "POST", payload, "application/json")
}

async function createNewPlant(payload) {
    return await apiCall(baseUrl + "admin/CreatePlant", "POST", payload, "application/json")
}


async function getReferencePeriods(payload) {
    return await apiCall(baseUrl + "admin/referencePeriodList", "POST", payload, "application/json");
}

async function getActivePeriod(payload) {
    return await apiCall(baseUrl + "widget/GetActivePeriod", "POST", payload, "application/json")
}

async function widgetVisibility(payload) {
    return await apiCall(baseUrl + "widget/CheckShowWidget", "POST", payload, "application/json");
}

async function widgetEconomicsVisibility(payload) {
    return await apiCall(baseUrl + "widget/CheckShowEconomics", "POST", payload, "application/json");
}

async function getImportDataHistory(payload) {
    return await apiCall(baseUrl + "admin/GetUploadResultList", "POST", payload, "application/json");
}

async function createNewUser(payload) {
    return await apiCall(baseUrl + "admin/CreateNewUser", "POST", payload, "application/json")
}

async function insertCompany(payload) {
    return await apiCall(baseUrl + "admin/CreateNewCompany", "POST", payload, "application/json")
}

async function deleteEmail(payload) {
    return await apiCall(baseUrl + "admin/DeleteUser", "POST", payload, "application/json")
}

async function postLogin() {
    return await apiCall(baseUrl + "info/postLogin", "GET")
}

async function postRegistration() {
    return await apiCall(baseUrl + "info/postRegistration", "GET")
}

async function createNewFormula(payload) {
    return await apiCall(baseUrl + "admin/createNewFormula", "POST", payload, "application/json")
}

async function getFormulaDetail(payload) {
    return await apiCall(baseUrl + "admin/getFormulaDetail", "POST", payload, "application/json");
}

async function updateFormula(payload) {
    return await apiCall(baseUrl + "admin/updateFormula", "POST", payload, "application/json");
}

async function setReferencePeriodData(payload) {
    return await apiCall(baseUrl + "admin/SetReferencePeriodData", "POST", payload, "application/json");
}

async function GetPublishedPeriodList(payload) {
    return await apiCall(baseUrl + "admin/GetPublishedPeriodList", "POST", payload, "application/json");
}

async function setPublishedPeriodList(payload){
    return await apiCall(baseUrl + "admin/SetPublishedPeriodList", "POST", payload, "application/json");
}

async function updateInvoice(payload){
    return await apiCall(baseUrl + "admin/UpdateInvoice", "POST", payload, "application/json");
}


async function downloadFileInvoice(payload){
    return await apiCall(baseUrl + "file/downloadFileInvoice", "POST", payload, "application/json");
}

async function downloadInfoPrivacy(payload){
    return await apiCall(baseUrl + "file/DownloadInfoPrivacy", "POST", payload, "application/json");
}

async function multipleDownloadFileInvoice(payload){
    return await apiCall(baseUrl + "file/multipleDownloadFileInvoice", "POST", payload, "application/json");
}

async function deleteFormula(payload){
    return await apiCall(baseUrl + "admin/deleteFormula", "POST", payload, "application/json");
}

async function widgetApi(payload) {
    if (payload.name == "TariffaMediaYTDWidjetComponent") {
        return await apiCall(baseUrl + "widget/TariffaMediaYTDWidjetComponent", "POST", payload.payload, "application/json") 
    }
    if (payload.name == "TabellaImpiantiWidjetComponent") {
        return await apiCall(baseUrl + "widget/TabellaImpiantiWidjetComponent", "POST", payload.payload, "application/json")
    }
    if (payload.name == "SavingsYTDWidjetComponent") {
        return await apiCall(baseUrl + "widget/SavingsYTDWidjetComponent", "POST", payload.payload, "application/json")
    }
    if (payload.name == "SavingsTotalWidjetComponent") {
        return await apiCall(baseUrl + "widget/SavingsTotalWidjetComponent", "POST", payload.payload, "application/json")
    }
    if (payload.name == "SavingsEconomicoWidjetComponent") {
        return await apiCall(baseUrl + "widget/SavingsEconomicoWidjetComponent", "POST", payload.payload, "application/json")
    }
    if (payload.name == "RecapUltimeFattureWidjetComponent") {
        return await apiCall(baseUrl + "widget/RecapUltimeFattureWidjetComponent ", "POST", payload.payload, "application/json")
    }
    if (payload.name == "RecapSavingsWidjetComponent") {
        return await apiCall(baseUrl + "widget/RecapSavingsWidjetComponent", "POST", payload.payload, "application/json")
    }
    if (payload.name == "RecapEconomicsWidjetComponent") {
        return await apiCall(baseUrl + "widget/RecapEconomicsWidjetComponent", "POST", payload.payload, "application/json")
    }
    if (payload.name == "PercentQMGWidjetComponent") {
        return await apiCall(baseUrl + "widget/PercentQMGWidjetComponent", "POST", payload.payload, "application/json")
    }
    if (payload.name == "MappaImpiantiWidjetComponent") {
        return await apiCall(baseUrl + "widget/MappaImpiantiWidjetComponent", "POST", payload.payload, "application/json")
    }
    if (payload.name == "ListaFattureWidjetComponent") {
        return await apiCall(baseUrl + "widget/ListaFattureWidjetComponent", "POST", payload.payload, "application/json")
    }
    if (payload.name == "GraficoQMGWidjetComponent") {
        return await apiCall(baseUrl + "widget/GraficoQMGWidjetComponent", "POST", payload.payload, "application/json")
    }
    if (payload.name == "EnergiaTermicaQMGStagionaleWidjetComponent") {
        if (payload.viewType == 0) {
            return await apiCall(baseUrl + "widget/QMGStagionaleWidjetComponent", "POST", payload.payload, "application/json")
        } else {
            return await apiCall(baseUrl + "widget/QMGStagionaleWidjetComponentByMonth", "POST", payload.payload, "application/json")
        }
    }
    if (payload.name == "EnergiaTermicaQMGPercentWidjetComponent") {
        return await apiCall(baseUrl + "widget/QMGPercentWidjetComponent", "POST", payload.payload, "application/json")
    }
    if (payload.name == "EnergiaFrigoriferaQMGStagionaleWidjetComponent") {

        if (payload.viewType == 0) {
            return await apiCall(baseUrl + "widget/QMGStagionaleWidjetComponent", "POST", payload.payload, "application/json")
        } else {
            return await apiCall(baseUrl + "widget/QMGStagionaleWidjetComponentByMonth", "POST", payload.payload, "application/json")
        }
    }
    if (payload.name == "EnergiaFrigoriferaQMGPercentWidjetComponent") {
        return await apiCall(baseUrl + "widget/QMGPercentWidjetComponent", "POST", payload.payload, "application/json")
    }
    if (payload.name == "EnergiaElettricaQMGStagionaleWidjetComponent") {

        if (payload.viewType == 0) {
            return await apiCall(baseUrl + "widget/QMGStagionaleWidjetComponent", "POST", payload.payload, "application/json")
        } else {
            return await apiCall(baseUrl + "widget/QMGStagionaleWidjetComponentByMonth", "POST", payload.payload, "application/json")
        }
    }
    if (payload.name == "EnergiaElettricaQMGPercentWidjetComponent") {
        return await apiCall(baseUrl + "widget/QMGPercentWidjetComponent", "POST", payload.payload, "application/json")
    }
    if (payload.name == "DettaglioIndiciTariffeWidjetComponent") {

        if (payload.customPeriod.IsCustom) {
            payload.payload["FirstPeriod"] = payload.customPeriod.FirstPeriod;
            payload.payload["LastPeriod"] = payload.customPeriod.LastPeriod;

            return await apiCall(baseUrl + "widget/DettaglioIndiciTariffeWidjetComponentPeriod", "POST", payload.payload, "application/json")
        } else {
            payload.payload["ReferencePeriodId"] = payload.ReferencePeriodId;
            return await apiCall(baseUrl + "widget/DettaglioIndiciTariffeWidjetCYTDComponent", "POST", payload.payload, "application/json")
        }
    }
    if (payload.name == "DettaglioFormuleWidjetComponent") {
        return await apiCall(baseUrl + "widget/DettaglioFormuleWidjetComponent", "POST", payload.payload, "application/json")
    }
    if (payload.name == "DashboardTooltipResumeWidjetComponent") {
        return [];
    }
    if (payload.name == "ContactCustomerCareWidjetComponent") {
        return [];
    }
    if (payload.name == "AlberiPiantatiWidjetComponent") {
        return await apiCall(baseUrl + "widget/AlberiPiantatiWidjetComponent", "POST", payload.payload, "application/json")
    }
    return [];
}


function apiToString(url, body) {

    let cachable = {
        url: url,
        body: body
    };

    let bodystr = JSON.stringify(cachable);

    return bodystr;
}


async function apiCall(url, method = "GET", data = null, contentType = "application/json", forceDifferentToken = null) {

    // try add jwt token
    let token = getSessionKey("B2CToken");

    // Default options are marked with *
    const params = {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': contentType,
            'X-Content-Type-Options': "nosniff",
            'Content-Security-Policy': "default-src 'self'"            
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    };

    if (token != null) {
        params["headers"]["Authorization"] = 'Bearer ' + token;
    }

    if (forceDifferentToken != null) {
        params["headers"]["Authorization"] = 'Bearer ' + forceDifferentToken;
    }

    if (data != null) {

        let userMode = EncryptStorage.getItem('requested-show-mode');
        if (userMode != null && url.toLocaleLowerCase().indexOf("eon/api/widget") !== -1) {
            data["visualizationMode"] = userMode == 'A' ? 0 : 1;
        }

        params["body"] = JSON.stringify(data); // body data type must match "Content-Type" header
    }

    let response = null;


    try {
        response = await fetch(url, params);
    } catch (e) {
        response = { status: -1, message: e.message };
    }

    let output;

    if (response.status == 401 || response.status == 403) { // expired token
        window.location.href = "#/login";
        EncryptStorage.removeItem("session-data");
        EncryptStorage.setItem("logged-in", false);
    } else {

        let value = null;
        try {
            value = await response.json();
        } catch (e) {

        }

        output = { "raw": response, "parsed": value };
    }

    console.log("Api call", url, params, output);


    return output;
}


function toggleSpinner(toggle) {
    if (toggle) {

        let wrapper = document.createElement("div");
        let spinner = document.createElement("div");

        wrapper.id = "spinner-wrap";

        spinner.style.border = "0.5em solid transparent";
        spinner.style.borderTop = "0.5em solid var(--eon-red)";
        spinner.style.width = "min(30vw, 10em)";
        spinner.style.height = "min(30vw, 10em)";
        spinner.style.borderRadius = "50%";
        spinner.style.animation = "spin 0.5s linear infinite";

        wrapper.style.position = "fixed";
        wrapper.style.display = "flex";
        wrapper.style.alignItems = "center";
        wrapper.style.justifyContent = "center";
        wrapper.style.top = "0";
        wrapper.style.left = "0";
        wrapper.style.width = "100vw";
        wrapper.style.height = "100vh";
        wrapper.style.background = "rgba(255,255,255,0.8)";

        wrapper.appendChild(spinner);

        document.body.appendChild(wrapper);

    } else {
        while (1) {
            let wrapper = document.getElementById("spinner-wrap");
            if (wrapper != null) {
                wrapper.parentNode.removeChild(wrapper);
            } else {
                break;
            }
        }
    }
}

async function downloadFromUrl(url, filename) {
    fetch(url).then(function (t) {
        return t.blob().then((b) => {
            var a = document.createElement("a");
            a.href = URL.createObjectURL(b);
            a.setAttribute("download", filename);
            a.click();
        });
    });
}

export default ApiService;