import React, { Component } from 'react'
import LoadingSpinnerComponent from './../LoadingSpinnerComponent'
import ApiService from './../../services/ApiService'
import TranslationAgent from './../../translations/TranslationAgent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { parseJwt } from './../../services/ApiService'
import './../../assets/css/custom/widjets/DettaglioFormuleWidjetComponent.css'
import EncryptStorage from './../../storage/EncryptStorage'

class DettaglioFormuleWidjetComponent extends Component {
    constructor(props) {
        // eslint-disable-next-line
        super(props);
        this.state = {
            isLoading: true,
            formulas: []
        }
    }

    isVisibleAdmin() {

        const b2cToken = JSON.parse(EncryptStorage.getItem("session-data"))?.B2CToken;
        if (b2cToken) {
            const b2cToken = JSON.parse(EncryptStorage.getItem("session-data")).B2CToken;
            if (b2cToken) {
                const parsedToken = parseJwt(b2cToken);
                return parsedToken.idp != null;
            }

            return false;
        }
    }


    componentDidMount() {
        let plantId = EncryptStorage.getItem('requested-plant-location-billing');
        let userId = JSON.parse(EncryptStorage.getItem("session-data"))?.userId
        let role = this.isVisibleAdmin() ? EncryptStorage.getItem("requested-show-mode") : "";

        if (plantId != null) {
            /* ApiCall to update status */
            ApiService.widgetApi({
                "name": "DettaglioFormuleWidjetComponent",
                "payload": { PlantId: (+plantId), UserId: userId, Role: role }
            }).then((res) => {
                if (this.props.onIsReady) { this.props.onIsReady(); }
                this.setState({ formulas: (res == null) ? [] : res.parsed, isLoading: false });
            })
        }
    }

    render() {

        let formulas = [];
        this.state.formulas?.forEach((item, i) => {
            formulas.push(
                <div key={i} className="formula-wrapper row">
                    <div className="col-sm-6">
                        <div className="formula-title" style={{ whiteSpace: "pre-wrap" }}>{item.formulaName}</div>

                    </div>
                    <div className="col-sm-6">
                        <div className="formula-value" style={{ whiteSpace: "pre-wrap" }}>{
                        EncryptStorage.getItem("language") == "it" ? item.formula.replace(".", ',') :
                        item.formula}</div>
                    </div>
                    <div className="row ml-2">
                        <div className="col-sm-12">
                            <div className="formula-subtitle" style={{ whiteSpace: "pre-wrap" }}>*{item.description}</div>
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <>
                <div className="formula-detail-toggle-element"
                    onClick={() => {
                        document.getElementById("formulaDetail").style.display = "flex";
                    }}>
                    {this.state.isLoading && (<LoadingSpinnerComponent />)}
                    {!this.state.isLoading && (
                        <div>
                            <div className="formula-detail-toggle-row" style={{cursor:"pointer"}}>
                                <FontAwesomeIcon icon={faInfoCircle} />
                                <div style={{ "marginLeft": "1em" }}>{TranslationAgent.localizeString("formula_detail")}</div>
                            </div>

                        </div>
                    )}
                </div>
                <div id="formulaDetail" class="modal" tabindex="-1" role="dialog">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" style={{ fontWeight: "bold" }}>{TranslationAgent.localizeString("formula_details_popup_tilte")}</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                                    document.getElementById("formulaDetail").style.display = "none";
                                }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body"> 
                            <div id="formulas">
                                {formulas}
                            </div>
                            </div>
                            <div class="modal-footer" style={{ justifyContent: "flex-end" }}>
                                <div style={{ alignSelf: "center" }} className="general-top-widjet-button" onClick={() => { let v = document.getElementById("formulaDetail"); if (v != null) { v.style.display = "none" } }}>{TranslationAgent.localizeString("close")}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default DettaglioFormuleWidjetComponent