import React, { Component } from 'react'
import ApiService from '../services/ApiService'
import WidgetRenderComponent from '../components/WidgetRenderComponent'
import DropdownComponent from '../components/DropdownComponent'
import BackButtonComponent from '../components/BackButtonComponent'
import TranslationAgent from '../translations/TranslationAgent';
import '../assets/css/custom/PlantView.css'
import IconService from '../services/IconService'
import { parseJwt } from './../services/ApiService'
import EncryptStorage from './../storage/EncryptStorage'

class ChartsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plants: [],
            companies: [],
            showPage: false
        }

        this.changedPlant = this.changedPlant.bind(this);
        this.changedCompany = this.changedCompany.bind(this);
    }

    getUserId() {
        return JSON.parse(EncryptStorage.getItem("session-data"))?.userId;
    }


    isVisibleAdmin() {

        const b2cToken = JSON.parse(EncryptStorage.getItem("session-data"))?.B2CToken;
        if (b2cToken) {
            const b2cToken = JSON.parse(EncryptStorage.getItem("session-data")).B2CToken;
            if (b2cToken) {
                const parsedToken = parseJwt(b2cToken);
                return parsedToken.idp != null;
            }

            return false;
        }
    }

    changedPlant(e) {
        EncryptStorage.setItem('requested-plant-location', e.target.value);
        EncryptStorage.setItem('requested-plant-location-billing', e.target.value);
        EncryptStorage.removeItem('requested-reference-year');
        window.getActivePeriod();
        window.App.reloadApp();
    }

    changedCompany(e) {

        EncryptStorage.removeItem('can-see-economics');
        EncryptStorage.setItem('requested-company-location', e.target.value);
        EncryptStorage.setItem('requested-plant-location', 0);
        EncryptStorage.setItem('requested-plant-location-billing', 0);
        EncryptStorage.removeItem('requested-reference-year');
        ApiService.widgetEconomicsVisibility({ UserId: this.getUserId(), CompanyId: e.target.value }).then((res) => {
            if (res?.raw?.status === 200) {
                this.setState({ showPage: true });
                EncryptStorage.setItem('can-see-economics', res.parsed.showEconomicsWidget);
                ApiService.getRegisteredPlantsList({ CompanyId: e.target.value }).then((res) => {
                    this.setState({ plants: (res == null) ? [] : res.parsed });
                    EncryptStorage.setItem("requested-plant-location", res?.parsed[0]?.plantId);
                    EncryptStorage.setItem("requested-plant-location-billing", res?.parsed[0]?.plantId);
                })
            }
        }).finally(() => {
            window.getActivePeriod();
            ApiService.toggleSpinner(false);
        });
    }

    componentDidMount() {
        let companyId = 0;
        if (JSON.parse(EncryptStorage.getItem("session-data"))?.isEon) {
            ApiService.getCustomerAnagraphics({ UserId: this.getUserId() }).then((res) => { this.setState({ companies: (res == null) ? [] : res.parsed }); })
            companyId = JSON.parse(EncryptStorage.getItem('requested-company-location'));
        }
        else {
            companyId = JSON.parse(EncryptStorage.getItem("session-data"))?.companyId;
        }

        if (companyId) {
            /* ApiCall to update status */
            ApiService.getRegisteredPlantsList({ CompanyId: companyId }).then((res) => {
                this.setState({ plants: (res == null) ? [] : res.parsed });

                let plantId = EncryptStorage.getItem('requested-plant-location') ? EncryptStorage.getItem('requested-plant-location') : res?.parsed[0]?.plantId;
                EncryptStorage.setItem("requested-plant-location", plantId);
                EncryptStorage.setItem("requested-plant-location-billing", plantId);
                let userId = JSON.parse(EncryptStorage.getItem("session-data"))?.userId;

                EncryptStorage.removeItem('can-see-benchmark-widget');

                if (plantId) {
                    ApiService.toggleSpinner(true);
                    ApiService.widgetVisibility({ UserId: userId, PlantId: +plantId }).then((res) => {
                        if (res?.raw?.status === 200) {
                            this.setState({ showPage: true });
                            EncryptStorage.setItem('can-see-benchmark-widget', res.parsed.showWidget == "S");
                        }
                    }).finally(() => {
                        ApiService.toggleSpinner(false);
                    });

                }
            });
        }
    }

    render() {
        const plants = [];
        if (this.state.plants) {
            this.state.plants?.forEach((item, i) => {
                plants.push({ text: item.plantName, value: item.plantId });
            })

            let pickedPlant = EncryptStorage.getItem('requested-plant-location');
            if (pickedPlant == 0 && plants[0]?.value && pickedPlant != plants[0]?.value && plants.length > 0) {
                EncryptStorage.setItem('requested-plant-location', plants[0]?.value);
                EncryptStorage.setItem('requested-plant-location-billing', plants[0]?.value);
                pickedPlant = plants[0]?.value;
                window.App.reloadApp();
            }
        }

        const companies = [];
        if (this.state.companies) {
            this.state.companies?.forEach((item, i) => {
                companies.push({ text: item.companyName, value: item.companyId });
            })
        }

        window.addEventListener("resize", () => {
            let selCompany = document.getElementById("select-company");
            let selRepCompany = document.getElementById("replace-select-company");
            if (window.innerWidth < 1025) {
                if (selCompany) {
                    selCompany.classList.add("col-md-4")
                    selCompany.classList.remove("col-md-3")
                }
                if (selRepCompany) {
                    selRepCompany.classList.add("col-md-1")
                    selRepCompany.classList.remove("col-md-2")
                    selRepCompany.classList.remove("col-md-3")
                }
            }
            else{
                if (selCompany) {
                    selCompany.classList.remove("col-md-4")
                    selCompany.classList.add("col-md-3")
                }
                if (selRepCompany) {
                    selRepCompany.classList.remove("col-md-1")
                    selRepCompany.classList.add("col-md-2")
                    selRepCompany.classList.remove("col-md-3")
                }
            }

        });

        return (
            <div className="content mt-1 slow-intro-fade" id="plantView">
                <div className="container-fluid">
                    <div className="col-md-12">
                        <div className="row">

                            <div className="col-md-1">
                                <BackButtonComponent backurl={"#/home"} title={""} />
                            </div>

                            {
                                JSON.parse(EncryptStorage.getItem("session-data"))?.isEon && this.isVisibleAdmin()

                                && <div id="select-company" className={window.innerWidth < 1025 ? "col-md-4" : "col-md-3"} style={{ "marginRight": "19px" }}>
                                    <DropdownComponent title={TranslationAgent.localizeString("select_company")} items={companies} currentValue={EncryptStorage.getItem('requested-company-location')} changeCallback={this.changedCompany} />
                                </div>
                            }

                            <div className="col-md-3">
                                <DropdownComponent title={TranslationAgent.localizeString("select_plant")} items={plants} currentValue={EncryptStorage.getItem('requested-plant-location')} changeCallback={this.changedPlant} />
                            </div>
                            {
                                JSON.parse(EncryptStorage.getItem("session-data"))?.isEon && this.isVisibleAdmin() ?
                                    <div id="replace-select-company" className={window.innerWidth < 1025 ? "col-md-4" : "col-md-3"}>

                                    </div>
                                    :
                                    <div className="col-md-5">

                                    </div>
                            }

                            <div className="col-md-3" style={{ display: "grid", justifyContent: "right" }} >
                                <img className={"eon-logo-icon"} src={IconService.eon_logo} />
                            </div>
                        </div>
                        {this.state.showPage && this.state.plants?.length > 0 && EncryptStorage.getItem('requested-plant-location') && (<WidgetRenderComponent title="plant" />)}
                    </div>
                </div>
            </div>
        )
    }
}

export default ChartsView