import React, { Component } from 'react'
import LoadingSpinnerComponent from './../LoadingSpinnerComponent'
import IconService from './../../services/IconService'
import ApiService from './../../services/ApiService'
import SmallBoxedWidjetComponent from './SmallBoxedWidjetComponent'
import TranslationAgent from './../../translations/TranslationAgent'
import moment from 'moment'
import './../../assets/css/custom/widjets/RecapUltimeFattureWidjetComponent.css'
import 'bulma/css/bulma.css'
import 'material-design-icons/iconfont/material-icons.css'
import PDFViewer from 'pdf-viewer-reactjs'
import { toast } from 'react-toastify'
import {parseJwt} from './../../services/ApiService'
import EncryptStorage from './../../storage/EncryptStorage'

class RecapUltimeFattureWidjetComponent extends Component {
    constructor(props) {
        // eslint-disable-next-line
        super(props);
        this.state = {
            isLoading: true,
            bills: [],
            billToViewContent: null,
            billToView: null
        }

        this.viewBill = this.viewBill.bind(this);
    }

    isVisibleAdmin() {

        const b2cToken = JSON.parse(EncryptStorage.getItem("session-data"))?.B2CToken;
        if (b2cToken) {
            const b2cToken = JSON.parse(EncryptStorage.getItem("session-data")).B2CToken;
            if (b2cToken) {
                const parsedToken = parseJwt(b2cToken);
                return parsedToken.idp != null;
            }

            return false;
        }
    }
    componentDidMount() {

        let plantId = EncryptStorage.getItem('requested-plant-location');
        let userId = JSON.parse(EncryptStorage.getItem("session-data"))?.userId
        let role = this.isVisibleAdmin() ? EncryptStorage.getItem("requested-show-mode") : "";
        if (plantId != null) {
            /* ApiCall to update status */
            ApiService.widgetApi({
                "name": "RecapUltimeFattureWidjetComponent",
                "payload": { PlantId: (+plantId), UserId: userId, Role: role }
            }).
                then((res) => {
                    if (this.props.onIsReady) { this.props.onIsReady(); }

                    this.setState({ bills: (res == null) ? [] : res.parsed, isLoading: false });
                });

        }

    }


    base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }


    saveByteArray(reportName, byte, type = "application/pdf") {
        var blob = new Blob([byte], { type: type });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = reportName;
        link.download = fileName;
        link.click();
    };

    downloadFile(filePath, fileName, invoiceId) {
        ApiService.toggleSpinner(true)
        ApiService.downloadFileInvoice({
            UserId: this.getUserId(),
            FilePath: filePath,
            Filename: fileName,
            InvoiceId: invoiceId
        }).then((res) => {
            var sampleArr = this.base64ToArrayBuffer(res.parsed?.value);
            this.saveByteArray(fileName, sampleArr);
        }).finally(() => {
            ApiService.toggleSpinner(false);
        })
    }


    getUserId() {
        return JSON.parse(EncryptStorage.getItem("session-data"))?.userId;
    }

    isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

    viewBill(bill) {

        if (bill.proformaPath) {
            ApiService.toggleSpinner(true)
            this.setState({
                billToView: bill
            })
            ApiService.downloadFileInvoice({
                UserId: this.getUserId(),
                FilePath: bill.proformaPath,
                Filename: bill.proformaName,
                InvoiceId: bill.invoiceId
            }).then((res) => {
                this.setState({
                    billToViewContent: res.parsed?.value
                });

                let v = document.getElementById("pdfPreview");
                if (v != null) {
                    v.style.display = "block";
                }
            }).finally(() => {
                ApiService.toggleSpinner(false);
            })
        }
        else {
            toast.warning(TranslationAgent.localizeString("pdf_not_found"), {
                hideProgressBar: true,
                autoClose: true,
                draggable: true,
            })
        }


    }

    render() {

        let prices = [];
        this.state.bills.forEach((item, i) => {
            prices.push(Intl.NumberFormat(EncryptStorage.getItem("language"), { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.invoiceValue));
        });

        let specialClass = "";
        if (prices.filter(x => x.length > 8).length == 3) {
            specialClass = " fixed-prices-tags";
        }

        let bills = [];
        this.state.bills.forEach((item, i) => {
            bills.push(
                <div onClick={() => this.viewBill(item)} className="ultime-fatture-wrapper" key={i}>
                    <div className="ultime-fatture-label">{moment(item.releaseDate).format("MMMM YYYY")}</div>
                    <img className="ultime-fatture-icon" src={IconService[item.invoiceState.toLowerCase() == "paid" ? "paid_bill" : "not_paid_bill"]} />
                    <div className={"ultime-fatture-price" + specialClass}>{prices[i]}€</div>

                </div>);
        });

        let billsToDisplay = <div className="ultime-fatture-row-wrap">{bills}</div>;

        const buttons = [
            { "buttonLabel": TranslationAgent.localizeString("invoice_details"), "redirectUrl": "invoices" },
        ];

        return (
            <div>
                {this.state.isLoading && (<LoadingSpinnerComponent />)}
                {!this.state.isLoading && (
                    <div>
                        <SmallBoxedWidjetComponent
                            emptyExtraContentLabel={bills.length > 0 ? null: TranslationAgent.localizeString('no_bill_available')}
                            extracontent={billsToDisplay}
                            buttons={buttons}
                            maxHeightclass={"fixedHeight"}
                        />
                    </div>
                )}
                {this.state.billToViewContent &&
                    <div id="pdfPreview" class="modal" tabindex="-1" role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" class="close" onClick={() => { let v = document.getElementById("pdfPreview"); if (v != null) { v.style.display = "none"; this.setState({ billToViewContent: null }) } }} data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body" style={{ minWidth: "auto", maxHeight: "auto", overflowY: "scroll" }}>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="button-boxed-widjet" style={{ "margin": "2em" }} onClick={() => {
                                                this.downloadFile(this.state.billToView.proformaPath, this.state.billToView.proformaName, this.state.billToView.invoiceId)
                                            }}>
                                                {TranslationAgent.localizeString("download_pdf")
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-10">

                                        </div>
                                        <div className="col-md-12">
                                            <PDFViewer document={{ base64: this.state.billToViewContent }}></PDFViewer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default RecapUltimeFattureWidjetComponent