import React, { Component } from 'react'
import FactoryRenderComponent from './FactoryRenderComponent'
import EncryptStorage from './../storage/EncryptStorage'

import './../assets/css/custom/LoadingSpinnerComponent.css'
import LoadingSpinnerComponent from './LoadingSpinnerComponent';
import ApiService from './../services/ApiService'
import TranslationAgent from '../translations/TranslationAgent';

class WidgetRenderComponent extends Component {
  constructor(props) {
      // eslint-disable-next-line
      super(props);
      this.state = {
          loadedElements: 0,
          pageContent: [],
          loading: true
      }
      this.processContentToRender = this.processContentToRender.bind(this);
      this.childIsReady = this.childIsReady.bind(this);
      this.countContent = this.countContent.bind(this);

    }

    countContent(content) {
        if (Array.isArray(content)) {
            let c = 0;
            for (let j = 0; j < content.length; j++) {
                let item = content[j];
                if (Array.isArray(item.Content)) {
                    for (let i = 0; i < item.Content.length; i++) {
                        c += this.countContent(item.Content[i]);
                    }
                }
                else {
                    c += 1;
                }
            }
            return c;

        } else
        {
            let c = 0;
            if (Array.isArray(content.Content)) {
                for (let i = 0; i < content.Content.length; i++) {
                    c += this.countContent(content.Content[i]);
                }
                return c;
            }
            else {
                return 1;
            }
        }
    }

    childIsReady() {
        this.setState({ loadedElements: this.state.loadedElements + 1 });
    }

    componentDidMount() {
        /* ApiCall to update status */
        ApiService.getPageDynamicContent(this.props.title).then((res) => { this.setState({ pageContent: res, loading: false, loadedElements: 0}); })
    }

    processContentToRender(){

        let pageContent = [];
        let toRender = this.state.pageContent;

        if (toRender == null) {
            return (<div>{TranslationAgent.localizeString("no_content_for_page")}</div>);
        }
        for (let i = 0; i < toRender.length; i++) {
            pageContent.push(<FactoryRenderComponent onIsReady={this.childIsReady} title={this.props.title} key={i} alignment={this.props.alignment} component={toRender[i]} />);
        }

        return pageContent;
    }

    render() {
        let displayWidgetStyle = this.props.waitWidjets == true && (this.countContent(this.state.pageContent) > this.state.loadedElements) ? { "animation": "none", "opacity":"0" } : { "animation": "fadeIn 1s ease-out" };
        displayWidgetStyle["transition"] = "opacity 1s ease-in";;

        let pageContent = this.processContentToRender();
        return (
            <div style={{ "padding": "0.5em" }}>
                {this.state.loading ? <LoadingSpinnerComponent /> : <div style={displayWidgetStyle}>{pageContent}</div>}
                {!this.state.loading && this.props.waitWidjets == true && (this.countContent(this.state.pageContent) > this.state.loadedElements) ? <div className="special-spinner-overlay"><LoadingSpinnerComponent /></div> : <div></div>}
        </div>
    )
  }
}
export default WidgetRenderComponent;
