import React, { Component } from 'react'
import LoadingSpinnerComponent from './../LoadingSpinnerComponent'
import ApiService from './../../services/ApiService'
import SmallBoxedWidjetComponent from './SmallBoxedWidjetComponent'
import TranslationAgent from './../../translations/TranslationAgent'
import {parseJwt} from './../../services/ApiService'
import EncryptStorage from './../../storage/EncryptStorage'

import './../../assets/css/custom/widjets/SavingsEconomicoWidjetComponent.css'

class SavingsEconomicoWidjetComponent extends Component {
    constructor(props) {
        // eslint-disable-next-line
        super(props);
        this.state = {
            isLoading: true,
            label1: "",
            label2: ""
        }
    }

    isVisibleAdmin() {

        const b2cToken = JSON.parse(EncryptStorage.getItem("session-data"))?.B2CToken;
        if (b2cToken) {
            const b2cToken = JSON.parse(EncryptStorage.getItem("session-data")).B2CToken;
            if (b2cToken) {
                const parsedToken = parseJwt(b2cToken);
                return parsedToken.idp != null;
            }

            return false;
        }
    }
    componentDidMount() {
        let plantId = EncryptStorage.getItem('requested-plant-location');
        let userId = JSON.parse(EncryptStorage.getItem("session-data"))?.userId
        let role = this.isVisibleAdmin() ? EncryptStorage.getItem("requested-show-mode") : "";
        if (plantId != null) {
            /* ApiCall to update status */
            ApiService.widgetApi({
                "name": "SavingsEconomicoWidjetComponent",
                "payload": { PlantId: (+plantId), UserId: userId, Role: role }
            }).then((res) => {
                if (this.props.onIsReady) { this.props.onIsReady(); }

                let value1 = "-";
                let value2 = "-";

                if (res != null) {
                    if (res.parsed != null) {
                        value1 = res.parsed.thisYear;
                        value2 = res.parsed.total;
                    }
                }

                this.setState({ label1: (res == null) ? "" : parseInt(value1, 10) / 1000, label2: (res == null) ? "" : parseInt(value2, 10) / 1000, isLoading: false });
            })
        }
    }

    isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

    render() {

        let canBeSeen = EncryptStorage.getItem('can-see-economics') == "S";

        return (
            <>
                {canBeSeen &&
                    <div>
                        {this.state.isLoading && (<LoadingSpinnerComponent />)}
                        {!this.state.isLoading && (
                            <div>

                                <>
                                    <SmallBoxedWidjetComponent
                                    label={(this.state.label1 != null ?
                                        !this.isNumber(this.state.label1) ? this.state.label1 : new Intl.NumberFormat(EncryptStorage.getItem("language"), { maximumFractionDigits: 0 }).format(this.state.label1) : "")}
                                    underlabel={"k€"}
                                    subtitle={TranslationAgent.localizeString("savings_start_year_eur")} />
                                    <div className="economics-small-separator"></div>
                                    <div className="economics-lower-big">{(this.state.label2 != null ?
                                        !this.isNumber(this.state.label2) ? this.state.label2 : new Intl.NumberFormat(EncryptStorage.getItem("language"), { maximumFractionDigits: 0 }).format(this.state.label2) : "")}</div>
                                    <div className="economics-lower-mid">{"k€"}</div>
                                    <div className="economics-lower-small">{TranslationAgent.localizeString("savings_total_eur")}</div>
                                    {/* <div className="economics-lower-tiny">{TranslationAgent.localizeString("value_computed_compared_to_avg_market_price")}</div> */}
                                </>
                            </div>
                        )}
                    </div>
                }
            </>
        )
    }
}

export default SavingsEconomicoWidjetComponent