import React, { Component } from 'react'
import ApiService from '../services/ApiService'
import BackButtonComponent from '../components/BackButtonComponent'
import LoadingSpinnerComponent from '../components/LoadingSpinnerComponent'
import moment from 'moment'
import ColoredTableComponent from '../components/ColoredTableComponent'
import { toast } from 'react-toastify';
import TranslationAgent from '../translations/TranslationAgent'
import '../assets/css/custom/PlantDetailView.css'
import ErrorService from '../services/ErrorService'
import IconService from '../services/IconService'
import EncryptStorage from './../storage/EncryptStorage'

class PlantDetailView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formulas: [],
            referencePeriods: [],
            plant: null,
            isLoadingFormulas: true,
            isLoadingPeriods: true,
            isLoadingPlant: true
        }
        window.PlantDetailView = this;
        this.dateFormat = this.dateFormat.bind(this);
        this.isLoadingPlant = this.isLoadingPlant.bind(this);
        this.deleteFormula = this.deleteFormula.bind(this);
    }

    isLoadingPlant(e) {

    }


    getPlantId() {
        const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
        return urlParams.get('plantId');
    }

    getUserId() {
        return JSON.parse(EncryptStorage.getItem("session-data"))?.userId;
    }

    dateFormat(date) {
        return date.split("T")[0];
    }

    deleteFormula(idFormula) {
        ApiService.toggleSpinner(true);
        ApiService.deleteFormula({
            FormulaId: idFormula
        }).then((res) => {
            if (res.raw.status === 200) {
                
                toast.success(TranslationAgent.localizeString("formula_delete_success"), {
                    hideProgressBar: true,
                    autoClose: true,
                    draggable: true,
                })
                ApiService.getFormulasAnagraphics({ PlantId: this.getPlantId(), UserId: this.getUserId() }).then((res) => { this.setState({ formulas: (res == null) ? [] : res.parsed, isLoadingFormulas: false }); });

            } else {
                let msg = ErrorService.extractError(res);
                if (msg)
                toast.error(msg, {
                    hideProgressBar: true,
                    autoClose: true,
                    draggable: true,
                })
            }
        })
            .finally(() => {
                ApiService.toggleSpinner(false);
            })

    }

    formatData(res) {
        res["vectors"] = [];
        res["vectors"].push({ "idbool": "electricVector", "enabled": res.electricVector == "S", "idsel": "electricNominal", "nominal": res.electricNominal.toString() });
        res["vectors"].push({ "idbool": "termicVector", "enabled": res.termicVector == "S", "idsel": "termicNominal", "nominal": res.termicNominal.toString() });
        res["vectors"].push({ "idbool": "coolingVector", "enabled": res.coolingVector == "S", "idsel": "coolingNominal", "nominal": res.coolingNominal.toString() });
        // res["vectors"].push({ "idbool": "hotWaterVector", "enabled": res.hotWaterVector == "S", "idsel": "hotWaterNominal", "nominal": res.hotWaterNominal.toString() });

        return res;
    }

    componentDidMount() {
        /* ApiCall to update status */
        ApiService.getReferencePeriods({ PlantId: this.getPlantId(), UserId: this.getUserId() }).then((res) => { this.setState({ referencePeriods: (res == null) ? [] : res.parsed, isLoadingPeriods: false }); });

        ApiService.getFormulasAnagraphics({ PlantId: this.getPlantId(), UserId: this.getUserId() }).then((res) => { this.setState({ formulas: (res == null) ? [] : res.parsed, isLoadingFormulas: false }); });

        ApiService.getPlantProfile({ PlantId: this.getPlantId(), UserId: this.getUserId() }).then((res) => { this.setState({ plant: (res == null) ? null : this.formatData(res.parsed), isLoadingPlant: false }); });

    }

    checkBeforeSave() {

        if (!document.getElementById("plantName")?.value) {
            toast.error(TranslationAgent.localizeString("plant_mandatory_name"), {
                hideProgressBar: true,
                autoClose: true,
                draggable: true,
            })
            return false;
        }

        if (!document.getElementById("plantCode")?.value) {
            toast.error(TranslationAgent.localizeString("plant_mandatory_code"), {
                hideProgressBar: true,
                autoClose: true,
                draggable: true,
            })
            return false;
        }

        return true;
    }

    render() {

        let lastItem = null;
        let periodReferences = [];

        let sortedPeriods = this.state.referencePeriods.sort((a, b) => {
            if (a.startDate > b.startDate) { return 1; }
            if (a.startDate < b.startDate) { return -1; }
            return 0;
        });

        sortedPeriods.forEach((item, i) => {
            periodReferences.push([item.referencePeriodName, moment(item.startDate).format("LL"), moment(item.endDate).format("LL"), "<button class=\"table-inrow-button\" onclick=\"EncryptStorage.setItem('stored-plantId', " + this.getPlantId() + ");  window.location.href = '.#/period_detail?periodId=" + item.referencePeriodId + "' \">" + TranslationAgent.localizeString("edit") + "</button>"]);
            lastItem = item;
        });


        let periodReferencesHeaders = [
            TranslationAgent.localizeString("plant_detail_period_name"),
            TranslationAgent.localizeString("plant_detail_period_from"),
            TranslationAgent.localizeString("plant_detail_period_to"),
            "",
        ];

        let lang = EncryptStorage.getItem("language");
        if (lang == null) {
            lang = "en";
        }

        let furmulas = [];
        this.state.formulas?.forEach((item, i) => {
            furmulas.push(["<span>" + item.ref + "</span>", item.formulaName, item.description, item.formula, item.visualization,
            "<button class=\"table-inrow-button mr-2\" onclick=\"window.location.href = '.#/edit_formula?formulaId=" + item.formulaId + "' \">" + TranslationAgent.localizeString("edit") + "</button>" +
            "<button class=\"table-inrow-button\" onclick=\"window.PlantDetailView.deleteFormula('" + item.formulaId + "');\">" + TranslationAgent.localizeString("erease") + "</button>",
            ]);
        });
        EncryptStorage.setItem("known-formulas", JSON.stringify(this.state.formulas));

        let furmulasHeaders = [
            TranslationAgent.localizeString("plant_detail_formula_ref"),
            TranslationAgent.localizeString("plant_detail_formula_name"),
            TranslationAgent.localizeString("plant_detail_formula_description"),
            TranslationAgent.localizeString("plant_detail_formula_value"),
            TranslationAgent.localizeString("plant_detail_formula_order"),
            "",
        ];

        let powerVectorsRows = [
            [TranslationAgent.localizeString("energetic_electric_power"), "", ""],
            [TranslationAgent.localizeString("energetic_termic_power"), "", ""],
            [TranslationAgent.localizeString("energetic_cooling_power"), "", ""],
            // [TranslationAgent.localizeString("energetic_hotWater_power"), "", ""],
        ];

        if (this.state.plant != null) {
            this.state.plant.vectors.forEach((item, i) => {
                let sel = "<select id=\"" + item.idbool + "\" ><option " + (item.enabled ? "selected=\"true\"" : "") + " value=\"true\" >" + TranslationAgent.localizeString("yes") + "</option><option " + (!item.enabled ? "selected=\"true\"" : "") + " value=\"false\">" + TranslationAgent.localizeString("no") + "</option></select>"
                powerVectorsRows[i][1] = sel;
                powerVectorsRows[i][2] = "<input id=\"" + item.idsel + "\" class=\"invisible-input-style\" value=\"" + item.nominal + "\"  placeholder=\"" + TranslationAgent.localizeString("energetic_vectors_capacity") + "\" type=\"number\">";
            });
        }

        let powerVectorsHeaders = [
            TranslationAgent.localizeString("energetic_vectors"),
            TranslationAgent.localizeString("energetic_vectors_available"),
            TranslationAgent.localizeString("energetic_vectors_capacity"),
        ];


        return (
            <div className="content mt-1">
                <div className="container-fluid">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-9">
                                <BackButtonComponent backurl={null} title={TranslationAgent.localizeString("plant_detail")} />
                            </div>
                            <div className="col-md-3" style={{ display: "grid", justifyContent: "right" }} >
                                <img className={"eon-logo-icon"} src={IconService.eon_logo} />
                            </div>
                        </div>
                    </div>
                    <div className="admin-content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="admin-content-title">{TranslationAgent.localizeString("plant_detail")}</div>
                                <div>
                                    {this.state.isLoadingPlant && (<LoadingSpinnerComponent />)}
                                    {!this.state.isLoadingPlant && (
                                        <div className="admin-detail-box">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_name")}</div>
                                                    <div className="admin-detail-box-input-wrap">
                                                        <input id="plantName" defaultValue={this.state.plant.plantName} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_name")} type="text" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_address")}</div>
                                                    <div className="admin-detail-box-input-wrap">
                                                        <input id="address" defaultValue={this.state.plant.address} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_address")} type="text" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_code")}</div>
                                                    <div className="admin-detail-box-input-wrap">
                                                        <input id="plantCode" defaultValue={this.state.plant.plantCode} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_code")} type="text" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_emitunit")}</div>
                                                    <div className="admin-detail-box-input-wrap">
                                                        <input id="issuingUnit" defaultValue={this.state.plant.issuingUnit} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_emitunit")} type="text" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_nametar")}</div>
                                                    <div className="admin-detail-box-input-wrap">
                                                        <input id="ratePlantName" defaultValue={this.state.plant.ratePlantName} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_nametar")} type="text" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_namearm")}</div>
                                                    <div className="admin-detail-box-input-wrap">
                                                        <input id="armonizedPlantName" defaultValue={this.state.plant.armonizedPlantName} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_namearm")} type="text" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_contr_decorr")}</div>
                                                    <div className="admin-detail-box-input-wrap">
                                                        <input id="effectiveDate" defaultValue={this.dateFormat(this.state.plant.effectiveDate)} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_contr_decorr")} type="date" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_contr_expire")}</div>
                                                    <div className="admin-detail-box-input-wrap">
                                                        <input id="endDate" defaultValue={this.dateFormat(this.state.plant.endDate)} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_contr_expire")} type="date" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_tree_target")}</div>
                                                    <div className="admin-detail-box-input-wrap">
                                                        <input id="treeTarget" defaultValue={this.state.plant.treeTarget} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_tree_target")} type="text" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_show_widget_tariffa")}</div>
                                                    <div className="admin-detail-box-input-wrap">
                                                        <select id="showWidget" style={{ "height": "1.9em" }} defaultValue={this.state.plant.showWidget == "S"} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_show_widget_tariffa")} type="text">
                                                            <option value="true">{TranslationAgent.localizeString("yes")} </option>
                                                            <option value="false">{TranslationAgent.localizeString("no")} </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_cop")}</div>
                                                    <div className="admin-detail-box-input-wrap">
                                                        <input id="cop" defaultValue={this.state.plant.cop} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_cop")} type="text" />
                                                    </div>
                                                </div>
                                            </div>                      
                                            {/* wip */}
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("min_gauge")}</div>
                                                    <div className="admin-detail-box-input-wrap">
                                                    <input id="minGauge" defaultValue={this.state.plant.minGauge} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("min_gauge")} type="text" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("max_gauge")}</div>
                                                    <div className="admin-detail-box-input-wrap">
                                                    <input id="maxGauge" defaultValue={this.state.plant.maxGauge} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("max_gauge")} type="text" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("target_gauge")}</div>
                                                    <div className="admin-detail-box-input-wrap">
                                                    <input id="targetGauge" defaultValue={this.state.plant.targetGauge} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("target_gauge")} type="text" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* end wip */}
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_lat")}</div>
                                                    <div className="admin-detail-box-input-wrap">
                                                        <input id="lat" defaultValue={this.state.plant.lat} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_lat")} type="text" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_long")}</div>
                                                    <div className="admin-detail-box-input-wrap">
                                                        <input id="long" defaultValue={this.state.plant.long} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_long")} type="text" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="admin-content-title">{TranslationAgent.localizeString("energetic_vectors")}</div>
                                                    <ColoredTableComponent rows={powerVectorsRows} headers={powerVectorsHeaders} isLoading={this.state.isLoadingPlant} />
                                                </div>
                                            </div>

                                            <div className="button-boxed-widjet" style={{ "marginTop": "2em" }} onClick={() => {

                                                if (!this.checkBeforeSave()) {
                                                    return;
                                                }

                                                ApiService.toggleSpinner(true);
                                                ApiService.updatePlant({
                                                    PlantId: +this.getPlantId(),
                                                    PlantName: document.getElementById("plantName").value,
                                                    RatePlantName: document.getElementById("ratePlantName").value,
                                                    ArmonizedPlantName: document.getElementById("armonizedPlantName").value,
                                                    IssuingUnit: document.getElementById("issuingUnit").value,
                                                    PlantCode: document.getElementById("plantCode").value,
                                                    Address: document.getElementById("address").value,
                                                    Lat: document.getElementById("lat").value,
                                                    Long: document.getElementById("long").value,
                                                    ElectricVector: document.getElementById("electricVector").value == "true" ? "S" : "N",
                                                    TermicVector: document.getElementById("termicVector").value == "true" ? "S" : "N",
                                                    CoolingVector: document.getElementById("coolingVector").value == "true" ? "S" : "N",
                                                    HotWaterVector: "N",
                                                    ElectricNominal: +document.getElementById("electricNominal").value,
                                                    TermicNominal: +document.getElementById("termicNominal").value,
                                                    CoolingNominal: +document.getElementById("coolingNominal").value,
                                                    HotWaterNominal: 0,
                                                    EffectiveDate: document.getElementById("effectiveDate").value,
                                                    EndDate: document.getElementById("endDate").value,
                                                    TreeTarget: +document.getElementById("treeTarget").value,
                                                    ShowWidget: document.getElementById("showWidget").value == "true" ? "S" : "N",
                                                    UpdatedBy: +this.getUserId(),
                                                    Cop: document.getElementById("cop").value,
                                                    MinGauge: +document.getElementById("minGauge").value,
                                                    MaxGauge: +document.getElementById("maxGauge").value,
                                                    TargetGauge: +document.getElementById("targetGauge").value
                                                }).then((res) => {

                                                    if (res.raw.status === 200) {
                                                        toast.success(TranslationAgent.localizeString("plant_update_success"), {
                                                            hideProgressBar: true,
                                                            autoClose: true,
                                                            draggable: true,
                                                        })
                                                    } else {
                                                        let msg = ErrorService.extractError(res);
                                                        if (msg)
                                                        toast.error(msg, {
                                                            hideProgressBar: true,
                                                            autoClose: true,
                                                            draggable: true,
                                                        })
                                                    }

                                                }).finally(() => {
                                                    ApiService.toggleSpinner(false);
                                                });

                                            }}>{TranslationAgent.localizeString("save")}</div>

                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="admin-content-title">{TranslationAgent.localizeString("period_anagraphic_title")}</div>
                                {!this.state.isLoadingPeriods && periodReferences.length == 0 && (<div className="button-boxed-widjet" onClick={() => { window.location.href = ".#/add_reference_period?plantId=" + this.getPlantId(); }}>{TranslationAgent.localizeString("period_anagraphic_create")}</div>)}
                                {!this.state.isLoadingPeriods && periodReferences.length > 0 && (<div className="button-boxed-widjet" onClick={() => {



                                    let oldStart = lastItem.startDate.split("T")[0];
                                    let oldEnd = lastItem.endDate.split("T")[0];

                                    let endSpl = oldEnd.split("-");
                                    let newEnd = ((+endSpl[0]) + 1).toString() + "-" + endSpl[1] + "-" + endSpl[2];


                                    let startSpl = oldStart.split("-");
                                    let newStart = ((+startSpl[0]) + 1).toString() + "-" + startSpl[1] + "-" + startSpl[2];

                                    let name = lastItem.referencePeriodName;

                                    let nameSpl = name.split("_ext_");

                                    if (nameSpl.length == 1) {
                                        name = name + "_ext_0";
                                    } else {
                                        let incremental = nameSpl[1];
                                        incremental = +incremental;
                                        incremental = incremental + 1;
                                        name = nameSpl[0] + "_ext_" + incremental.toString();
                                    }

                                    ApiService.toggleSpinner(true);
                                    ApiService.createReferencePeriod({
                                        PlantId: +this.getPlantId(),
                                        Name: name,
                                        Start: newStart,
                                        End: newEnd,
                                        UserId: +this.getUserId()
                                    }).then((res) => {


                                        if (res.raw.status === 200) {
                                            toast.success(TranslationAgent.localizeString("reference_period_extended_success"), {
                                                hideProgressBar: true,
                                                autoClose: true,
                                                draggable: true,
                                            })
                                        } else {
                                            let msg = ErrorService.extractError(res);
                                    if (msg)
                                    toast.error(msg, {
                                                hideProgressBar: true,
                                                autoClose: true,
                                                draggable: true,
                                            })
                                        }

                                        ApiService.getReferencePeriods({ PlantId: this.getPlantId(), UserId: this.getUserId() }).
                                            then((res) => { this.setState({ referencePeriods: (res == null) ? [] : res.parsed, isLoadingPeriods: false }); }).finally(() => {
                                                ApiService.toggleSpinner(false);
                                            })
                                    })

                                }}>{TranslationAgent.localizeString("period_anagraphic_extend")}</div>)}
                                <ColoredTableComponent rows={periodReferences} headers={periodReferencesHeaders} isLoading={this.state.isLoadingPeriods} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="admin-content-title">{TranslationAgent.localizeString("formulas_anagraphic")}</div>
                                <div>
                                    {!this.state.isLoadingFormulas && (<div className="button-boxed-widjet" onClick={() => { window.location.href = ".#/add_new_formula?plantId=" + this.getPlantId() }}>{TranslationAgent.localizeString("plant_detail_formula_add")}</div>)}
                                    <ColoredTableComponent rows={furmulas} headers={furmulasHeaders} isLoading={this.state.isLoadingFormulas} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default PlantDetailView