import React, { Component } from 'react'
import SmallBoxedWidjetComponent from './SmallBoxedWidjetComponent'
import TranslationAgent from './../../translations/TranslationAgent'
import EncryptStorage from './../../storage/EncryptStorage'


import './../../assets/css/custom/widjets/DashboardTooltipResumeWidjetComponent.css'

class DashboardTooltipResumeWidjetComponent extends Component {
    constructor(props) {
        // eslint-disable-next-line
        super(props);
    }

    render() {        
        return (
            <div id="tooltipDashboard">
                <div  className={this.props.ytdPercent && this.props.ytdPercent != 0 ? "inrow-small-widgets-wrap" : "inrow-small-widgets-wrap-single" }>                            
                    <div className="inrow-small-widgets">
                        <SmallBoxedWidjetComponent
                            label={this.props.ytdValue}
                            title={TranslationAgent.localizeString("analisys_ytd_co2")}
                            underlabel={"t"}
                            largerSubtitle={TranslationAgent.localizeString("co2_savings")} />
                    </div>
                    {
                        this.props.ytdPercent && this.props.ytdPercent != 0 &&
                        <div className="inrow-small-widgets">
                            <SmallBoxedWidjetComponent
                                label={this.props.ytdPercent}
                                title={TranslationAgent.localizeString("consume_on_baseline_ytd")}
                                underlabel={TranslationAgent.localizeString("en_electric")}
                                underlabel={"%"}
                                // extracontent={""}
                                largerSubtitle={TranslationAgent.localizeString("en_electric")}
                            />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default DashboardTooltipResumeWidjetComponent