import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import IconService from '../services/IconService'
import TranslationAgent from '../translations/TranslationAgent'
import EncryptStorage from './../storage/EncryptStorage'

import '../assets/css/custom/SidebarComponent.css'

class SidebarChildrenComponent extends Component {
    constructor(props) {
    super(props);
        this.colorButtons = this.colorButtons.bind(this);
        this.isValidNav = this.isValidNav.bind(this);
  }
  
  colorButtons(index, name){
    
    let navElements = document.getElementsByClassName("nav-element");
    Array.prototype.forEach.call(navElements, function(item,i) {
      if(i == index){
        item.className = "nav-element boxed-nav-item-active";
      }else{
        item.className = "nav-element boxed-nav-item";
      }
    });

    let navElementsIcons = document.getElementsByClassName("navigation-image");
    Array.prototype.forEach.call(navElementsIcons, function(item,i) {
      let n = item.name;
      if(i == index){
          n += "_w";
      }else{
          n += "_r";
      }
      item.src = IconService[n];
    });
     window.location.href = "#/" + name;
  }

    isValidNav() {
        for (let i = 0; i < this.props.activeNavs.length; i++) {
            if (window.location.href.indexOf(this.props.activeNavs[i]) != -1) {
                return true;
            }
        }

        if (window.location.href.split("#")[1] == "/"+this.props.name) {
            return true;
        }

        return false;
    }

  render() {
      let rowStyle = {
        "margin": "0",
        "height": "2em"
      };

      let isValid = this.isValidNav();

      if (this.props.isStalling) {
          isValid = EncryptStorage.getItem("stalling-menu-" + this.props.index.toString()) == "true";
      } else {
          EncryptStorage.setItem("stalling-menu-" + this.props.index.toString(), isValid.toString());
      }

      let activeClass = isValid ? "nav-element boxed-nav-item-active" : "nav-element boxed-nav-item";
      let activeIco = isValid ? IconService[this.props.name + "_w"] : IconService[this.props.name + "_r"];


      return (
          <li>
              <div className={activeClass}
                    onMouseEnter={() => {
                        document.getElementsByClassName("navigation-image")[this.props.index].src = IconService[this.props.name + "_w"];
                    }}
                    onMouseLeave={() => {
                        document.getElementsByClassName("navigation-image")[this.props.index].src = activeIco;
                    }}
                  onClick={() => { this.colorButtons(this.props.index, this.props.name); }}>
                  <img className="navigation-image" name={this.props.name} src={activeIco} style={rowStyle}/>
            <p>{TranslationAgent.localizeString(this.props.name)}</p>
            </div>
        </li>
    )
  }
}

export default SidebarChildrenComponent