import React, { Component } from 'react'
import ApiService from '../services/ApiService'
import BackButtonComponent from '../components/BackButtonComponent'
import DatePickerComponent from '../components/DatePickerComponent'
import ErrorService from '../services/ErrorService'
import TranslationAgent from '../translations/TranslationAgent'
import { toast } from 'react-toastify';
import IconService from '../services/IconService'
import '../assets/css/custom/DataImportView.css'
import DropdownComponent from '../components/DropdownComponent'
import ImportZipResultComponent from '../components/ImportZipResultComponent'
import EncryptStorage from './../storage/EncryptStorage'

class DataImportView extends Component {
    constructor(props) {
        super(props);
        let type = EncryptStorage.getItem('admin-file-type');
        this.state = {
            "dateFrom": EncryptStorage.getItem("admin-date-from"),
            "dateTo": EncryptStorage.getItem("admin-date-to"),
            "documentType": type == null ? 2 : type,
            showImportZipResult: false,
            titleModalZipResult: "",
            importZipErrorFiles: [],
        }
        this.changedDateFrom = this.changedDateFrom.bind(this);
        this.changedDateTo = this.changedDateTo.bind(this);
        this.changeDocType = this.changeDocType.bind(this);
        this.acceptFile = this.acceptFile.bind(this);
        this.concludeImport = this.concludeImport.bind(this);
        this.concludeImportZip = this.concludeImportZip.bind(this);
        this.sendFile = this.sendFile.bind(this);
        this.sendFileZip = this.sendFileZip.bind(this);

        window.DataImportView = this;
    }

    sendFile() {
        var xhr = new XMLHttpRequest();
        xhr.open("POST", ApiService.getBaseUrl() + 'file/readExcelFile');
        xhr.setRequestHeader("Authorization", "Bearer " + JSON.parse(EncryptStorage.getItem('session-data'))?.B2CToken)
        xhr.onload = function (event) {
            window.DataImportView.concludeImport(event);
        };
        // or onerror, onabort
        var formData = new FormData(document.getElementById("uploadForm"));
        xhr.send(formData);
    }

    sendFileZip() {
        var xhr = new XMLHttpRequest();
        xhr.open("POST", ApiService.getBaseUrl() + 'file/readZipFile');
        xhr.setRequestHeader("Authorization", "Bearer " + JSON.parse(EncryptStorage.getItem('session-data'))?.B2CToken)
        xhr.onload = function (event) {
            window.DataImportView.concludeImportZip(event);
        };
        // or onerror, onabort
        var formData = new FormData(document.getElementById("uploadFormZip"));
        xhr.send(formData);
    }

    concludeImport(response) {
        setTimeout(() => {
            window.history.back();
        }, 500);
        ApiService.toggleSpinner(false);
        if (response.target.status === 200) {
            toast.success(TranslationAgent.localizeString("file_imported_success"), {
                hideProgressBar: true,
                autoClose: true,
                draggable: true,
            })
        } else {

            let msg = ErrorService.extractErrorFromForm(response);
            if (msg)
            toast.error(msg, {
                hideProgressBar: true,
                autoClose: true,
                draggable: true,
            })
        }
    }

    concludeImportZip(response) {
        ApiService.toggleSpinner(false);
        if (response.target.status === 200) {
            const res = JSON.parse(response.target.response);
            if (res.imported === 0) {
                this.setState({
                    showImportZipResult: true,
                    titleModalZipResult: TranslationAgent.localizeString("file_imported_no_file"),
                    importZipErrorFiles: res.errorFile
                })

                document.getElementById("ImportZipResultComponent").style.display = "block"

            }
            else if (res.error > 0) {
                const title = TranslationAgent.localizeString("file_imported_success_1") + res.imported.toString() +
                    TranslationAgent.localizeString("file_imported_success_2") + res.total.toString() +
                    TranslationAgent.localizeString("file_imported_success_3") + res.error
                this.setState({
                    showImportZipResult: true,
                    titleModalZipResult: title,
                    importZipErrorFiles: res.errorFile
                })
                
                document.getElementById("ImportZipResultComponent").style.display = "block"
            }
            else {
                toast.success(TranslationAgent.localizeString("file_imported_success_1") + res.imported.toString() +
                    TranslationAgent.localizeString("file_imported_success_2") + res.total.toString() +
                    TranslationAgent.localizeString("file_imported_success_3") + res.error, {
                    hideProgressBar: true,
                    autoClose: true,
                    draggable: true,
                })
            }


        } else {
            setTimeout(() => {
                window.history.back();
            }, 500);
            let msg = ErrorService.extractErrorFromForm(response);

            if (msg)
            toast.error(msg, {
                hideProgressBar: true,
                autoClose: true,
                draggable: true,
            })
        }

    }

    acceptFile() {
        let targ = document.getElementById("file-name-slot")
        let src = document.getElementById("file-input");
        let upbutton = document.getElementById("upload-button");

        if (targ != null && src != null && upbutton != null) {
            let name = src.files[0].name;
            targ.innerHTML = TranslationAgent.localizeString("chosen_file") + ": <strong>" + encodeURI(name)+ "</strong>";

            upbutton.style.display = "";
            setTimeout(() => {
                upbutton.style.opacity = "1";
            }, 100);
        }
    }

    acceptZipFile() {
        let targ = document.getElementById("file-zip-name-slot")
        let src = document.getElementById("file-zip-input");
        let upbutton = document.getElementById("upload-zip-button");

        if (targ != null && src != null && upbutton != null) {
            let name = src.files[0].name;
            targ.innerHTML = TranslationAgent.localizeString("chosen_file") + ": <strong>" + encodeURI(name) + "</strong>";

            upbutton.style.display = "";
            setTimeout(() => {
                upbutton.style.opacity = "1";
            }, 100);
        }
    }

    componentDidMount() {
        if (!EncryptStorage.getItem("admin-file-type"))
            EncryptStorage.setItem("admin-file-type", "2")
    }

    changedDateFrom(e) {
        EncryptStorage.setItem("admin-date-from", e);
        this.setState({ "dateFrom": e });
    }

    changedDateTo(e) {
        EncryptStorage.setItem("admin-date-to", e);
        this.setState({ "dateTo": e });
    }

    changeDocType(e) {
        EncryptStorage.setItem("admin-file-type", e.target.value);
        this.setState({ "documentType": e.target.value });
    }
    render() {

        let documentTypes = [
            {
                "text": TranslationAgent.localizeString("invoices"),
                "value": 2
            },
            {
                "text": TranslationAgent.localizeString("rates"),
                "value": 0
            },
            {
                "text": TranslationAgent.localizeString("armonized"),
                "value": 1
            }
        ];

        let errFiles = [];
        this.state.importZipErrorFiles?.forEach((item, i) => {
            errFiles.push([item]);
        });

        return (
            <>
                <div className="content mt-1">
                    <div className="container-fluid">
                        <div className="admin-content">

                            <div className="row">
                                <div className="col-md-9">
                                    <BackButtonComponent backurl={"#/importdata"} title={TranslationAgent.localizeString("import_data_title")} />
                                </div>
                                <div className="col-md-3" style={{ display: "grid", justifyContent: "right" }} >
                                <img className={"eon-logo-icon"} src={IconService.eon_logo} />
                            </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div>
                                        <div style={{ "display": "inline-block" }}>
                                            <DatePickerComponent mode={"month"} currentValue={EncryptStorage.getItem('admin-date-from')} title={TranslationAgent.localizeString("facture_from")} requestMaxDate={EncryptStorage.getItem('admin-date-to')} changeCallback={this.changedDateFrom} />
                                        </div>
                                        <div style={{ "display": "inline-block" }}>
                                            <DatePickerComponent mode={"month"} currentValue={EncryptStorage.getItem('admin-date-to')} title={TranslationAgent.localizeString("facture_to")} changeCallback={this.changedDateTo} />
                                        </div>
                                        <div style={{ "display": "inline-block" }}>
                                            <DropdownComponent title={TranslationAgent.localizeString("select_document_type")} items={documentTypes} currentValue={EncryptStorage.getItem('admin-file-type')} changeCallback={this.changeDocType} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <form id="uploadForm" encType='multipart/form-data'>
                                        <div style={{ "display": "none" }}>
                                            <input name="dateFrom" type="text" onChange={() => { }} value={EncryptStorage.getItem("admin-date-from")?EncryptStorage.getItem("admin-date-from"):""} />
                                            <input name="dateTo" type="text" onChange={() => { }} value={EncryptStorage.getItem("admin-date-to") ? EncryptStorage.getItem("admin-date-to"): ""} />
                                            <input name="documentType" type="text" onChange={() => { }} value={EncryptStorage.getItem("admin-file-type")?EncryptStorage.getItem("admin-file-type"):""} />
                                            <input type="hidden" name="userId" onChange={() => { }} value={JSON.parse(EncryptStorage.getItem('session-data'))?.userId} />
                                        </div>
                                        <input name="fileExcel" id="file-input" type="file" className="pick-file-button" onChange={this.acceptFile} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                        <button type="submit" style={{ "display": "none" }} id="upload_real_button">upload</button>
                                    </form>
                                    <div className="button-boxed-widjet" style={{ "marginBottom": "2em" }}>{TranslationAgent.localizeString("pick_a_file")}</div>
                                    <div className="pl-2"><h5>{TranslationAgent.localizeString("pick_a_file_invoice")}</h5></div>
                                    <div id="file-name-slot" style={{ "marginLeft": "1em" }} ></div>
                                    <div id="upload-button" className="button-boxed-widjet"
                                        style={{ "marginTop": "2em", "display": "none", "opacity": "0", "transition": "opacity 0.5s linear" }}
                                        onClick={() => {
                                            ApiService.toggleSpinner(true);
                                            //document.getElementById("upload_real_button").click();
                                            this.sendFile();
                                        }}>{TranslationAgent.localizeString("upload_file")}</div>
                                </div>
                                {this.state.documentType == 2 && (<div className="col-md-4">
                                    <form id="uploadFormZip" encType='multipart/form-data'>
                                        <div style={{ "display": "none" }}>
                                            <input type="hidden" name="userId" value={JSON.parse(EncryptStorage.getItem('session-data'))?.userId} />
                                        </div>
                                        <input name="fileZip" id="file-zip-input" type="file" className="pick-file-button" onChange={this.acceptZipFile} accept="zip,application/zip,application/x-zip,application/x-zip-compressed" />
                                        <button type="submit" style={{ "display": "none" }} id="upload_real-zip_button">upload</button>
                                    </form>

                                    <div className="button-boxed-widjet" style={{ "marginBottom": "2em" }}>{TranslationAgent.localizeString("load_zip_file")}</div>
                                    <div className="pl-2"><h5>{TranslationAgent.localizeString("load_zip_file_invoice")}</h5></div>
                                    <div id="file-zip-name-slot" style={{ "marginLeft": "1em" }} ></div>
                                    <div id="upload-zip-button" className="button-boxed-widjet"
                                        style={{ "marginTop": "2em", "display": "none", "opacity": "0", "transition": "opacity 0.5s linear" }}
                                        onClick={() => {
                                            ApiService.toggleSpinner(true);
                                            //document.getElementById("upload_real_button").click();
                                            this.sendFileZip()
                                        }}>{TranslationAgent.localizeString("upload_file")}</div>
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.showImportZipResult &&
                    <ImportZipResultComponent modalTitle={this.state.titleModalZipResult} errorFiles={errFiles}></ImportZipResultComponent>}
            </>
        )
    }
}

export default DataImportView