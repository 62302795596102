import React, { Component } from 'react'
import ApiService from '../services/ApiService'
import BackButtonComponent from '../components/BackButtonComponent'
import ColoredTableComponent from '../components/ColoredTableComponent'
import { toast } from 'react-toastify';
import TranslationAgent from '../translations/TranslationAgent'
import '../assets/css/custom/PlantDetailView.css'
import ErrorService from '../services/ErrorService'
import IconService from '../services/IconService'
import EncryptStorage from './../storage/EncryptStorage'

class AddPlantView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formulas: [],

        }

    }

    getCompanyId() {
        const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
        return urlParams.get('companyId');
    }

    getUserId() {
        return JSON.parse(EncryptStorage.getItem("session-data"))?.userId;
    }

    checkBeforeSave() {
        if (!document.getElementById("plantName")?.value) {
            toast.error(TranslationAgent.localizeString("plant_mandatory_name"), {
                hideProgressBar: true,
                autoClose: true,
                draggable: true,
            })
            return false;
        }
        if (!document.getElementById("plantCode")?.value) {
            toast.error(TranslationAgent.localizeString("plant_mandatory_code"), {
                hideProgressBar: true,
                autoClose: true,
                draggable: true,
            })
            return false;
        }

        return true;
    }

    componentDidMount() {

    }

    render() {

        let powerVectorsRows = [
            [TranslationAgent.localizeString("energetic_electric_power"), "", ""],
            [TranslationAgent.localizeString("energetic_termic_power"), "", ""],
            [TranslationAgent.localizeString("energetic_cooling_power"), "", ""],
            // [TranslationAgent.localizeString("energetic_hotWater_power"), "", ""],
        ];


        let ids = [
            { "idbool": "electricVector", "idsel": "electricNominal" },
            { "idbool": "termicVector", "idsel": "termicNominal" },
            { "idbool": "coolingVector", "idsel": "coolingNominal" },
            // { "idbool": "hotWaterVector", "idsel": "hotWaterNominal" }
        ]

        powerVectorsRows.forEach((item, i) => {
            let sel = "<select id=\"" + ids[i].idbool + "\"><option value=\"true\" >" + TranslationAgent.localizeString("yes") + "</option><option>" + TranslationAgent.localizeString("no") + "</option></select>"
            powerVectorsRows[i][1] = sel;
            powerVectorsRows[i][2] = "<input  id=\"" + ids[i].idsel + "\" type=\"number\" class=\"invisible-input-style\" placeholder=\"" + TranslationAgent.localizeString("energetic_vectors_capacity") + "\">";
        });

        let powerVectorsHeaders = [
            TranslationAgent.localizeString("energetic_vectors"),
            TranslationAgent.localizeString("energetic_vectors_available"),
            TranslationAgent.localizeString("energetic_vectors_capacity"),
        ];

        return (
            <div className="content mt-1">
                <div className="container-fluid">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-9">
                                <BackButtonComponent backurl={null} title={TranslationAgent.localizeString("add_plant_title")} />
                            </div>
                            <div className="col-md-3" style={{ display: "grid", justifyContent: "right" }} >
                                <img className={"eon-logo-icon"} src={IconService.eon_logo} />
                            </div>
                        </div>
                    </div>
                    <div className="admin-content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="admin-content-title">{TranslationAgent.localizeString("new_plant_info")}</div>
                                <div className="admin-detail-box">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_name")}</div>
                                            <div className="admin-detail-box-input-wrap">
                                                <input id="plantName" className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_name")} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_address")}</div>
                                            <div className="admin-detail-box-input-wrap">
                                                <input id="address" className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_address")} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_code")}</div>
                                            <div className="admin-detail-box-input-wrap">
                                                <input id="plantCode" className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_code")} type="text" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_emitunit")}</div>
                                            <div className="admin-detail-box-input-wrap">
                                                <input id="issuingUnit" className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_emitunit")} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_nametar")}</div>
                                            <div className="admin-detail-box-input-wrap">
                                                <input id="ratePlantName" className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_nametar")} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_namearm")}</div>
                                            <div className="admin-detail-box-input-wrap">
                                                <input id="armonizedPlantName" className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_namearm")} type="text" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_contr_decorr")}</div>
                                            <div className="admin-detail-box-input-wrap">
                                                <input id="effectiveDate" className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_contr_decorr")} type="date" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_contr_expire")}</div>
                                            <div className="admin-detail-box-input-wrap">
                                                <input id="endDate" className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_contr_expire")} type="date" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_tree_target")}</div>
                                            <div className="admin-detail-box-input-wrap">
                                                <input id="treeTarget" className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_tree_target")} type="text" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_show_widget_tariffa")}</div>
                                            <div className="admin-detail-box-input-wrap">
                                                <select id="showWidget" style={{ "height": "1.9em" }} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_show_widget_tariffa")} type="text">
                                                    <option value="true">{TranslationAgent.localizeString("yes")} </option>
                                                    <option value="false">{TranslationAgent.localizeString("no")} </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_cop")}</div>
                                            <div className="admin-detail-box-input-wrap">
                                                <input id="cop" className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_cop")} type="text" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* wip */}
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="admin-detail-box-title">{TranslationAgent.localizeString("min_gauge")}</div>
                                            <div className="admin-detail-box-input-wrap">
                                                <input id="minGauge" defaultValue={0} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("min_gauge")} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="admin-detail-box-title">{TranslationAgent.localizeString("max_gauge")}</div>
                                            <div className="admin-detail-box-input-wrap">
                                                <input id="maxGauge" defaultValue={100} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("max_gauge")} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="admin-detail-box-title">{TranslationAgent.localizeString("target_gauge")}</div>
                                            <div className="admin-detail-box-input-wrap">
                                                <input id="targetGauge" defaultValue={75} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("target_gauge")} type="text" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* end wip */}
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_lat")}</div>
                                            <div className="admin-detail-box-input-wrap">
                                                <input id="lat" className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_lat")} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_long")}</div>
                                            <div className="admin-detail-box-input-wrap">
                                                <input id="long" className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_long")} type="text" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="admin-content-title">{TranslationAgent.localizeString("energetic_vectors")}</div>
                                            <ColoredTableComponent rows={powerVectorsRows} headers={powerVectorsHeaders} isLoading={this.state.isLoadingPlant} />
                                        </div>
                                    </div>

                                    <div className="button-boxed-widjet" style={{ "marginTop": "2em" }} onClick={() => {

                                        if (!this.checkBeforeSave()) {
                                            return;
                                        }

                                        ApiService.toggleSpinner(true);
                                        ApiService.createNewPlant({
                                            PlantName: document.getElementById("plantName").value,
                                            RatePlantName: document.getElementById("ratePlantName").value,
                                            ArmonizedPlantName: document.getElementById("armonizedPlantName").value,
                                            IssuingUnit: document.getElementById("issuingUnit").value,
                                            PlantCode: document.getElementById("plantCode").value,
                                            Address: document.getElementById("address").value,
                                            Lat: +document.getElementById("lat").value,
                                            Long: +document.getElementById("long").value,
                                            ElectricVector: document.getElementById("electricVector").value == "true" ? "S" : "N",
                                            TermicVector: document.getElementById("termicVector").value == "true" ? "S" : "N",
                                            CoolingVector: document.getElementById("coolingVector").value == "true" ? "S" : "N",
                                            HotWaterVector: "N",
                                            ElectricNominal: +document.getElementById("electricNominal").value,
                                            TermicNominal: +document.getElementById("termicNominal").value,
                                            CoolingNominal: +document.getElementById("coolingNominal").value,
                                            HotWaterNominal: 0,
                                            EffectiveDate: document.getElementById("effectiveDate").value,
                                            EndDate: document.getElementById("endDate").value,
                                            TreeTarget: +document.getElementById("treeTarget").value,
                                            ShowWidget: document.getElementById("showWidget").value == "true" ? "S" : "N",
                                            CompanyId: +this.getCompanyId(),
                                            IdDisabled: "N",
                                            CreatedBy: +this.getUserId(),
                                            LastModifiedBy: +this.getUserId(),
                                            Cop: document.getElementById("cop").value,
                                            MinGauge: +document.getElementById("minGauge").value,
                                            MaxGauge: +document.getElementById("maxGauge").value,
                                            TargetGauge: +document.getElementById("targetGauge").value
                                        }).then((res) => {

                                            if (res.raw.status === 200) {
                                                setTimeout(() => {
                                                    window.history.back();
                                                }, 1000);

                                                toast.success(TranslationAgent.localizeString("plant_added_success"), {
                                                    hideProgressBar: true,
                                                    autoClose: true,
                                                    draggable: true,
                                                })
                                            } else {
                                                let msg = ErrorService.extractError(res);
                                                if (msg)
                                                    toast.error(msg, {
                                                        hideProgressBar: true,
                                                        autoClose: true,
                                                        draggable: true,
                                                    })
                                            }


                                        }).finally(() => {
                                            ApiService.toggleSpinner(false);
                                        })

                                    }}>{TranslationAgent.localizeString("save")}</div>

                                </div>
                                <div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default AddPlantView