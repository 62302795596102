import React, { Component } from 'react'
import LoadingSpinnerComponent from './../LoadingSpinnerComponent'
import ApiService from './../../services/ApiService'
import SmallBoxedWidjetComponent from './SmallBoxedWidjetComponent'
import TranslationAgent from './../../translations/TranslationAgent'
import {parseJwt} from './../../services/ApiService'
import './../../assets/css/custom/widjets/SavingsTotalWidjetComponent.css'
import EncryptStorage from './../../storage/EncryptStorage'

class SavingsTotalWidjetComponent extends Component {
    constructor(props) {
        // eslint-disable-next-line
        super(props);
        this.state = {
            isLoading: true,
            label: ""
        }
    }

    isVisibleAdmin() {

        const b2cToken = JSON.parse(EncryptStorage.getItem("session-data"))?.B2CToken;
        if (b2cToken) {
            const b2cToken = JSON.parse(EncryptStorage.getItem("session-data")).B2CToken;
            if (b2cToken) {
                const parsedToken = parseJwt(b2cToken);
                return parsedToken.idp != null;
            }

            return false;
        }
    }

    componentDidMount() {


        let plantId = EncryptStorage.getItem('requested-plant-location');
        let userId = JSON.parse(EncryptStorage.getItem("session-data"))?.userId
        let role = this.isVisibleAdmin() ? EncryptStorage.getItem("requested-show-mode") : "";
        if (plantId != null) {
            /* ApiCall to update status */
            ApiService.widgetApi({
                "name": "SavingsTotalWidjetComponent",
                "payload": { PlantId: (+plantId), UserId: userId, Role: role }
            }).then((res) => {
                if (this.props.onIsReady) { this.props.onIsReady(); }

                let value1 = "-";

                if (res != null) {
                    if (res.parsed != null) {
                        value1 = res.parsed.total;
                    }
                }

                this.setState({ label: (res == null) ? "" : Intl.NumberFormat(EncryptStorage.getItem("language"), { maximumFractionDigits: 1 }).format(parseFloat(value1)), isLoading: false });
            })
        }

    }

    render() {
        return (
            <div>
                {this.state.isLoading && (<LoadingSpinnerComponent />)}
                {!this.state.isLoading && (<SmallBoxedWidjetComponent
                    label={this.state.label != null ? this.state.label : "-"}
                    underlabel={"t"}
                    subtitle={TranslationAgent.localizeString("co2_savings_all_plants_start_forniture")} />
                )}
            </div>
        )
    }
}

export default SavingsTotalWidjetComponent