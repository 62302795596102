import React, { Component } from 'react'
import ApiService from '../services/ApiService'
import WidgetRenderComponent from '../components/WidgetRenderComponent'
import BackButtonComponent from '../components/BackButtonComponent'
import DropdownComponent from '../components/DropdownComponent'
import DatePickerComponent from '../components/DatePickerComponent'
import TranslationAgent from '../translations/TranslationAgent';
import IconService from '../services/IconService'
import EncryptStorage from './../storage/EncryptStorage'

class DashboardView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plants: []
        }

        this.changedPlant = this.changedPlant.bind(this);
        this.changedDateFrom = this.changedDateFrom.bind(this);
        this.changedDateTo = this.changedDateTo.bind(this);
    }

    changedDateFrom(e) {
        EncryptStorage.setItem('requested-max-billing-from', e);
        this.setState({});
    }

    changedDateTo(e) {
        EncryptStorage.setItem('requested-max-billing-to', e);
        this.setState({});
    }

    changedPlant(e) {
        EncryptStorage.setItem('requested-plant-location', e.target.value);
    }

    componentDidMount() {

        let companyId = JSON.parse(EncryptStorage.getItem("session-data"))?.isEon ? JSON.parse(EncryptStorage.getItem("requested-company-location")) : JSON.parse(EncryptStorage.getItem("session-data"))?.companyId;

        /* ApiCall to update status */
        ApiService.getRegisteredPlantsList({ CompanyId: companyId }).then((res) => { this.setState({ plants: (res == null) ? [] : res.parsed }); })
    }


    render() {
        const plants = [];
        this.state.plants.forEach((item, i) => {
            plants.push({ text: item.plantName, value: item.plantId });
        })

        let from = EncryptStorage.getItem('requested-max-billing-from');
        var data = new Date().getFullYear()

        if (from == null) {
            EncryptStorage.setItem('requested-max-billing-from', data.toString() + "-01-01");
        }

        let to = EncryptStorage.getItem('requested-max-billing-to');
        if (to == null) {
            EncryptStorage.setItem('requested-max-billing-to', (new Date().toISOString().split("T")[0]));
        }

        return (
            <div className="content mt-1">
                <div className="container-fluid">
                    <div style={{ "marginBottom": "-1em" }}>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-9">
                                    <BackButtonComponent backurl={"#/plant"} title={TranslationAgent.localizeString("factures_title")} />
                                </div>
                                <div className="col-md-3" style={{ display: "grid", justifyContent: "right" }} >
                                    <img className={"eon-logo-icon"} src={IconService.eon_logo} />
                                </div>
                            </div>
                            <div className="row ml-2">
                                <div style={{ "display": "inline-block" }}>
                                    <DatePickerComponent mode={"date"} requestMaxDate={EncryptStorage.getItem('requested-max-billing-to')} currentValue={EncryptStorage.getItem('requested-max-billing-from')} title={TranslationAgent.localizeString("facture_from")} changeCallback={this.changedDateFrom} />
                                </div>
                                <div style={{ "display": "inline-block" }}>
                                    <DatePickerComponent mode={"date"} currentValue={EncryptStorage.getItem('requested-max-billing-to')} title={TranslationAgent.localizeString("facture_to")} changeCallback={this.changedDateTo} />
                                </div>
                                <div style={{ "display": "inline-block" }}>
                                    <DropdownComponent title={TranslationAgent.localizeString("select_plant")} items={plants} currentValue={EncryptStorage.getItem('requested-plant-location')} changeCallback={this.changedPlant} />
                                </div>
                                <div style={{ "display": "inline-block" }}>
                                    <div className="button-boxed-widjet" style={{ "margin": "1.55em", height: "3em", padding: "0.7em" }} onClick={() => {
                                        window.App.reloadApp();
                                    }}>{TranslationAgent.localizeString("search")}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <WidgetRenderComponent title="invoices" />
                    </div>
                </div>
            </div >
        )
    }
}

export default DashboardView