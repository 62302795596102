import React, { Component, useState } from 'react'
import { renderToString } from 'react-dom/server'
import DatePicker from "react-datepicker";
import it from "date-fns/locale/it";
import en from "date-fns/locale/en-GB";
import "../assets/css/custom/DatePickerComponent.css";
import moment from 'moment';
import EncryptStorage from './../storage/EncryptStorage'

class DatePickerComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDate: this.props.currentValue == null || this.props.currentValue == undefined ? "" : this.props.currentValue,
        };
        this.setSelectedDate = this.setSelectedDate.bind(this);
    }

    setSelectedDate(selectedDate) {
        this.setState({ selectedDate: selectedDate });
    }

    render() {

        let minDate = "1990-01-01";
        let maxDate = new Date().toISOString().split("T")[0];

        // if (this.props.mode == "month") {
        //     minDate = "1990-01";
        //     let maxDateSpl = maxDate.split("-");
        //     maxDate = maxDateSpl[0] + "-" + maxDateSpl[1];
        // }

        if (this.props.requestMaxDate != undefined && this.props.requestMaxDate != null) {
            maxDate = this.props.requestMaxDate;
        }

        if (this.props.requestMinDate != undefined && this.props.requestMinDate != null) {
            minDate = this.props.requestMinDate;
        }

        let value = this.props.currentValue == null || this.props.currentValue == undefined ? "" : this.props.currentValue;

        var onChenageDate = (date) => {
            

            let dt = moment(date).format("YYYY-MM-DD");
            this.setSelectedDate(dt);

            if (this.props.mode === "month")
                this.props.changeCallback(dt.split('-')[0] + '-' + dt.split('-')[1]);
            else
                this.props.changeCallback(dt);
        }

        if (this.props.mode === "month") {
            return (
                <div className="form-group eon-select-wrap">
                    <label className="eon-select-title" htmlFor="date">{this.props.title}</label>
                    {/* <input type={this.props.mode} value={value} min={minDate} max={maxDate} className="form-control eon-select" id="date" onChange={this.props.changeCallback} /> */}
                    <div className="form-control no-border eon-select-padded">                                                
                        <DatePicker
                            locale={EncryptStorage.getItem('language') === 'it'? it : en}
                            dateFormat='MMM-y'
                            selected={this.state.selectedDate ? new Date(this.state.selectedDate) : ''}
                            minDate={new Date(minDate)}
                            maxDate={new Date(maxDate)}
                            onChange={(date) => (onChenageDate(date))}
                            showMonthYearPicker
                            showYearDropdown
                            dropdownMode="select"
                        />
                    </div>
                </div>
            )
        }
        else
            return (
                <div className="form-group eon-select-wrap">
                    <label className="eon-select-title" htmlFor="date">{this.props.title}</label>
                    {/* <input type={this.props.mode} value={value} min={minDate} max={maxDate} className="form-control eon-select" id="date" onChange={this.props.changeCallback} /> */}
                    <div className="form-control no-border eon-select-padded">
                        <DatePicker
                            locale={EncryptStorage.getItem('language') === 'it'? it : en}
                            dateFormat='dd/MM/yyyy'
                            selected={new Date(this.state.selectedDate)}
                            minDate={new Date(minDate)}
                            maxDate={new Date(maxDate)}
                            onChange={(date) => (onChenageDate(date))}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                        // withPortal
                        />
                    </div>
                </div>
            )
    }
}

export default DatePickerComponent