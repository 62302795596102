import React, { Component } from 'react'
import IconService from './../services/IconService'
import EncryptStorage from './../storage/EncryptStorage'

import './../assets/css/custom/BackButtonComponent.css'

class BackButtonComponent extends Component {
  render() {
    return (
        <div className="back-button-wrapper">
            <div><img className="back-button" src={IconService.back_button} onClick={() => {
                if (this.props.backurl == null) {
                    window.history.back();
                } else {
                    window.location.href = this.props.backurl;
                }
            }}/></div>
            <div style={this.props.title != null ? {} : {"color":"transparent"}} className="back-button-title pl-2">{this.props.title != null? this.props.title: "-"}</div>
        </div>
    )
  }
}
export default BackButtonComponent;
