import React, { Component } from 'react'
import IconService from '../services/IconService'
import '../assets/css/custom/NavbarComponent.css'

class NavbarComponent extends Component {

  render() {
    return (

      <div className="container-fluid">
        <div className="row mt-2">          
          <div className="col-6" style={{paddingLeft:"20px", marginTop: "2px"}}>
            <div className="open-navmenu-button" onClick={() => {

              document.body.onclick = (e) => {
                if (e.target.id == "sidebar-navemnu-element") {
                  return;
                }
                let el = document.getElementById("sidebar-navemnu-element");
                let inkdrop = document.getElementById("side-bar-inkdrop-menu");

                if (el != null) {
                  el.style.left = "";
                  inkdrop.classList.add("menu-inkdrop-closed");
                }
              }
              window.onresize = () => {
                let el = document.getElementById("sidebar-navemnu-element");
                let inkdrop = document.getElementById("side-bar-inkdrop-menu");

                if (el != null) {
                  el.style.left = "";
                  inkdrop.classList.add("menu-inkdrop-closed");

                }
              }

              setTimeout(() => {
                let el = document.getElementById("sidebar-navemnu-element");
                let inkdrop = document.getElementById("side-bar-inkdrop-menu");
                if (el != null) {
                  el.style.left = "-285px";
                  inkdrop.classList.remove("menu-inkdrop-closed");
                }
              }, 100);

            }}>
              <img src={IconService.menu_b} style={{ "height": "20px" }} />
            </div>
          </div>
          <div className="col-3">
          </div>

          <div className="col-3">
            <img className="eon-logo-navbar" style={{ width: "auto", height: "auto" }} src={IconService.eon_logo} />
          </div>
        </div>
      </div>
    )
  }
}

export default NavbarComponent