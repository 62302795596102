const MockApiService = {
    fakeApi
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

async function fakeApi(keyname) {

    function randomDate(start, end) {
        return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    }


    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('-');
    }

    let Pages =
    {
        "rates": [
            {
                "Type": "Container",
                "Title": null,
                "Content": [
                    {
                        "Type": "Widjet",
                        "Content": "DettaglioIndiciTariffeWidjetComponent",
                        "Space": 3
                    },
                    {
                        "Type": "Widjet",
                        "Content": "DettaglioFormuleWidjetComponent",
                        "Space": 3
                    },
                ]
            }
        ],
        "invoices": [
            {
                "Type": "Container",
                "Title": null,
                "Content": [
                    {
                        "Type": "Widjet",
                        "Content": "ListaFattureWidjetComponent",
                        "Space": 3
                    }
                ]
            }
        ],
        "home": [
            {
                "Type": "Container",
                "Title": null,
                "Alignment": "center",
                "Content": [
                    {
                        "Type": "TabContainer",
                        "Title": null,
                        "Content": [
                            {
                                "Type": "Widjet",
                                "TabTitle": { "it": "Mappa", "en": "Map" },
                                "TabIcon": "map_icon",
                                "Content": "MappaImpiantiWidjetComponent",
                                "Space": 2
                            },
                            {
                                "Type": "Widjet",
                                "TabTitle": { "it": "Lista", "en": "List" },
                                "TabIcon": "list_icon",
                                "Content": "TabellaImpiantiWidjetComponent",
                                "Space": 2
                            },
                        ]
                    },
                    {
                        "Type": "Container",
                        "Direction": "column",
                        "Title": null,
                        "Content": [
                            {
                                "Type": "Widjet",
                                "Content": "RecapSavingsWidjetComponent",
                                "Space": 1
                            },
                            {
                                "Type": "Widjet",
                                "Content": "RecapEconomicsWidjetComponent",
                                "Space": 1
                            },
                        ]
                    }
                ]
            }
        ],
        "plant": [
            {
                "Type": "Container",
                "Title": { "it": "Analisi CO₂", "en": "CO₂ Analysis"},
                "Separator": "Bottom",
                "Content": [
                    {
                        "Type": "Widjet",
                        "Content": "SavingsYTDWidjetComponent",
                        "Space": 1
                    },
                    {
                        "Type": "Widjet",
                        "Content": "SavingsTotalWidjetComponent",
                        "Space": 1
                    },
                    {
                        "Type": "Widjet",
                        "Content": "AlberiPiantatiWidjetComponent",
                        "Space": 1
                    },
                ]
            },
            {
                "Type": "Container",
                "Title": { "it": "Dati economici", "en": "Economics" },
                "Separator": "Bottom",
                "Content": [
                    {
                        "Type": "Widjet",
                        "Content": "RecapUltimeFattureWidjetComponent",
                        "Space": 1
                    },
                    {
                        "Type": "Widjet",
                        "Content": "TariffaMediaYTDWidjetComponent",
                        "Space": 1
                    },
                    {
                        "Type": "Widjet",
                        "Content": "SavingsEconomicoWidjetComponent",
                        "Space": 1
                    },
                ]
            },
            {
                "Type": "Container",
                "Alignment": "center",
                "Title": { "it": "Take or pay", "en": "Take or pay" },
                "Content": [
                    {
                        "Type": "Widjet",
                        "Content": "GraficoQMGWidjetComponent",
                        "Space": 2
                    },
                    {
                        "Type": "Widjet",
                        "Content": "PercentQMGWidjetComponent",
                        "Space": 1
                    },

                ]
            }
        ],
        "charts": [
            {
                "Type": "Container",
                "Alignment": "center",
                "Title": null,
                "Content": [
                    {
                        "Type": "Widjet",
                        "Content": "EnergiaElettricaQMGStagionaleWidjetComponent",
                        "Space": 2
                    },
                    {
                        "Type": "Widjet",
                        "Content": "EnergiaElettricaQMGPercentWidjetComponent",
                        "Space": 1
                    },
                ]
            },
            {
                "Type": "Container",
                "Alignment": "center",
                "Title": null,
                "Content": [
                    {
                        "Type": "Widjet",
                        "Content": "EnergiaTermicaQMGStagionaleWidjetComponent",
                        "Space": 2
                    },
                    {
                        "Type": "Widjet",
                        "Content": "EnergiaTermicaQMGPercentWidjetComponent",
                        "Space": 1
                    },
                ]
            },
            {
                "Type": "Container",
                "Alignment": "center",
                "Title": null,
                "Content": [
                    {
                        "Type": "Widjet",
                        "Content": "EnergiaFrigoriferaQMGStagionaleWidjetComponent",
                        "Space": 2
                    },
                    {
                        "Type": "Widjet",
                        "Content": "EnergiaFrigoriferaQMGPercentWidjetComponent",
                        "Space": 1
                    },
                ]
            }
        ]
    };

    if (keyname == "charts" || keyname == "plant" || keyname == "home" || keyname == "invoices" || keyname == "rates") {
        return Pages[keyname];
    }

    await delay(Math.floor(Math.random() * 1500));


    if (keyname == "formulas") {
        return [
            {
                "title": { "it": "formula 1 it", "en": "formula 1 en"},
                "subtitle": { "it": "subtiitle formula 1 it", "en": "subtitle formula 1 en" },
                "value": "A = E - 2 * C ^ 2",
                "order": 1
            },
            {
                "title": { "it": "formula 2 it", "en": "formula 2 en" },
                "subtitle": { "it": "subtiitle formula 2 it", "en": "subtitle formula 2 en" },
                "value": "B = E - 2 * C ^ 2",
                "order": 2
            },
            {
                "title": { "it": "formula 3 it", "en": "formula 3 en" },
                "subtitle": { "it": "subtiitle formula 3 it", "en": "subtitle formula 3 en" },
                "value": "C = E - 2 * C ^ 2",
                "order": 3
            },
        ];
    }


    if (keyname == "plant_anagraphics") {

        let mockusr = {
            "plantname": "Milano",
            "plantaddress": "Via xxx xxxxxxxx",
            "plantcode": "1234",
            "startfurnish": formatDate(randomDate(new Date(2012, 0, 1), new Date())),
        };
        let list = [];
        for (let i = 0; i < 50; i++) {
            list.push(mockusr);
        }

        return list;
    }

    if (keyname == "reference_periods") {
        let list = [];
        for (let i = 0; i < 10; i++) {
            let mockusr = {
                "name": "furnish"+i.toString(),
                "furnish_start": formatDate(randomDate(new Date(2012, 0, 1), new Date())),
                "furnish_end": formatDate(randomDate(new Date(2012, 0, 1), new Date())),
            };
            list.push(mockusr);
        }

        return list;
    }

    if (keyname == "plant_anagraphic") {
        return {
            "name": "Milano SRL",
            "address": "xxx xxxx",
            "code": "1234",
            "emit_unit": "00921",
            "rates_name": "milan",
            "armonized_name": "milan",
            "furnish_start": formatDate(randomDate(new Date(2012, 0, 1), new Date())),
            "furnish_end": formatDate(randomDate(new Date(2012, 0, 1), new Date())),
            "tree_target": Math.floor(Math.random() * 1500),
            "show_widget": (Math.floor(Math.random() * 100) > 50 ? true : false),
            "vectors": [{ "enabled": true, "nominal": "123" }, { "enabled": true, "nominal": "123" }, { "enabled": false, "nominal": "123" }]
        }
    }

    
    if (keyname == "customer_anagraphic") {
        return {
            "name": "Milano SRL",
            "social": "xxx xxxx",
            "code": "1234",
            "piva": "12345678",
            "sede": "xxx yyy xxxxxx"
        }
    }

    if (keyname == "period_composition_detail") {
        let list = [];
        for (let i = 0; i < 3; i++) {
            list.push(Array.from({ length: 12 }, () => Math.floor(Math.random() * 200)));
        }

        return list;
    }

    if (keyname == "rates-indexes") {

        let names = ["Milano", "Cuneo", "Roma", "Bologna"];
        let plants = [];

        for (let j = 0; j < 4; j++) {
            let list = [];
            for (let i = 0; i < 10; i++) {
                let rate = {
                    name: "NameObk" + i.toString(),
                    rif: "Rif" + i.toString(),
                    values: Array.from({ length: 12 }, () => Math.floor(Math.random() * 10000))
                }
                list.push(rate);
            }

            let plant = {
                "rates": list,
                "name": names[j],
                "extra": Array.from({ length: 12 }, () => Math.floor(Math.random() * 100))
            };

            plants.push(plant);
        }

        return plants;
    }

    if (keyname == "reference_periods_times") {
        let list = [];
        for (let i = 0; i < 10; i++) {
            list.push(formatDate(randomDate(new Date(2012, 0, 1), new Date())) +" / " +formatDate(randomDate(new Date(2012, 0, 1), new Date())));
        }

        return list;
    }


    if (keyname == "emails_anagraphgics") {
        let list = [];
        for (let i = 0; i < 10; i++) {
            let mockusr = {
                "email": "email.aziendale."+i.toString()+"@azienda.com",
            };
            list.push(mockusr);
        }

        return list;
    }

    if (keyname == "user_anagraphics") {
        let mockusr = {
            "name": "Nome Azienda",
            "socialreason": "Azienda S.p.A.",
            "codicesap": "1234",
            "piva": "123456789",
            "registeredoffice": "xxx yyy zzzzz"
        };
        let list = [];
        for (let i = 0; i < 50; i++) {
            list.push(mockusr);
        }

        return list;
    }

    if (keyname == "import_data_history") {
        let list = [];
        for (let i = 0; i < 50; i++) {
            let mockusr = {
                "name": "Doc1.xlsx",
                "items": Math.floor(Math.random() * 1500),
                "date": formatDate(randomDate(new Date(2012, 0, 1), new Date())),
                "status": "",
            };
            let s;
            if (Math.floor(Math.random() * 100) > 50)
            {
                s = "Ok";
            } 
            else
            {
                s = "Error";
            }

            mockusr["status"] = s;
            list.push(mockusr);
        }

        return list;
    }



    if (keyname == "gauge") {
        return Math.floor(Math.random() * 101);
    }

    if (keyname == "implant_dashboard_data") {
        return [
            {
                "name": "Milano",
                "position": [45.463619, 9.188120],
                "address": "Via XX Settembre, 12",
                "startFurnish": randomDate(new Date(2012, 0, 1), new Date()),
                "capacity": {
                    "electrical": Math.floor(Math.random() * 1500),
                    "steam": Math.floor(Math.random() * 1500),
                    "hotwater": Math.floor(Math.random() * 1500),
                },
                "ytd_value": Math.floor(Math.random() * 100),
                "ytd_percent": Math.floor(Math.random() * 101),
            },
            {
                "name": "Cuneo",
                "position": [44.494190, 7.547110],
                "address": "Via XX Settembre, 12",
                "startFurnish": randomDate(new Date(2012, 0, 1), new Date()),
                "capacity": {
                    "electrical": Math.floor(Math.random() * 1500),
                    "steam": Math.floor(Math.random() * 1500),
                    "hotwater": Math.floor(Math.random() * 1500),
                },
                "ytd_value": Math.floor(Math.random() * 100),
                "ytd_percent": Math.floor(Math.random() * 101),
            },
            {
                "name": "Bologna",
                "position": [44.494190, 11.346520],
                "address": "Via XX Settembre, 12",
                "startFurnish": randomDate(new Date(2012, 0, 1), new Date()),
                "capacity": {
                    "electrical": Math.floor(Math.random() * 1500),
                    "steam": Math.floor(Math.random() * 1500),
                    "hotwater": Math.floor(Math.random() * 1500),
                },
                "ytd_value": Math.floor(Math.random() * 100),
                "ytd_percent": Math.floor(Math.random() * 101),
            },
            {
                "name": "Roma",
                "position": [41.902782, 12.496366],
                "address": "Via XX Settembre, 12",
                "startFurnish": randomDate(new Date(2012, 0, 1), new Date()),
                "capacity": {
                    "electrical": Math.floor(Math.random() * 1500),
                    "steam": Math.floor(Math.random() * 1500),
                    "hotwater": Math.floor(Math.random() * 1500),
                },
                "ytd_value": Math.floor(Math.random() * 100),
                "ytd_percent": Math.floor(Math.random() * 101),
            },
        ]
    }

    if (keyname == "chart") {
        return {
            d1 : Array.from({ length: 12 }, () => Math.floor(Math.random() * 100)),
            d2 : Array.from({ length: 12 }, () => Math.floor(Math.random() * 100))
        }
    }

    if (keyname == "invoices_table") {
        let data = [];
        for (let i = 0; i < 20; i++) {
            data.push([randomDate(new Date(2012, 0, 1), new Date()), Math.floor(Math.random() * 1500), "Milano", Math.floor(Math.random() * 1500), randomDate(new Date(2012, 0, 1), new Date()), Math.floor(Math.random() * 100) > 50]);
        }
        return data;
    }

    if (keyname == "oriz_bar") {
        let value = Math.floor(Math.random() * 1500);

        let delta = Math.floor(Math.random() * value/2);

        if (Math.floor(Math.random() * 100) > 50) {
            delta = delta * -1;
        }

        let oldvalue = value + delta;

        return {
            d1: value,
            d2: oldvalue
        }
    }

    if (keyname == "bills") {
        return [
            {
                date: randomDate(new Date(2012, 0, 1), new Date()),
                paid: Math.floor(Math.random() * 100) > 50,
                price: Math.floor(Math.random() * 20000).toString()
            },
            {
                date: randomDate(new Date(2012, 0, 1), new Date()),
                paid: Math.floor(Math.random() * 100) > 50,
                price: Math.floor(Math.random() * 20000).toString()
            },
            {
                date: randomDate(new Date(2012, 0, 1), new Date()),
                paid: Math.floor(Math.random() * 100) > 50,
                price: Math.floor(Math.random() * 20000).toString()
            },
        ]
    }

    if (keyname == "qmg_chart") {
        return {
            d1: Array.from({ length: 3 }, () => Math.floor(Math.random() * 50)),
            d2: Array.from({ length: 3 }, () => Math.floor(Math.random() * 50)),
            d3: Array.from({ length: 3 }, () => Math.floor(Math.random() * 50))
        }
    }

    if (keyname == "boxed") {
        return Math.floor(Math.random() * 1500).toString();
    }

    if (keyname == "plants_list") {
        return ["Milano", "Cuneo", "Bologna", "Roma"];
    }

    return null;
}

export default MockApiService;