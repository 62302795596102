import React, { Component } from 'react'
import TranslationAgent from './../translations/TranslationAgent'
import EncryptStorage from './../storage/EncryptStorage'

import AlberiPiantatiWidjetComponent from './widjets/AlberiPiantatiWidjetComponent'
import ContactCustomerCareWidjetComponent from './widjets/ContactCustomerCareWidjetComponent'
import DettaglioFormuleWidjetComponent from './widjets/DettaglioFormuleWidjetComponent'
import DettaglioIndiciTariffeWidjetComponent from './widjets/DettaglioIndiciTariffeWidjetComponent'
import EnergiaElettricaQMGPercentWidjetComponent from './widjets/EnergiaElettricaQMGPercentWidjetComponent'
import EnergiaElettricaQMGStagionaleWidjetComponent from './widjets/EnergiaElettricaQMGStagionaleWidjetComponent'
import EnergiaFrigoriferaQMGPercentWidjetComponent from './widjets/EnergiaFrigoriferaQMGPercentWidjetComponent'
import EnergiaFrigoriferaQMGStagionaleWidjetComponent from './widjets/EnergiaFrigoriferaQMGStagionaleWidjetComponent'
import EnergiaTermicaQMGPercentWidjetComponent from './widjets/EnergiaTermicaQMGPercentWidjetComponent'
import EnergiaTermicaQMGStagionaleWidjetComponent from './widjets/EnergiaTermicaQMGStagionaleWidjetComponent'
import GraficoQMGWidjetComponent from './widjets/GraficoQMGWidjetComponent'
import ListaFattureWidjetComponent from './widjets/ListaFattureWidjetComponent'
import MappaImpiantiWidjetComponent from './widjets/MappaImpiantiWidjetComponent'
import PercentQMGWidjetComponent from './widjets/PercentQMGWidjetComponent'
import RecapEconomicsWidjetComponent from './widjets/RecapEconomicsWidjetComponent'
import RecapSavingsWidjetComponent from './widjets/RecapSavingsWidjetComponent'
import RecapUltimeFattureWidjetComponent from './widjets/RecapUltimeFattureWidjetComponent'
import SavingsEconomicoWidjetComponent from './widjets/SavingsEconomicoWidjetComponent'
import SavingsTotalWidjetComponent from './widjets/SavingsTotalWidjetComponent'
import SavingsYTDWidjetComponent from './widjets/SavingsYTDWidjetComponent'
import TabellaImpiantiWidjetComponent from './widjets/TabellaImpiantiWidjetComponent'
import TariffaMediaYTDWidjetComponent from './widjets/TariffaMediaYTDWidjetComponent'
import FakeWidjetComponent from './widjets/FakeWidjetComponent'

import './../assets/css/custom/LoadingSpinnerComponent.css'
import './../assets/css/custom/FactoryRenderComponent.css'
import IconService from '../services/IconService'



class FactoryRenderComponent extends Component {
    constructor(props) {
        // eslint-disable-next-line
        super(props);
        this.selectLanguagedAttribute = this.selectLanguagedAttribute.bind(this);
        this.translateAttribute = this.translateAttribute.bind(this);
        this.getItemSpace = this.getItemSpace.bind(this);
    }

    componentDidMount() {
            let elem = document.getElementById(this.props.title);
    }

    getItemSpace(item) {
        if (item.Type == "Widjet") {
            return item.Space;
        }

        if (item.Type == "Container") {
            let width = 0;

            let direction = item.Direction;
            if (direction == null || direction == undefined) {
                direction = "row";
            }

            if (direction == "row") {
                item.Content.forEach((item, i) => {
                    width += this.getItemSpace(item);
                });
            } else {
                item.Content.forEach((item, i) => {
                    let currentW = this.getItemSpace(item);
                    if (currentW > width) {
                        width = currentW;
                    }
                });
            }

            return width;
        }

        if (item.Type == "TabContainer") {
            let width = 0;
            item.Content.forEach((item, i) => {
                let currentW = this.getItemSpace(item);
                if (currentW > width) {
                    width = currentW;
                }
            });
            return width;
        }

        return 0;
    }

    selectLanguagedAttribute(attr) {
        let lang = EncryptStorage.getItem("language");
        if (lang == null) {
            lang = "en";
        }
        let res = attr[lang];
        if (res == undefined) {
            return lang + "(?)";
        } else {
            return res;
        }
    }

    translateAttribute(attr) {
        let lang = EncryptStorage.getItem("language");
        if (lang == null) {
            lang = "en";
        }

        if (attr[lang].indexOf("CO₂") == -1) {
            return <div>{attr[lang]}</div>;
        } else {
            let spl = attr[lang].split(" ");
            let labels = [];
            spl.forEach((item, i) => {
                let stl = { "marginRight": "0.5em" };
                if (item == "CO₂") {
                    stl["color"] = "var(--eon-blue)";
                }
                labels.push(<div key={i} style={stl}>{item}</div>);
            });
            return <div style={{ "display": "flex", "fontSize": "1.2em" }}>{labels}</div>;
        }
    }

    render() {
        let canBeSeen = EncryptStorage.getItem('can-see-economics') == "S";

        let WidjetComponent = <div onClick={(el) => { el.target.style.padding = "0"; el.target.style.border = "none"; el.target.style.maxHeight = "0"; }} className="unknown-widjet">{TranslationAgent.localizeString("unknown_widjet")}: {this.props.component.Type}:{this.props.component.Content}</div>;

        let currentElement = this.props.component;

        let componentStyles = {};

        if ("Separator" in currentElement) {
            componentStyles["border" + currentElement["Separator"]] = "1px solid rgba(0,0,0,0.2)";
        }

        if (currentElement.Type != "Widjet") {
            let pageContent = [];
            let tabContainerButtons = [];

            if (this.props.component.Type == "TabContainer") {

                let random_tab_id = '_' + Math.random().toString(36).substr(2, 9);

                let maxSpace = 0;
                this.props.component.Content.forEach((item, i) => {
                    let weighted_space = item.Space * 4;
                    if (weighted_space > maxSpace) {
                        maxSpace = weighted_space;
                    }
                });

                this.props.component.Content.forEach((item, i) => {

                    let tabbedIndexedId = random_tab_id + "_" + i.toString();

                    let weighted_space = maxSpace;

                    if (this.props.isInParent) {
                        weighted_space = 12;
                    }

                    let tabContainerStyle = {};
                    if (i == 0) {
                        tabContainerStyle["display"] = "block"
                    }

                    tabContainerButtons.push(<div onClick={(e) => {

                        let target_button = "button_" + e.target.getAttribute("name");
                        let target_widget = "widjet_" + e.target.getAttribute("name");

                        Array.prototype.forEach.call(document.getElementsByClassName("tabbed-button-for-container"), (item, i) => {
                            item.classList.remove("tabbed-button-for-container-selected");
                        });

                        let b = document.getElementById(target_button);
                        if (b != null) {
                            b.className += " tabbed-button-for-container-selected";
                        }

                        Array.prototype.forEach.call(document.getElementsByClassName("widjet-tabbed-container"), (item, i) => {
                            item.style.display = "none";
                        });
                        let w = document.getElementById(target_widget);
                        if (w != null) {
                            w.style.display = "block";
                        }


                    }} key={(i + 1) * 10} id={"button_" + tabbedIndexedId} name={tabbedIndexedId} className={i == 0 ? "tabbed-button-for-container tabbed-button-for-container-selected" : "tabbed-button-for-container"}>

                        <div name={tabbedIndexedId} className="tabbed-button-for-container-inner">
                            <img name={tabbedIndexedId} className="tabbed-icon-element" src={IconService[item.TabIcon]} />
                            <div name={tabbedIndexedId} className="tabbed-title-button-element">{this.selectLanguagedAttribute(item.TabTitle)}</div>
                        </div>
                    </div>);

                    pageContent.push(
                        <div id={"widjet_" + tabbedIndexedId} key={3 * (i + 1)} style={tabContainerStyle} className={"widjet-tabbed-container col-md-" + (weighted_space).toString()}>
                            <div key={(i + 1)} style={{
                                "width": "100%"
                            }}><FactoryRenderComponent onIsReady={this.props.onIsReady}  key={2 * (i + 1)} component={item} isTabbed={true} isInParent={true} /></div>
                        </div>);
                });


                let bootstrapGridBase = (<div className="row tabbed-row-container" style={{width: "100%"}}>{pageContent}</div>);

                WidjetComponent = (<div style={componentStyles}>
                    <div className="row" style={{width: "100%"}}>
                        <div className="col-8">
                            {/* <div id="dashboard-title-table" style={{ fontSize: "40px", fontWeight: "bold", lineHeight: "1em", marginBottom: "0.3em", marginTop: "0.2em" }}>{TranslationAgent.localizeString("dashboard_title")}</div> */}
                            <div id="dashboard-title-table" style={{ fontSize: "36px", fontWeight: "bold", lineHeight: "1em", marginBottom: "0.3em", marginTop: "0.2em" }}></div>
                        </div>
                        <div className="col-4" style={{ alignSelf: "flex-end", textAlign: "right" }}>
                            <div>{tabContainerButtons}</div>
                        </div>
                    </div>
                    {bootstrapGridBase}
                </div>);

            }

            if (this.props.component.Type == "Container") {

                let alignment = this.props.alignment;
                if (alignment == null || alignment == undefined) {
                    alignment = "flex-start";
                }

                let direction = this.props.component.Direction;
                if (direction == null || direction == undefined) {
                    direction = "row";
                }

                this.props.component.Content.forEach((item, i) => {

                    let weighted_space = this.getItemSpace(item) * 4;

                    if (this.props.isInParent) {
                        weighted_space = 12;
                    }

                    if (((item.Content === "SavingsEconomicoWidjetComponent" || item.Content === "RecapEconomicsWidjetComponent")) || (item.Content !== "SavingsEconomicoWidjetComponent" && item.Content !== "RecapEconomicsWidjetComponent")) {
                        pageContent.push(
                            <div id={item.Content.length === 2 ? item.Content.map((item) => { return item.Content }).join("-") : item.Content} key={3 * (i + 1)} className={"widjet-container col-md-" + (weighted_space).toString()}>
                                <div key={(i + 1)} style={{
                                    "width": "100%"
                                }}><FactoryRenderComponent onIsReady={this.props.onIsReady}  key={2 * (i + 1)} component={item} isInParent={true} /></div>
                            </div>);
                    }
                });

                let bootstrapGridBase = (<div id={this.props.title} className="row" style={{ "alignItems": alignment, "flexDirection": direction }}>{pageContent}</div>);
                let titleToCheck = TranslationAgent.localizeString("granted_minimal_quantities");

                WidjetComponent = (<div style={componentStyles}>
                    {this.props.component.Title != null && (<div className="widjet-container-title">{this.translateAttribute(this.props.component.Title)}</div>)}
                    {(this.props.component.Title != null && (this.props.component.Title.it === titleToCheck || this.props.component.Title.en === titleToCheck)) ? <div class="rebar-boxed-widget" style={{ marginTop: "1.3em", marginLeft: "0.5em" }}></div> : <></>}
                    <div>{bootstrapGridBase}</div></div>);
            }
        }
        else {
            let componentType = this.props.component.Content;

            if (componentType == "AlberiPiantatiWidjetComponent") {
                WidjetComponent = <AlberiPiantatiWidjetComponent onIsReady={this.props.onIsReady} />;
            }
            if (componentType == "ContactCustomerCareWidjetComponent") {
                WidjetComponent = <ContactCustomerCareWidjetComponent onIsReady={this.props.onIsReady} />;
            }
            if (componentType == "DettaglioFormuleWidjetComponent") {
                WidjetComponent = <DettaglioFormuleWidjetComponent onIsReady={this.props.onIsReady} />;
            }
            if (componentType == "DettaglioIndiciTariffeWidjetComponent") {
                WidjetComponent = <DettaglioIndiciTariffeWidjetComponent onIsReady={this.props.onIsReady} />;
            }
            if (componentType == "EnergiaElettricaQMGPercentWidjetComponent") {
                WidjetComponent = <EnergiaElettricaQMGPercentWidjetComponent onIsReady={this.props.onIsReady} />;
            }
            if (componentType == "EnergiaElettricaQMGStagionaleWidjetComponent") {
                WidjetComponent = <EnergiaElettricaQMGStagionaleWidjetComponent onIsReady={this.props.onIsReady} />;
            }
            if (componentType == "EnergiaFrigoriferaQMGPercentWidjetComponent") {
                WidjetComponent = <EnergiaFrigoriferaQMGPercentWidjetComponent onIsReady={this.props.onIsReady} />;
            }
            if (componentType == "EnergiaFrigoriferaQMGStagionaleWidjetComponent") {
                WidjetComponent = <EnergiaFrigoriferaQMGStagionaleWidjetComponent onIsReady={this.props.onIsReady} />;
            }
            if (componentType == "EnergiaTermicaQMGPercentWidjetComponent") {
                WidjetComponent = <EnergiaTermicaQMGPercentWidjetComponent onIsReady={this.props.onIsReady} />;
            }
            if (componentType == "EnergiaTermicaQMGStagionaleWidjetComponent") {
                WidjetComponent = <EnergiaTermicaQMGStagionaleWidjetComponent onIsReady={this.props.onIsReady} />;
            }
            if (componentType == "GraficoQMGWidjetComponent") {
                WidjetComponent = <GraficoQMGWidjetComponent onIsReady={this.props.onIsReady} />;
            }
            if (componentType == "ListaFattureWidjetComponent") {
                WidjetComponent = <ListaFattureWidjetComponent onIsReady={this.props.onIsReady} />;
            }
            if (componentType == "MappaImpiantiWidjetComponent") {
                WidjetComponent = <MappaImpiantiWidjetComponent onIsReady={this.props.onIsReady} />;
            }
            if (componentType == "PercentQMGWidjetComponent") {
                WidjetComponent = <PercentQMGWidjetComponent onIsReady={this.props.onIsReady} />;
            }
            if (componentType == "RecapEconomicsWidjetComponent") {
                if (canBeSeen)
                    WidjetComponent = <RecapEconomicsWidjetComponent onIsReady={this.props.onIsReady} />;
                else {
                    WidjetComponent = <FakeWidjetComponent onIsReady={this.props.onIsReady} />;
                }
                                }
            if (componentType == "RecapSavingsWidjetComponent") {
                WidjetComponent = <RecapSavingsWidjetComponent onIsReady={this.props.onIsReady} />;
            }
            if (componentType == "RecapUltimeFattureWidjetComponent") {
                WidjetComponent = <RecapUltimeFattureWidjetComponent onIsReady={this.props.onIsReady} />;
            }
            if (componentType == "SavingsEconomicoWidjetComponent") {
                if (canBeSeen)
                    WidjetComponent = <SavingsEconomicoWidjetComponent onIsReady={this.props.onIsReady} />;
                else {
                    WidjetComponent = <FakeWidjetComponent onIsReady={this.props.onIsReady} />;
                }
            }
            if (componentType == "SavingsTotalWidjetComponent") {
                WidjetComponent = <SavingsTotalWidjetComponent onIsReady={this.props.onIsReady} />;
            }
            if (componentType == "SavingsYTDWidjetComponent") {
                WidjetComponent = <SavingsYTDWidjetComponent onIsReady={this.props.onIsReady} />;
            }
            if (componentType == "TabellaImpiantiWidjetComponent") {
                WidjetComponent = <TabellaImpiantiWidjetComponent onIsReady={this.props.onIsReady} />;
            }
            if (componentType == "TariffaMediaYTDWidjetComponent") {
                WidjetComponent = <TariffaMediaYTDWidjetComponent onIsReady={this.props.onIsReady} />;
            }
        }


        let mainClassName = this.props.isTabbed ? "tabbed-widjet-zone" : "widjet-zone";
        return (
            <div>
                {
                    <div className={mainClassName}>
                        {WidjetComponent}
                    </div>
                }
            </div>
        )
    }
}
export default FactoryRenderComponent;
