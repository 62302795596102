
import TranslationAgent from './../translations/TranslationAgent'

const ErrorService = {
    extractError,
    extractErrorFromForm
};


function extractErrorFromForm(response) {
    let msg = "";

    const error = JSON.parse(response?.target?.responseText);

    if (error){
        msg = error?.value?.message;
    }
    else {
        msg = TranslationAgent.localizeString("something_went_wrong");
    }

    return msg;
}

function extractError(response) {

    let msg = null;
    let msgDefault = null;
    let msgProcessed = null;
    let messageComposed = "";

    if (response?.raw?.status == 400){        
        msg = TranslationAgent.localizeString("invalid_payload_format");
        return msg
    }

    if (msgDefault == null || msgDefault == "" || msgDefault == undefined) {
        msgDefault = response?.raw?.message;
    }

    if (response?.parsed && response.parsed?.value && response.parsed?.value?.message) {
        msgProcessed = response?.parsed?.value?.message;
    }    

    if (response?.parsed?.errors) {
        const keys = Object.keys(response?.parsed?.errors);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const errors = "";
            response?.parsed?.errors[key].forEach((item, i) => {
                errors += item + " ";
            });
            messageComposed += errors;
        }
    }

    if (msg == null || msg == "" || msg == undefined) {
        msg = messageComposed;
    }

    if (msg == null || msg == "" || msg == undefined) {
        msg = msgDefault;
    }

    if (msg == null || msg == "" || msg == undefined)  {
        msg = TranslationAgent.localizeString(msgProcessed);
    }

    if (msg == null || msg == "" || msg == undefined) {
        msg = TranslationAgent.localizeString("something_went_wrong");
    }


    if (!response)
        msg = "";
        
    if (response?.raw?.status == 401 || response?.raw?.status == 403){
        msg = "";
    }

    

    return msg;
}

export default ErrorService;