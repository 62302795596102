import React, { Component } from 'react'
import LoadingSpinnerComponent from './../LoadingSpinnerComponent'
import ApiService from './../../services/ApiService'
import { toast } from 'react-toastify';
import ErrorService from './../../services/ErrorService'
import IconService from './../../services/IconService'
import TranslationAgent from './../../translations/TranslationAgent'
import './../../assets/css/custom/widjets/ContactCustomerCareWidjetComponent.css'
import EncryptStorage from './../../storage/EncryptStorage'


class ContactCustomerCareWidjetComponent extends Component {
    constructor(props) {
        // eslint-disable-next-line
        super(props);
        this.state = {
            open: false,
            visible: true
        }

        window.customerCare = this;

        window.onhashchange = () => {
            window.customerCare.setState({ visible: false });
            setTimeout(() => {
                try {
                    window.customerCare.setState({ visible: true });
                } catch (e) {

                }
            }, 1);
        };
    }

    render() {

        return (
            <> {this.state.visible &&
                <div id="main-care-item"
                    onMouseLeave={() => {
                        let b = document.getElementById("main-care-item");
                        if (b != null) {
                            b.style.opacity = "1";
                        }
                    }}

                    onMouseEnter={() => {
                        let b = document.getElementById("main-care-item");
                        if (b != null) {
                            b.style.opacity = "1";
                        }
                    }}

                    className={ "" + (this.state.open ? "main-care-wrap main-care-wrap-open" : "main-care-wrap")}>
                    {this.state.open && (
                        <div>
                            <div className="main-care-title">
                                <span>{TranslationAgent.localizeString('care_title')}</span>
                                <span className="main-care-close-button" onClick={() => {
                                    this.setState({ open: false });
                                    let b = document.getElementById("main-care-item");
                                    if (b != null) {
                                        b.style.opacity = "1";
                                    }
                                }}>X</span>
                            </div>
                            <div className="main-care-inner">
                                <div className="main-care-fake-msg">{TranslationAgent.localizeString('care_msg')}</div>
                                <textarea id="textarea_send_msg" maxLength="500" placeholder={TranslationAgent.localizeString('care_plchld')} className="main-care-txtarea"></textarea>
                                <div className="main-care-button button-boxed-widjet" onClick={() => {

                                    let m = document.getElementById("textarea_send_msg");
                                    if (m == null) {
                                        return;
                                    }

                                    ApiService.toggleSpinner(true);
                                    ApiService.getCustomerCare({
                                        Message: m.value,
                                        UserId: JSON.parse(EncryptStorage.getItem("session-data"))?.userId
                                    }).then((res) => {

                                        if (res.raw.status === 200) {
                                            setTimeout(() => {
                                                this.setState({ open: false });
                                            }, 1000);

                                            let m = document.getElementById("textarea_send_msg");
                                            if (m != null) {
                                                m.innetHTML = "";
                                                m.innerText = "";
                                            }

                                            toast.success(TranslationAgent.localizeString("message_sent_success"), {
                                                hideProgressBar: true,
                                                autoClose: true,
                                                draggable: true,
                                            })
                                        } else {
                                            let msg = ErrorService.extractError(res);
                                            if (msg)
                                                toast.error(msg, {
                                                    hideProgressBar: true,
                                                    autoClose: true,
                                                    draggable: true,
                                                })
                                        }
                                    }).finally(() => {
                                        ApiService.toggleSpinner(false);
                                    })

                                }}>{TranslationAgent.localizeString('send')}</div>
                            </div>
                        </div>

                    )}
                    <img className="main-care-icon" src={IconService.chat_icon} onClick={() => { this.setState({ open: true }); }} />
                </div>
            }
            </>
        )
    }
}

export default ContactCustomerCareWidjetComponent