import React, { Component } from 'react'
import Chart from "react-apexcharts";
import ApiService from './../../services/ApiService'
import TranslationAgent from './../../translations/TranslationAgent'
import localesIt from 'apexcharts/dist/locales/it.json'
import localesEn from 'apexcharts/dist/locales/en.json'
import {parseJwt} from './../../services/ApiService'
import EncryptStorage from './../../storage/EncryptStorage'

import './../../assets/css/custom/widjets/GraficoQMGWidjetComponent.css'
class GraficoQMGWidjetComponent extends Component {
    constructor(props) {
        // eslint-disable-next-line
        super(props);
        this.state = {
            isLoading: true,
            data: [],
        }
    }

    isVisibleAdmin() {

        const b2cToken = JSON.parse(EncryptStorage.getItem("session-data"))?.B2CToken;
        if (b2cToken) {
            const b2cToken = JSON.parse(EncryptStorage.getItem("session-data")).B2CToken;
            if (b2cToken) {
                const parsedToken = parseJwt(b2cToken);
                return parsedToken.idp != null;
            }

            return false;
        }
    }

    componentDidMount() {

        let plantId = EncryptStorage.getItem('requested-plant-location');
        let userId = JSON.parse(EncryptStorage.getItem("session-data"))?.userId
        let role = this.isVisibleAdmin() ? EncryptStorage.getItem("requested-show-mode") : "";
        if (plantId != null) {
            /* ApiCall to update status */
            ApiService.widgetApi({
                "name": "GraficoQMGWidjetComponent",
                "payload": { Role: role, PlantId: (+plantId), UserId: userId }
            }).then((res) => {
                if (this.props.onIsReady) { this.props.onIsReady(); }
                this.setState({ data: (res == null) ? [] : res.parsed, isLoading: false });
            })
        }
    }

    render() {

        let annual = [];
        let baseline = [];
        let consumes = [];
        let enType = []

        if (this.state.data != null) {
            this.state.data.forEach((item, i) => {
                if ((item.annual || item.baseline ||  item.effective)) {
                    annual[i] = (Math.floor(item.annual));
                    baseline[i] = (Math.floor(item.baseline));
                    consumes[i] = (Math.floor(item.effective));
                    enType[i] = item.energyType == "Electrical" ? "electric_energy" : item.energyType == "Thermal" ? "termic_energy" : "cooling_energy";
                }
                        
            });
        }


        const fromAPI = {
            labels: [],
            datasets: []
        }
        enType.forEach((value) => {
            fromAPI.labels.push(value);
        })
        
        if (annual[0] || annual[1] || annual[2]) {
            fromAPI.datasets.push({
                "legend": "granted_annual_consume",
                "values": annual
            })
        }

        if (baseline[0] || baseline[1] || baseline[2]) {
            fromAPI.datasets.push({
                "legend": "baseline",
                "values": baseline
            })
        }

        if (consumes[0] || consumes[1] || consumes[2]) {
            fromAPI.datasets.push({
                "legend": "consumes",
                "values": consumes
            })
        }

        let locales = EncryptStorage.getItem("language") == "it" ? [localesIt] : [localesEn]

        let typeEn = -1;
        let oldSeriesIdx = 0;

        let customLegendItems = [];
        let colors = [];
        // let grantColors = []

        enType.forEach((value, i) => {
            if (value === "electric_energy" && consumes[i]) {
                customLegendItems.push((window.tellActiveYear()) + " YTD " + TranslationAgent.localizeString("consumes"))
                colors.push('#E4E000')//("#EAE740")
                // grantColors.push("#F2F088")
            }
            if (value === "electric_energy" && baseline[i] ) {
                customLegendItems.push((window.tellActiveYear()) + " YTD " + TranslationAgent.localizeString("baseline"))
                colors.push('#fffd94') //("#F2F088")
                // colors.push("#D3D3D3")
            }
            if (value === "termic_energy" && consumes[i]) {
                customLegendItems.push((window.tellActiveYear()) + " YTD " + TranslationAgent.localizeString("consumes"))
                colors.push("#C44341")
                // grantColors.push("#D16C6A")
            }
            if (value === "termic_energy" && baseline[i]) {
                customLegendItems.push((window.tellActiveYear()) + " YTD " + TranslationAgent.localizeString("baseline"))
                colors.push("#D16C6A")
                // colors.push("#D3D3D3")
            }
            if (value === "cooling_energy" && consumes[i]) {
                customLegendItems.push((window.tellActiveYear()) + " YTD " + TranslationAgent.localizeString("consumes"))
                colors.push("#2ba0ad")//("#54B5C0")
                // grantColors.push("#78C4CD")
            }
            if (value === "cooling_energy" && baseline[i]) {
                customLegendItems.push((window.tellActiveYear()) + " YTD " + TranslationAgent.localizeString("baseline"))
                colors.push("#90d8e0")//("#78C4CD")
                // colors.push("#D3D3D3")
            }
        })


        if (annual[0] || annual[1] || annual[2]) {
            customLegendItems.push(TranslationAgent.localizeString("granted_annual_consume"))
            colors.push("#D3D3D3")
        }

        return (

            <div>
                <Chart type="bar" width={"90%"} height={250} series={[{
                    name: TranslationAgent.localizeString("consumes"),
                    data: consumes.map((item, i) => {
                        return {
                            x: TranslationAgent.localizeString("consumes"),
                            y: item,
                            goals: annual[i] ? [{
                                name: TranslationAgent.localizeString("granted_annual_consume"),
                                value: annual[i],
                                strokeWidth: 5,
                                strokeColor: '#D3D3D3', 
                                //strokeColor: grantColors[i],
                            }]: []
                        }
                    }),
                },
                {
                    name: TranslationAgent.localizeString("baseline"),
                    data: baseline.map((item) => {
                        return {
                            x: TranslationAgent.localizeString("baseline"),
                            y: item,
                        }

                    }),
                },
                ]}
                    options={
                        {
                            responsive: [{
                                breakpoint: 1025,
                                options: {
                                    legend: {
                                        position: "top",
                                        show: true,
                                        customLegendItems: customLegendItems
                                    },
                                    chart: {
                                        locales: locales,
                                        stacked: false,
                                        toolbar: {
                                            show: false
                                        },
                                        defaultLocale: EncryptStorage.getItem("language"),
                                    },
                                    tooltip: {
                                        fixed: {
                                            enabled: true,
                                            position: 'bottomRight',
                                        },
                                    }
                                }
                            }],
                            legend: {
                                position: "right",
                                show: true,
                                customLegendItems: customLegendItems,
                                fontSize: "10px",
                            },
                            plotOptions: {
                                bar: {
                                    horizontal: false,
                                    borderRadius: 8,
                                    columnWidth: 70,
                                }
                            },
                            chart: {
                                locales: locales,
                                stacked: false,
                                toolbar: {
                                    show: false
                                },
                                defaultLocale: EncryptStorage.getItem("language"),
                            },
                            colors: colors,
                            xaxis: {
                                type: 'category',
                                categories: fromAPI.labels.map((item) => {
                                    return TranslationAgent.localizeString(item)
                                }),
                            },
                            dataLabels: {
                                enabled: false,
                                style: {
                                    colors: ["#000000", "#000000", "#000000"],
                                    fontWeight: "normal"
                                },
                                formatter: function (value) {
                                    return Intl.NumberFormat(EncryptStorage.getItem("language"), { maximumFractionDigits: 2 }).format(value);
                                }

                            },
                            yaxis: {
                                labels: {
                                    formatter: function (value) {
                                        return Intl.NumberFormat(EncryptStorage.getItem("language"), { maximumFractionDigits: 2 }).format(value);
                                    },
                                },
                                showAlways: true,
                                title: {
                                    text: "MWh",       
                                    style: {
                                        fontWeight: "normal",
                                        fontSize: "13px",
                                        cssClass:"spacing-y-axis"
                                    }                       
                                }
                            },
                            fill: {
                                colors: [function ({ value, seriesIndex, w }) {
                                    if (oldSeriesIdx === seriesIndex) {
                                        typeEn = typeEn + 1;
                                    }
                                    else {
                                        typeEn = 0;
                                        oldSeriesIdx = seriesIndex;
                                    }
                                    if (enType[typeEn] == "electric_energy" && seriesIndex == 0) {
                                        return "#E4E000"//"#EAE740"
                                    } else if (enType[typeEn] == "electric_energy" && seriesIndex == 1) {
                                        return '#F2F191'//'#F2F088'
                                        // return "#D3D3D3"
                                    }
                                    else if (enType[typeEn] == "termic_energy" && seriesIndex == 0) {
                                        return "#C44341"
                                    } else if (enType[typeEn] == "termic_energy" && seriesIndex == 1) {
                                        return '#D16C6A'
                                        // return "#D3D3D3"
                                    }
                                    else if (enType[typeEn] == "cooling_energy" && seriesIndex == 0) {
                                        return "#2ba0ad"//"#54B5C0"
                                    } else if (enType[typeEn] == "cooling_energy" && seriesIndex == 1) {
                                        return "#90d8e0"//'#78C4CD'
                                        // return "#D3D3D3"
                                    }
                                    else {
                                        return '#D3D3D3'
                                    }
                                }]
                            }
                        }
                    }></Chart>

                {/* <BarChartComponent title={null} coloredTitle={null} isLoading={this.state.isLoading} data={plottableData} /> */}
                <div className="button-boxed-widjet-wrapper" style={{display:"flex", justifyContent:"flex-end"}}>
                    <div className="button-boxed-widjet" style={{minWidth:"20em"}} onClick={() => {
                        window.location.href = "#/charts";
                    }}>{TranslationAgent.localizeString("details")}</div>
                </div>
            </div>
        )
    }
}

export default GraficoQMGWidjetComponent