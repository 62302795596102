import React, { Component } from 'react'
import LoadingSpinnerComponent from './../LoadingSpinnerComponent'
import TranslationAgent from './../../translations/TranslationAgent'
import ApiService from './../../services/ApiService'
import moment from 'moment'
import DashboardTooltipResumeWidjetComponent from './DashboardTooltipResumeWidjetComponent'
import { parseJwt } from './../../services/ApiService'
import './../../assets/css/custom/widjets/TabellaImpiantiWidjetComponent.css'
import EncryptStorage from './../../storage/EncryptStorage'

class TabellaImpiantiWidjetComponent extends Component {
    constructor(props) {
        // eslint-disable-next-line
        super(props);
        this.state = {
            isLoading: true,
            data: []
        }
    }

    getUserId() {
        return JSON.parse(EncryptStorage.getItem("session-data"))?.userId;
    }

    getCompanyId() {
        return JSON.parse(EncryptStorage.getItem("session-data"))?.companyId;
    }

    isVisibleAdmin() {

        const b2cToken = JSON.parse(EncryptStorage.getItem("session-data"))?.B2CToken;
        if (b2cToken) {
            const b2cToken = JSON.parse(EncryptStorage.getItem("session-data")).B2CToken;
            if (b2cToken) {
                const parsedToken = parseJwt(b2cToken);
                return parsedToken.idp != null;
            }

            return false;
        }
    }

    componentDidMount() {
        // let plantId = EncryptStorage.getItem('requested-plant-location');
        let userId = JSON.parse(EncryptStorage.getItem("session-data"))?.userId;
        let companyId = JSON.parse(EncryptStorage.getItem("session-data"))?.isEon ? JSON.parse(EncryptStorage.getItem("requested-company-location")) : JSON.parse(EncryptStorage.getItem("session-data"))?.companyId;
        let role = this.isVisibleAdmin() ? EncryptStorage.getItem("requested-show-mode") : "";

        if (companyId != null) {
            /* ApiCall to update status */
            ApiService.widgetApi({
                "name": "TabellaImpiantiWidjetComponent",
                "payload": { UserId: userId, CompanyId: companyId, Role: role }
            }).then((res) => {
                if (this.props.onIsReady) { this.props.onIsReady(); }

                this.setState({ data: (res == null) ? "" : res.parsed, isLoading: false });
            })
        }
    }

    isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

    render() {



        let shreddarRule = { "padding": "0 1.5px" };
        let limitedShreddar = { "padding": "0 1.5px", "width":"0" };

        let tableRows = [];
        setTimeout(() => {

            if (document.getElementById("dashboard-title-table")) {
                if (document.getElementById("id-select-company")) {
                    let selected = document.getElementById("id-select-company");
                    if (selected.selectedIndex >= 0) {

                        let text = selected.selectedIndex >= 0 ? selected.options[selected.selectedIndex].text + " " : "";
                        /*if (this.state.data?.length > 1) {
                            text = text + TranslationAgent.localizeString("dashboard_plants")
                        }
                        else if (this.state.data?.length === 1) {
                            text = text + TranslationAgent.localizeString("dashboard_plant");
                        }*/
                        if (document.getElementById("dashboard-title-table"))
                            document.getElementById("dashboard-title-table").innerText = text;
                    }
                }
                else {
                    ApiService.getCustomerProfile({ CompanyId: this.getCompanyId(), UserId: this.getUserId() }).then((res) => {
                        if (document.getElementById("dashboard-title-table"))
                            document.getElementById("dashboard-title-table").innerText = res?.parsed?.companyName;
                    })
                }
            }
        }, 250);

        if (this.state.data?.length) {
            this.state.data?.forEach((item, i) => {

                let innerStyle = { "padding": "0.5em" };
                if (i % 2 == 1) {
                    innerStyle["background"] = "var(--eon-blue-light)";
                }

                tableRows.push(
                    <tr key={i}>
                        <td style={shreddarRule}>
                            <div style={innerStyle} className="tabella-widget-datacell-plant">
                                <div>
                                    <div className="tabella-widget-title-element" style={{ cursor: "pointer" }} onClick={() => {
                                        EncryptStorage.setItem("requested-plant-location", item.plantId)
                                        window.location.href = './#/plant';
                                    }}>{item.plantName}</div>
                                    <div className="tabella-widget-address-element">{item.address}</div>
                                </div>
                            </div>
                        </td>
                        <td style={shreddarRule}>
                            <div style={innerStyle} className="tabella-widget-datacell-date">
                                <div className="tabella-widget-date-element">{moment(item.contractEndDate).format("DD-MM-YYYY")}</div>
                            </div>
                        </td>
                        <td style={shreddarRule}>
                            <div className="tabella-widget-datacell-capacity">
                                <div style={innerStyle} className="tabella-widget-datacell-capacity">
                                    <div className="tabella-widget-capacity-wrap">
                                        {
                                            parseFloat(item.electricNominal) !== 0 &&
                                            <div className="tabella-widget-capacity-label">
                                                {TranslationAgent.localizeString("table_electrical")}
                                                <div className="pl-2">{new Intl.NumberFormat(EncryptStorage.getItem("language"), { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(item.electricNominal)} kW</div>
                                            </div>
                                        }
                                        {
                                            parseFloat(item.termicNominal) !== 0 &&
                                            <div className="tabella-widget-capacity-label">
                                                {TranslationAgent.localizeString("table_steam")}
                                                <div className="pl-2">{new Intl.NumberFormat(EncryptStorage.getItem("language"), { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(item.termicNominal)} kW</div>
                                            </div>
                                        }
                                        {
                                            parseFloat(item.coolingNominal) !== 0 &&
                                            <div className="tabella-widget-capacity-label">
                                                {TranslationAgent.localizeString("table_coldwater")}
                                                <div className="pl-2">{new Intl.NumberFormat(EncryptStorage.getItem("language"), { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(item.coolingNominal)} kW</div>
                                            </div>
                                        }
                                        {/* {
                                            parseFloat(item.hotWaterNominal) !== 0 &&
                                            <div className="tabella-widget-capacity-label">
                                                {TranslationAgent.localizeString("table_hotwater")}
                                                <div className="pl-2">{new Intl.NumberFormat(EncryptStorage.getItem("language"), {minimumFractionDigits: 0, maximumFractionDigits: 0}).format(item.hotWaterNominal)} kW</div>
                                            </div>
                                        } */}
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td style={limitedShreddar}>
                            <div className="tabella-widget-datacell">
                                <div style={innerStyle} className="tabella-widget-datacell-highlights">
                                    <DashboardTooltipResumeWidjetComponent ytdValue={Intl.NumberFormat(EncryptStorage.getItem("language"), { maximumFractionDigits: 1 }).format(parseFloat(item.co2Value))} ytdPercent={Intl.NumberFormat(EncryptStorage.getItem("language"), { maximumFractionDigits: 0 }).format(item.electricCYTDPercentage)} />
                                </div>
                            </div>
                        </td>
                    </tr>)
            });
        }

        return (
            <div id="tabellaImpianti">
                {this.state.isLoading && (<LoadingSpinnerComponent />)}
                {!this.state.isLoading && (
                    <div className="table-widget-outerwrap">
                        <table className="table-widget-element">
                            <tbody>
                                <tr>
                                    <th style={shreddarRule}><div className="table-widget-header">{TranslationAgent.localizeString("table_implant")}</div></th>
                                    <th style={shreddarRule} className="date-header"><div className="table-widget-header">{TranslationAgent.localizeString("table_start_furnish")}</div></th>
                                    <th style={shreddarRule}><div className="table-widget-header">{TranslationAgent.localizeString("table_capacity")}</div></th>
                                    <th style={shreddarRule}><div className="table-widget-header">{TranslationAgent.localizeString("table_currentyear")}</div></th>
                                </tr>
                                {tableRows}
                            </tbody>
                        </table>
                    </div>)}
            </div>
        )
    }
}

export default TabellaImpiantiWidjetComponent