import React, { Component } from 'react'
import IconService from '../services/IconService'
import LoadingSpinnerComponent from './LoadingSpinnerComponent'
import EncryptStorage from './../storage/EncryptStorage'

import './../assets/css/custom/ColoredTableComponent.css'


class ColoredTableComponent extends Component {
    constructor(props) {
        // eslint-disable-next-line
        super(props);

        this.writeTableXmlOnStorage = this.writeTableXmlOnStorage.bind(this);
    }

    writeTableXmlOnStorage() {
        let list = EncryptStorage.getItem("on-page-tables");

        if (list == null) {
            list = "{}";
        }

        if (!this.props.isLoading) {
            let plist = JSON.parse(list);

            let headerCols = "";
            this.props.headers?.forEach((item, i) => {
                if (!this.props.excludeExportHeader || !this.props.excludeExportHeader?.find((itemEx) => {
                    return itemEx === item;
                })) {
                    let val = item != null ? item : "";
                    headerCols += "<Col>" + val + "</Col>";
                }
            });

            let headers = "<Element>" + headerCols + "</Element>";

            let allRows = "";
            this.props.rows?.forEach((row, i) => {
                let rowCols = "";
                row?.forEach((item, j) => {
                    let val = item != null ? item : "";

                    if (
                        val.toString().indexOf("<img") != -1 ||
                        val.toString().indexOf("<button") != -1 ||
                        val.toString().indexOf("<input") != -1 ||
                        val.toString().indexOf("<div") != -1 ||
                        val.toString().indexOf("<select") != -1) {
                        val = "";
                    }
                    if (!this.props.excludeExportHeader || !this.props.excludeExportHeader?.find((itemEx) => {
                        return itemEx === this.props.headers[j];
                    })) {
                        rowCols += "<Col>" + val + "</Col>";
                    }
                })
                allRows += "<Element>" + rowCols + "</Element>";

            });


            let tableName = this.props.headers[0]?.replace(/ /g, '_');
            if (this.props.tableName != null && this.props.tableName != undefined && this.props.tableName != "") {
                tableName = this.props.tableName;
            }

            let xml = "<Elements>" + headers + allRows + "</Elements>";
            plist[tableName] = xml;
            EncryptStorage.setItem("on-page-tables", JSON.stringify(plist));
        }
    }


    isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

    render() {
        this.writeTableXmlOnStorage();

        let headers = [];
        let rows = [];
        let extraRows = [];
        let skipIndexes = [];

        if (this.props.headers != null && this.props.headers != undefined) {
            this.props.headers?.forEach((item, i) => {
                if (item != null && (!this.props.hiddenHeaders || this.props.hiddenHeaders?.find((h) => {
                    return h !== i
                }))) {
                    let columnClass = "colored-table-header-cell";
                    if (i == this.props.highLightColumn) {
                        columnClass = "colored-table-header-highlight-cell";
                    }
                    if (i > this.props.highLightColumn) {
                        columnClass = "colored-table-header-after-highlight-cell";
                    }

                    let innerStyle = {};

                    if (item?.toString() == "") {
                        item = "-";
                        innerStyle["color"] = "transparent";
                    }

                    headers.push(<th key={i} style={{ "padding": "0 0.2em" }}><div className={columnClass} style={innerStyle} dangerouslySetInnerHTML={{ __html: item }}></div></th>);
                } else {
                    skipIndexes.push(i);
                }
            });
        }

        if (this.props.rows != null && this.props.rows != undefined) {
            this.props.rows?.forEach((row, i) => {
                let rowContent = [];
                row?.forEach((item, j) => {
                    if (skipIndexes.indexOf(j) == -1) {
                        let columnClass = "colored-table-normal-cell";

                        if (item?.toString().indexOf("<button") != -1) {
                            columnClass = "colored-table-button-cell ";
                        }

                        if (j == this.props.highLightColumn) {
                            columnClass = "colored-table-highlight-cell";
                        }
                        if (j > this.props.highLightColumn) {
                            columnClass = "colored-table-after-highlight-cell";
                        }

                        let innerStyle = {};
                        if (i % 2 == 1) {
                            innerStyle["background"] = "var(--eon-blue-light)";
                        }



                        if (item?.toString() == "" || j > this.props.highLightColumn) {
                            item = "-";
                            innerStyle["color"] = "transparent";

                        }

                        if (this.isNumber(item) && (!item.toString().startsWith("00"))) {
                            item = new Intl.NumberFormat(EncryptStorage.getItem("language")).format(item).toString();
                        }
                        if (this.props.tableName && this.props.tableName === "DettaglioIndiciTariffe") {
                            if (j === 0 || j === 1) {
                                innerStyle["justifyContent"] = "left";
                                innerStyle["width"] = j === 1 ? "100%" : "100%"
                            }
                            else {
                                innerStyle["justifyContent"] = "center";
                                innerStyle["width"] = "100%" 
                            }
                        }
                        rowContent.push(<td key={i.toString() + "_" + j.toString()} style={{ "padding": "0 0.2em" }}><div className={columnClass} style={innerStyle} dangerouslySetInnerHTML={{ __html: item }}></div></td>);

                    }
                })
                rows.push(<tr key={i}>{rowContent}</tr>);
            });
        }

        if (this.props.extraRows) {
            this.props.extraRows?.forEach((row, i) => {
                let rowContent = [];
                row.forEach((item, j) => {

                    if (skipIndexes.indexOf(j) == -1) {
                        let innerStyle = {};
                        if (i % 2 == 1) {
                            innerStyle["background"] = "var(--eon-blue-light)";
                        }


                        if (item?.toString() == "" || item == null) {
                            item = "-";
                            innerStyle["color"] = "transparent";
                        }


                        rowContent.push(<td key={"_" + i.toString() + "_" + j.toString()} style={{ "padding": "0 0.2em" }}><div style={innerStyle} className="colored-table-normal-cell" dangerouslySetInnerHTML={{ __html: item }}></div></td>);

                    }
                });

                extraRows.push(<tr key={"_" + i.toString()}>{rowContent}</tr>);
            });
        }

        let tableStyle = { "maxHeight": "70vh", "overflow": "auto" };

        if (this.props.fixHeight != undefined && this.props.fixHeight != null) {
            tableStyle["maxHeight"] = this.props.fixHeight;
        }

        return (
            <div>
                {this.props.isLoading && (<LoadingSpinnerComponent />)}
                {!this.props.isLoading && (
                    <div style={tableStyle} className="tableFixHead">
                        <table style={{ "width": "100%", "borderBottom": "1px solid var(--eon-dark-red)" }}>
                            <thead>
                                <tr>{headers}</tr>
                            </thead>
                            <tbody>
                                {rows}
                                {extraRows}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        )
    }
}

export default ColoredTableComponent