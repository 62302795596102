import React, { Component } from 'react'
import './../assets/css/custom/LoadingSpinnerComponent.css'
import EncryptStorage from './../storage/EncryptStorage'

class LoadingSpinnerComponent extends Component {
  render() {
    return (
        <div className="spinner-wrapper">
            <div className="spinner-item"></div>
        </div>
    )
  }
}
export default LoadingSpinnerComponent;
