import React, { Component } from 'react'
import TranslationAgent from '../translations/TranslationAgent'
import SidebarChildrenComponent from './SidebarChildrenComponent'
import IconService from '../services/IconService'
import '../assets/css/custom/SidebarComponent.css'
import {parseJwt} from './../services/ApiService'
import ApiService from './../services/ApiService'
import EncryptStorage from './../storage/EncryptStorage'


class SidebarComponent extends Component {
    constructor(props) {
        super(props);

        this.zoomPage = this.zoomPage.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
    }

    zoomPage(level) {
        let current = getComputedStyle(document.documentElement)
            .getPropertyValue('--eon-base-zoom');
        let asInt = +(current.replace("rem", ""));

        asInt = asInt + level;

        if (asInt < 0.6) {
            asInt = 0.6;
        }
        if (asInt > 1.2) {
            asInt = 1.2;
        }

        let z = asInt.toString() + "rem";
        document.documentElement.style.setProperty('--eon-base-zoom', z);

        EncryptStorage.setItem("preferred-zoom", z);
    }

    isVisibleAdmin() {
        const b2cToken = JSON.parse(EncryptStorage.getItem("session-data"))?.B2CToken;
        if (b2cToken) {
            const b2cToken = JSON.parse(EncryptStorage.getItem("session-data")).B2CToken;
            if (b2cToken) {
                const parsedToken = parseJwt(b2cToken);
                return parsedToken.idp != null;
            }

            return false;
        }
    }

    base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }


    saveByteArray(reportName, byte, type = "application/pdf") {
        var blob = new Blob([byte], { type: type });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = reportName;
        link.download = fileName;
        link.click();
    };

    downloadFile() {
        ApiService.toggleSpinner(true)
        ApiService.downloadInfoPrivacy({
            Language: EncryptStorage.getItem("language")
        }).then((res) => {
            var sampleArr = this.base64ToArrayBuffer(res.parsed?.value?.content);
            this.saveByteArray(res.parsed?.value?.fileName, sampleArr);
        }).finally(() => {
            ApiService.toggleSpinner(false);
        })
    }

    render() {

        let z = EncryptStorage.getItem("preferred-zoom");
        if (z != null) {
            document.documentElement.style.setProperty('--eon-base-zoom', z);
        }

        return (
            <div>
                <div className="sidebar sidebar-spacer" id="sidebar-navemnu-element" style={{ "background": "var(--eon-red)", "borderRight": "0.5em solid var(--eon-blue)", "zIndex": "2" }}>
                    <ul className="nav">
                        <SidebarChildrenComponent index={0} name={"home"} isStalling={window.location.href.indexOf("#/loading") != -1} activeNavs={[]} />
                        <SidebarChildrenComponent index={1} name={"plant"} isStalling={window.location.href.indexOf("#/loading") != -1} activeNavs={["#/charts", "#/invoices", "#/rates"]} />
                        {
                            ((!window.testIsSuperAdmin() && this.isVisibleAdmin())) &&
                            <SidebarChildrenComponent index={2} name={"admin"} isStalling={window.location.href.indexOf("#/loading") != -1} activeNavs={["#/customer_detail", "#/plant_detail", '#/add_customer', '#/edit_formula', '#/period_detail', '#/add_new_formula', '#/add_new_plant', '#/add_reference_period']} />
                        }
                        {
                            ((!window.testIsSuperAdmin() && this.isVisibleAdmin())) &&
                            <SidebarChildrenComponent index={3} name={"importdata"} isStalling={window.location.href.indexOf("#/loading") != -1} activeNavs={["#/importdata", "#/import_file", '#/publish_data']} />
                        }
                    </ul>
                    <div className="sidebar-footer" >
                        <div style={{ fontSize: "13px" }} onClick={() => {
                            EncryptStorage.removeItem("logged-in");
                            const token = JSON.parse(EncryptStorage.getItem("session-data"))?.B2CToken;
                            const parsedJwt = parseJwt(token);
                            window.location.href = process.env.REACT_APP_B2C_BASE_URL + parsedJwt.acr + "/oauth2/v2.0/logout?post_logout_redirect_uri=" + process.env.REACT_APP_ROOT_URL + "/#/login"
                            EncryptStorage.clear();
                        }}>
                            <img className="navbar-quit-icon" src={IconService.quit_w} />
                            <span style={{ color: 'white' }}>{TranslationAgent.localizeString("exit")}</span>
                        </div>
                    </div>
                    <div className="language-decorator-wrap">
                        <div className="language-decorator-slider">
                            <div style={EncryptStorage.getItem("language") == "it" ? {} : { "display": "flex", "flexDirection": "column-reverse" }}>
                                <div className="language-decorator-row" onClick={() => {
                                    EncryptStorage.setItem("language", "it");
                                    window.location.reload();
                                }}>
                                    <img className="language-decorator-image" src={TranslationAgent.getLanguageDecorators("it").icon} />
                                    <span className="language-decorator-description">{TranslationAgent.getLanguageDecorators("it").label}</span>
                                </div>
                                <div style={{ "height": "0.5em" }}></div>
                                <div className="language-decorator-row" onClick={() => {
                                    EncryptStorage.setItem("language", "en");
                                    window.location.reload();
                                }}>
                                    <img className="language-decorator-image" src={TranslationAgent.getLanguageDecorators("en").icon} />
                                    <span className="language-decorator-description">{TranslationAgent.getLanguageDecorators("en").label}</span>
                                </div>
                            </div>
                        </div>
                        <div className="language-decorator-separator"></div>

                        <span style={{cursor:"pointer"}} onClick={() => {
                            this.downloadFile()
                        }} className="language-decorator-description">{TranslationAgent.localizeString("privacy_info")}</span>
                        <span style={{cursor:"pointer"}} onClick={() => {
                            this.downloadFile()
                        }} className="language-decorator-description">{TranslationAgent.localizeString("usage_condition")}</span>

                        <div className="language-decorator-separator"></div>
                        <span className="language-decorator-description">{TranslationAgent.localizeString("powered_by_eon")}</span>
                    </div>
                </div>


                <div id="side-bar-inkdrop-menu" className="menu-inkdrop menu-inkdrop-closed"></div>
            </div>
        )
    }
}

export default SidebarComponent