import React, { Component } from 'react'
import LoadingSpinnerComponent from './../LoadingSpinnerComponent'
import ApiService from './../../services/ApiService'
import SmallBoxedWidjetComponent from './SmallBoxedWidjetComponent'
import TranslationAgent from './../../translations/TranslationAgent'
import {parseJwt} from './../../services/ApiService'
import './../../assets/css/custom/widjets/TariffaMediaYTDWidjetComponent.css'
import EncryptStorage from './../../storage/EncryptStorage'

class TariffaMediaYTDWidjetComponent extends Component {
    constructor(props) {
        // eslint-disable-next-line
        super(props);
        this.state = {
            isLoading: true,
            label: "",
            bench: 0,
            plantValue: 0
        }
    }

    isVisibleAdmin() {

        const b2cToken = JSON.parse(EncryptStorage.getItem("session-data"))?.B2CToken;
        if (b2cToken) {
            const b2cToken = JSON.parse(EncryptStorage.getItem("session-data")).B2CToken;
            if (b2cToken) {
                const parsedToken = parseJwt(b2cToken);
                return parsedToken.idp != null;
            }

            return false;
        }
    }
    componentDidMount() {
        let plantId = EncryptStorage.getItem('requested-plant-location');
        let userId = JSON.parse(EncryptStorage.getItem("session-data"))?.userId
        let role = this.isVisibleAdmin() ? EncryptStorage.getItem("requested-show-mode") : "";
        if (plantId != null) {
            /* ApiCall to update status */
            ApiService.widgetApi({
                "name": "TariffaMediaYTDWidjetComponent", payload: {
                    PlantId: +plantId,
                    UserId: +userId,
                    Role: role
                }
            }).then((res) => {
                if (this.props.onIsReady) { this.props.onIsReady(); }
                let label = "-";
                let bench = 0;
                let plantValue = 0;
                if (res != null) {
                    if (res.parsed != null) {
                        label = res.parsed.plantValue;
                        bench = res.parsed.benchmark;
                        plantValue = res.parsed.plantValue;
                    }
                }
                this.setState({ label: label, bench: bench, plantValue: plantValue, isLoading: false });
            })
        }
    }

    isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

    render() {
        const buttons = [
            { "buttonLabel": TranslationAgent.localizeString("rates_details"), "redirectUrl": "rates" },
        ];

        let useDeltaPercent = (this.state.plantValue ? this.state.plantValue : 0) -
            (this.state.bench ? this.state.bench : 0);

        if (useDeltaPercent > 100) {
            useDeltaPercent = 100;
        }
        if (useDeltaPercent < -100) {
            useDeltaPercent = -100;
        }

        let triangleMargin = (90 + useDeltaPercent).toString() + "px";

        let canBeSeen = EncryptStorage.getItem('can-see-benchmark-widget') == "true";

        return (
            <div>
                {this.state.isLoading && (<LoadingSpinnerComponent />)}
                {!this.state.isLoading && (
                    <div>
                        <SmallBoxedWidjetComponent
                            label={(new Intl.NumberFormat(EncryptStorage.getItem("language"), { maximumFractionDigits: 2 }).format(+this.state.label))}
                            underlabel={"€/MWhe"}
                            extracontent={
                                canBeSeen ? 
                                <div className="outer-bar-chart-comparison-wrap">
                                    <div className="delta-label p-1"><span className="">Δ</span> <span>{Intl.NumberFormat(EncryptStorage.getItem("language"), { maximumFractionDigits: 2 }).format((this.state.plantValue ? this.state.plantValue : 0) -
                                        (this.state.bench ? this.state.bench : 0))}%</span></div>
                                    <div className="bar-chart-comparison-wrap">
                                        <div className="bar-chart-comparison-triangle" style={{ "marginLeft": triangleMargin }}>▼</div>
                                        <div className="half-bar"></div>
                                        <div className="middle-element-bar">
                                            <div className="centered-value">{new Intl.NumberFormat(EncryptStorage.getItem("language")).format(this.state.bench)}</div>
                                        </div>
                                        <div className="half-bar">
                                        </div>
                                    </div>
                                </div> : <></>}
                            subtitle={canBeSeen ? TranslationAgent.localizeString("average_price") + " YTD " + (window.tellActiveYear()) + " " + TranslationAgent.localizeString("on_electric_vector_confront") : TranslationAgent.localizeString("average_price") + " YTD " + (window.tellActiveYear()) + " "}
                            color={"var(--eon-blue)"}
                            buttons={buttons}
                            maxHeightclass={"fixedHeight"}
                        />
                    </div>
                )}
            </div>

        )
    }
}

export default TariffaMediaYTDWidjetComponent