import React, { Component } from 'react'
import ApiService from '../services/ApiService'
import BackButtonComponent from '../components/BackButtonComponent'
import TranslationAgent from '../translations/TranslationAgent'
import { toast } from 'react-toastify';
import '../assets/css/custom/PlantDetailView.css'
import ErrorService from '../services/ErrorService'
import IconService from '../services/IconService'
import EncryptStorage from './../storage/EncryptStorage'

class AddFormulaView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formula: {
                ref: "",
                description: "",
                formula: "",
                formulaName: "",
                visualization: 0
            }
        }
    }

    onChangeProperty(propName, value) {
        this.setState(prevState => {
            let form = Object.assign({}, prevState.formula);
            form[propName] = value;
            return { formula: form };
        })
    }

    getPlantId() {
        const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
        return urlParams.get('plantId');
    }

    getUserId() {
        return JSON.parse(EncryptStorage.getItem("session-data"))?.userId;
    }

    saveNewFormula() {
        ApiService.toggleSpinner(true);
        ApiService.createNewFormula({
            PlantId: this.getPlantId(),
            Ref: this.state.formula.ref,
            FormulaName: this.state.formula.formulaName,
            Description: this.state.formula.description,
            Formula: this.state.formula.formula,
            Visualization: this.state.formula.visualization,
            CreatedBy: this.getUserId()
        }).then((res) => {

            if (res.raw.status === 200) {
                setTimeout(() => {
                    window.history.back();
                }, 1000);

                toast.success(TranslationAgent.localizeString("formula_added_success"), {
                    hideProgressBar: true,
                    autoClose: true,
                    draggable: true,
                })
            } else {
                let msg = ErrorService.extractError(res);
                if (msg)
                toast.error(msg, {
                    hideProgressBar: true,
                    autoClose: true,
                    draggable: true,
                })
            }
        }).finally(() => {
            ApiService.toggleSpinner(false);
        })
    }

    render() {

        return (
            <div className="content mt-1">
                <div className="container-fluid">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-9">
                                <BackButtonComponent backurl={null} title={TranslationAgent.localizeString("add_new_formula_title")} />
                            </div>
                            <div className="col-md-3" style={{ display: "grid", justifyContent: "right" }} >
                                <img className={"eon-logo-icon"} src={IconService.eon_logo} />
                            </div>
                        </div>
                    </div>
                    <div className="admin-content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="admin-content-title">{TranslationAgent.localizeString("formula_details")}</div>
                                <div className="admin-detail-box">
                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_detail_formula_ref")}</div>
                                    <div className="admin-detail-box-input-wrap">
                                        <input onChange={(e) => this.onChangeProperty("ref", e.target?.value)} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_detail_formula_ref")} type="text" />
                                    </div>
                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_detail_formula_name")}</div>
                                    <div className="admin-detail-box-input-wrap">
                                        <input onChange={(e) => this.onChangeProperty("formulaName", e.target?.value)} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_detail_formula_name")} type="text" />
                                    </div>
                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_detail_formula_description")}</div>
                                    <div className="admin-detail-box-input-wrap">
                                        <input onChange={(e) => this.onChangeProperty("description", e.target?.value)} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_detail_formula_description")} type="text" />
                                    </div>
                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_detail_formula_value")}</div>
                                    <div className="admin-detail-box-input-wrap">
                                        <input onChange={(e) => this.onChangeProperty("formula", e.target?.value)} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_detail_formula_value")} type="text" />
                                    </div>
                                    <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_detail_formula_order")}</div>
                                    <div className="admin-detail-box-input-wrap">
                                        <input onChange={(e) => this.onChangeProperty("visualization", e.target?.value)} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_detail_formula_order")} type="number" />
                                    </div>
                                    <div onClick={() => this.saveNewFormula()} className="button-boxed-widjet" style={{ "marginTop": "2em" }}>{TranslationAgent.localizeString("save")}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddFormulaView