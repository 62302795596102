import React, { Component } from 'react'
import TranslationAgent from '../translations/TranslationAgent'
import ColoredTableComponent from './ColoredTableComponent'
import EncryptStorage from './../storage/EncryptStorage'

class ImportZipResultComponent extends Component {
  constructor(props) {
    super(props);
  }
  render() {

    const headers = [TranslationAgent.localizeString("error_file_name")]

    return (
      <div class="modal" id="ImportZipResultComponent" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" style={{fontWeight:"bold"}}>{this.props.modalTitle}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                document.getElementById("ImportZipResultComponent").style.display = "none";
              }}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <ColoredTableComponent rows={this.props.errorFiles} headers={headers} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ImportZipResultComponent;
