import React, { Component } from 'react'
import TranslationAgent from './../../translations/TranslationAgent'
import GaugeChartComponent from './../GaugeChartComponent'
import ApiService from './../../services/ApiService'
import { parseJwt } from './../../services/ApiService'
import EncryptStorage from './../../storage/EncryptStorage'

import './../../assets/css/custom/widjets/PercentQMGWidjetComponent.css'

class PercentQMGWidjetComponent extends Component {
    constructor(props) {
        // eslint-disable-next-line
        super(props);
        this.state = {
            percent: 0,
            isLoading: true,
            gaugeValues: [],
        }
    }
    isVisibleAdmin() {

        const b2cToken = JSON.parse(EncryptStorage.getItem("session-data"))?.B2CToken;
        if (b2cToken) {
            const b2cToken = JSON.parse(EncryptStorage.getItem("session-data")).B2CToken;
            if (b2cToken) {
                const parsedToken = parseJwt(b2cToken);
                return parsedToken.idp != null;
            }

            return false;
        }
    }
    componentDidMount() {

        let plantId = EncryptStorage.getItem('requested-plant-location');
        let userId = JSON.parse(EncryptStorage.getItem("session-data"))?.userId
        let role = this.isVisibleAdmin() ? EncryptStorage.getItem("requested-show-mode") : "";
        if (plantId != null) {

            ApiService.getGaugeValues({ UserId: userId, PlantId: plantId }).then((resValues) => {
                this.setState({ gaugeValues: [resValues?.parsed?.minGauge, resValues?.parsed?.targetGauge, resValues?.parsed?.maxGauge] })

                /* ApiCall to update status */
                ApiService.widgetApi({
                    "name": "PercentQMGWidjetComponent",
                    "payload": { PlantId: (+plantId), UserId: userId, Role: role }
                }).then((res) => {
                    if (this.props.onIsReady) { this.props.onIsReady(); }

                    let value = 0;
                    if (res != null) {
                        if (res.parsed != null) {
                            value = res.parsed.cytdThermalElectricPercentage;
                        }
                    }
                    this.setState({ percent: value, isLoading: false });
                });
            })

        }

    }

    render() {
        let title =
            TranslationAgent.localizeString("qmg_gauge_title_1") +
            " " + (window.tellActiveYear()) + " YTD " +
            TranslationAgent.localizeString("qmg_gauge_title_2");

        let leftSegments = [];
        let rightSegments = [];

        let leftOffsetDiff = (this.state.gaugeValues[1] - this.state.gaugeValues[0])/3;        
        leftSegments = [this.state.gaugeValues[0], this.state.gaugeValues[0] + leftOffsetDiff, this.state.gaugeValues[0] + (leftOffsetDiff * 2), this.state.gaugeValues[0] + (leftOffsetDiff * 3)]

        // let rightOffsetDiff = (this.state.gaugeValues[2] - this.state.gaugeValues[1])/3;        
        // rightSegments = [this.state.gaugeValues[1] + rightOffsetDiff, this.state.gaugeValues[1] + (rightOffsetDiff * 2), this.state.gaugeValues[2]]
         
        let segments = [leftSegments[0]/100, leftSegments[1]/100, leftSegments[2]/100, leftSegments[3]/100, this.state.gaugeValues[2]/100]
        let segColors = ["rgb(255, 71, 26)", "rgb(243, 168, 31)","rgb(212, 231, 37)","rgb(122, 218, 44)"]
            
        return (<div ><GaugeChartComponent segColors={segColors} minValue ={this.state.gaugeValues[0]}  maxValue ={this.state.gaugeValues[2]}  segments={segments} showRealPerc={true} title={title} subtitle={""} isLoading={this.state.isLoading} percent={this.state.percent} /></div>)
    }
}

export default PercentQMGWidjetComponent