import React, { Component } from 'react'
import ApiService from '../services/ApiService'
import ColoredTableComponent from '../components/ColoredTableComponent'
import TranslationAgent from '../translations/TranslationAgent'
import '../assets/css/custom/AdminView.css'
import moment from 'moment'
import IconService from '../services/IconService'
import EncryptStorage from './../storage/EncryptStorage'

class AdminView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            isLoadingFiles: true
        }

        window.AdminView = this;
        this.downloadFile = this.downloadFile.bind(this);
    }
    
    base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }


    saveByteArray(reportName, byte, type = "application/pdf") {
        var blob = new Blob([byte], { type: type });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = reportName;
        link.download = fileName;
        link.click();
    };

    downloadFile(fileName, filePath, fileType){            
        ApiService.toggleSpinner(true);
        ApiService.downloadImportedFile({
            FilePath: filePath,
        }).then((res) => {
            var sampleArr = this.base64ToArrayBuffer(res.parsed?.value);
            this.saveByteArray(fileName, sampleArr);
        }).finally(() => {
            ApiService.toggleSpinner(false);
        })
    }

    componentDidMount() {
        /* ApiCall to update status */
        ApiService.getImportDataHistory({ UserId: ApiService.getSessionKey("userId") }).then((res) => { this.setState({ files: (res == null) ? [] : res?.parsed, isLoadingFiles: false }); });
    }

    render() {
        moment.locale("it")
        let filesRows = [];
        this.state.files?.forEach((item, i) => {
            const downloadBtn = item.filePath ? "<button class=\"table-inrow-button\" onclick=\"window.AdminView.downloadFile('" + item.fileName + "', '" + item.filePath +"', '" + item.fileType + "')\">Download</button>" : "";

            const date = new Date(item.uploadDate)
            const offset = new Date().getTimezoneOffset();

            
            if (offset > 0){ // tolgo ore
                item.uploadDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes() + offset, date.getSeconds())
            }
            else{// aggiungo ore
                item.uploadDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes() - offset, date.getSeconds())                
            } 


            filesRows.push([item.fileName, item.fileType, moment(item.fromDate).format("MMMM yyyy"), moment(item.toDate).format("MMMM yyyy"), moment(item.uploadDate).format("DD/MM/yyyy HH:mm:ss"), item.uploadedBy, item.status, downloadBtn]);
        });

        let filesHeaders = [
            TranslationAgent.localizeString("file_name"),
            TranslationAgent.localizeString("file_type"),
            TranslationAgent.localizeString("file_from_date"),
            TranslationAgent.localizeString("file_to_date"),
            TranslationAgent.localizeString("file_upload_date"),
            TranslationAgent.localizeString("file_uploaded_by"),
            TranslationAgent.localizeString("file_status"),
            ""
        ];

        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="admin-title">{TranslationAgent.localizeString("import_title")}</div>
                            </div>
                            <div className="col-md-3" style={{ display: "grid", justifyContent: "right" }} >
                                <img className={"eon-logo-icon"} src={IconService.eon_logo} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="admin-content">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            {!this.state.isLoadingFiles && <div className="button-boxed-widjet" style={{ "marginBottom": "2em" }} onClick={() => { window.location.href = ".#/import_file" }}>{TranslationAgent.localizeString("import_new_data")}</div>}
                                        </div>
                                        <div className="col-md-4">
                                            {!this.state.isLoadingFiles && <div className="button-boxed-widjet" style={{ "marginBottom": "2em" }} onClick={() => { window.location.href = ".#/publish_data" }}>{TranslationAgent.localizeString("publish_data")}</div>}
                                        </div>
                                    </div>
                                    <div className="admin-content-title">{TranslationAgent.localizeString("admin_file_import")}</div>

                                    <ColoredTableComponent rows={filesRows} headers={filesHeaders} isLoading={this.state.isLoadingFiles} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminView