import React, { Component } from 'react'
import DropdownComponent from '../components/DropdownComponent'
import BackButtonComponent from '../components/BackButtonComponent'
import ApiService from '../services/ApiService'
import IconService from '../services/IconService'
import WidgetRenderComponent from '../components/WidgetRenderComponent'
import TranslationAgent from '../translations/TranslationAgent';
import { toast } from 'react-toastify';
import moment from 'moment'
import EncryptStorage from './../storage/EncryptStorage'

import ErrorService from '../services/ErrorService'
import '../assets/css/custom/ChartsView.css'

class PlantsView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pdfUrl: 'https://arxiv.org/pdf/quant-ph/0410100.pdf',
            pdfTitle: 'PDF prova',
            pdfPeriod: 'Gennaio 2020',
            isLoadingPeriods: true,
            periods: [],
            windowWidth: window.innerWidth,
        };

        this.changedReferenceYear = this.changedReferenceYear.bind(this);
        this.changedViewType = this.changedViewType.bind(this);
        this.downloadExcel = this.downloadExcel.bind(this);
    }

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    };

    componentDidMount() {
        /* ApiCall to update status */

        let plantId = this.getPlantId();
        if (plantId != null) {
            ApiService.getReferencePeriods({ PlantId: plantId, UserId: this.getUserId() }).then((res) => {
                this.setState({ periods: (res == null) ? [] : res.parsed, isLoadingPeriods: false });
            });
        }

        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    changedReferenceYear(e) {
        EncryptStorage.setItem('requested-reference-year', e.target.value);
        
        window.App.reloadApp()
    }

    changedViewType(value) {
        EncryptStorage.setItem('requested-view-type', value);
        
        window.App.reloadApp(true)
    }

    getPlantId() {
        let id = EncryptStorage.getItem('requested-plant-location');
        if (id != null) {
            return +id;
        }

        return null;
    }

    getUserId() {
        return JSON.parse(EncryptStorage.getItem("session-data"))?.userId;
    }

    base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }


    saveByteArray(reportName, byte, type = "application/pdf") {
        var blob = new Blob([byte], { type: type });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = reportName;
        link.download = fileName;
        link.click();
    };

    downloadExcel() {
        let viewType = EncryptStorage.getItem('requested-view-type');

        const period = this.state.periods.find((item) => { return item.referencePeriodId == EncryptStorage.getItem("requested-reference-year") })
        const columns = [TranslationAgent.localizeString("month"), TranslationAgent.localizeString("consumes"), TranslationAgent.localizeString("baseline"), TranslationAgent.localizeString("minimal_level"), "", TranslationAgent.localizeString("electro_gauge_title")]
        // Energia Elettrica
        const enELStag = EncryptStorage.getItem("QMG_EletttricaStag-"+ viewType.toString());
        const enELGauge = EncryptStorage.getItem("language") == "it" ? EncryptStorage.getItem("QMG_EletttricaPerc").replace(".", ",") : EncryptStorage.getItem("QMG_EletttricaPerc")
        let enELStagParsed = null;
        let enELData = [];
        if (enELStag) {
            enELStagParsed = JSON.parse(enELStag);
            enELData = enELStagParsed.map((item) => {
                return {
                    Month: EncryptStorage.getItem("language") == "it" ? item.dateIta : item.dateEn,
                    Consumption: EncryptStorage.getItem("language") == "it" ? item.consume.toString().replace(".", ",") : item.consume.toString(),
                    Baseline: EncryptStorage.getItem("language") == "it" ? item.baseLine.toString().replace(".", ",") : item.baseLine.toString(),
                    MinimalThresold: EncryptStorage.getItem("language") == "it" ? EncryptStorage.getItem("QMG_ElettricaStagThresold").replace(".", ",") : EncryptStorage.getItem("QMG_ElettricaStagThresold")
                }
            })
        }

        // Energia Termica
        const enTEStag = EncryptStorage.getItem("QMG_TermicaStag-" + viewType.toString());
        const enTEGauge = EncryptStorage.getItem("language") == "it" ? EncryptStorage.getItem("QMG_TermicaPerc").replace(".", ",") : EncryptStorage.getItem("QMG_TermicaPerc")
        let enTEStagParsed = null;
        let enTEData = [];
        if (enTEStag) {
            enTEStagParsed = JSON.parse(enTEStag);
            enTEData = enTEStagParsed.map((item) => {
                return {
                    Month: EncryptStorage.getItem("language") == "it" ? item.dateIta : item.dateEn,
                    Consumption: EncryptStorage.getItem("language") == "it" ? item.consume.toString().replace(".", ",") : item.consume.toString(),
                    Baseline: EncryptStorage.getItem("language") == "it" ? item.baseLine.toString().replace(".", ",") : item.baseLine.toString(),
                    MinimalThresold: EncryptStorage.getItem("language") == "it" ? EncryptStorage.getItem("QMG_TermicaStagThresold").replace(".", ",") : EncryptStorage.getItem("QMG_TermicaStagThresold")
                }
            })
        }

        // Energia Frigorifera
        const enFRStag = EncryptStorage.getItem("QMG_FrigoriferaStag-" + viewType.toString());
        const enFRGauge = EncryptStorage.getItem("language") == "it" ? EncryptStorage.getItem("QMG_FrigoriferaPerc").replace(".", ",") : EncryptStorage.getItem("QMG_FrigoriferaPerc");
        let enFRStagParsed = null;
        let enFRData = [];
        if (enFRStag) {
            enFRStagParsed = JSON.parse(enFRStag);
            enFRData = enFRStagParsed.map((item) => {
                return {
                    Month: EncryptStorage.getItem("language") == "it" ? item.dateIta : item.dateEn,
                    Consumption: EncryptStorage.getItem("language") == "it" ? item.consume.toString().replace(".", ",") : item.consume.toString(),
                    Baseline: EncryptStorage.getItem("language") == "it" ? item.baseLine.toString().replace(".", ",") : item.baseLine.toString(),
                    MinimalThresold: EncryptStorage.getItem("language") == "it" ? EncryptStorage.getItem("QMG_FrigoriferaStagThresold").replace(".", ",") : EncryptStorage.getItem("QMG_FrigoriferaStagThresold")
                }
            })
        }

        const values = [];

        // Energia Elettrica
        if (enELGauge != "0" || enELData.find((item) => {
            return item.Consumption != "0" || item.Baseline != "0" || item.MinimalThresold != "0"
        })) {
            values.push({
                title: TranslationAgent.localizeString("electric_energy"),
                columns: columns,
                data: enELData,
                gauge: enELGauge
            })
        }

        // Energia Termica
        if (enTEGauge != "0" || enTEData.find((item) => {
            return item.Consumption != "0" || item.Baseline != "0" || item.MinimalThresold != "0"
        })) {
            values.push({
                title: TranslationAgent.localizeString("termic_energy"),
                columns: columns,
                data: enTEData,
                gauge: enTEGauge
            })
        }

        // Energia Frigorifera
        if (enFRGauge != "0" || enFRData.find((item) => {
            return item.Consumption != "0" || item.Baseline != "0" || item.MinimalThresold != "0"
        })) {
            values.push({
                title: TranslationAgent.localizeString("cooling_energy"),
                columns: columns,
                data: enFRData,
                gauge: enFRGauge
            })
        }

        const payload = {
            period: moment(period.startDate).format("MM yyyy") + " / " + moment(period.endDate).format("MM yyyy"),
            type: EncryptStorage.getItem("requested-view-type") == 0 ? TranslationAgent.localizeString("view_type_cumulative") : TranslationAgent.localizeString("view_type_monthly"),
            typeId: EncryptStorage.getItem("requested-view-type"),
            cultureInfo: EncryptStorage.getItem("language") == "it" ? "it-IT" : "en-EN",
            values: values
        }

        ApiService.toggleSpinner(true);

        const typeName = payload.type.replace(" ", "");
        const periodName = payload.period.replace("/", "_").replace(" ", "");
        const excelName = "QMG_" + typeName + "_" + periodName + ".xlsx";

        ApiService.createFileExcelGranted(payload).then((res) => {
            if (res.raw.status === 200) {
                if (res && res.parsed) {
                    var sampleArr = this.base64ToArrayBuffer(res.parsed);
                    this.saveByteArray(excelName, sampleArr, "application/vnd.ms-excel");
                }

            } else {

                let msg = ErrorService.extractError(res);
                if (msg)
                toast.error(msg, {
                    hideProgressBar: true,
                    autoClose: true,
                    draggable: true,
                })
            }
        }).finally(() => {
            ApiService.toggleSpinner(false);
        });
    }

    render() {
        const years = [];
        let currentYearFound = false;
        let currentYearIndex = 0;
        let today = new Date();
        let currentYear = today.getFullYear();
        let currentMonth = today.getMonth();

        if (this.state.periods != null) {
            this.state.periods.forEach((item, i) => {
                years.push({ text: moment(item.startDate).format("MM yyyy") + " / " + moment(item.endDate).format("MM yyyy"), value: item.referencePeriodId });

                if (!currentYearFound) {
                    currentYearIndex = i;

                    if (currentYear >= item.startDate.split('-')[0] && currentYear <= item.endDate.split('-')[0] && currentMonth >= item.startDate.split('-')[1] && currentMonth <= item.endDate.split('-')[1]) {
                        currentYearFound = true;
                    }
                }
            });
        }

        let y = EncryptStorage.getItem('requested-reference-year');
        if (y == null && years.length > 0) {
            EncryptStorage.setItem('requested-reference-year', years[currentYearIndex].value);
            
            window.App.reloadApp()
        }

        let viewTypes = [
            { text: TranslationAgent.localizeString("view_type_cumulative"), value: 0 },
            { text: TranslationAgent.localizeString("view_type_monthly"), value: 1 }];

        let v = EncryptStorage.getItem('requested-view-type');

        if (v == null && viewTypes.length > 0) {
            EncryptStorage.setItem('requested-view-type', viewTypes[0].value);
            
            window.App.reloadApp()
        }

        let excelButton;
        if (this.state.windowWidth > 1000)
            excelButton = <div style={{ fontSize: "1em" }} className="btn button-boxed-widjet-excel" onClick={() => this.downloadExcel()}>
                <img src={IconService.excel_icon} className="smallico-in-button" />
                <span>Download Excel</span>
            </div>;
        else
            excelButton = <div style={{ fontSize: "1em" }} className="btn button-boxed-widjet-excel" onClick={() => this.downloadExcel()}>
                <img src={IconService.excel_icon} className="smallico-in-button" />
            </div>;

        return (
            <div className="content mt-1 slow-intro-fade">
                <div className="container-fluid">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-5" >
                                <div style={{ "display": "inline-block" }}>
                                    <BackButtonComponent backurl={"#/plant"} title={TranslationAgent.localizeString("granted_minimal_quantities")} />
                                </div>
                            </div>
                            <div className="col-md-4" style={{ display: "flex", alignItems: "center" }} >
                                <div style={{ "display": "inline-block" }}>
                                    <DropdownComponent title={TranslationAgent.localizeString("select_ref_period")} items={years} currentValue={EncryptStorage.getItem('requested-reference-year')} changeCallback={this.changedReferenceYear} />
                                </div>
                            </div>
                            <div className="col-md-3" style={{ display: "grid", justifyContent: "right" }} >
                                <img className={"eon-logo-icon"} src={IconService.eon_logo} />
                            </div>

                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12" style={{ textAlign: "right" }}>
                                <div id="groupedViewType" className="pr-4">
                                    {/* <DropdownComponent title={TranslationAgent.localizeString("view_type")} items={viewTypes} currentValue={EncryptStorage.getItem('requested-view-type')} changeCallback={this.changedViewType} /> */}
                                    <div className="btn-group" role="group" aria-label="Basic example">
                                        <button style={{ fontSize: "1em", opacity: EncryptStorage.getItem("requested-view-type") == 1 ? "0.5" : "unset" }} onClick={() => { this.changedViewType(0) }} type="button" className="btn button-boxed-widjet">{TranslationAgent.localizeString("view_type_cumulative")}</button>
                                        <button style={{ fontSize: "1em", opacity: EncryptStorage.getItem("requested-view-type") == 0 ? "0.5" : "unset" }} onClick={() => { this.changedViewType(1) }} type="button" className="btn button-boxed-widjet">{TranslationAgent.localizeString("view_type_monthly")}</button>
                                    </div>
                                    <div className="ml-3 btn-group" role="group" aria-label="Basic example">
                                        {excelButton}
                                    </div>
                                </div>

                            </div>
                        </div>


                        {years.length > 0 && (<WidgetRenderComponent alignment={"center"} title="charts" />)}
                        <div style={{"height":"5em"}}></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PlantsView