import React, { Component } from 'react'
import ApiService from '../services/ApiService'
import BackButtonComponent from '../components/BackButtonComponent'
import LoadingSpinnerComponent from '../components/LoadingSpinnerComponent'
import ColoredTableComponent from '../components/ColoredTableComponent'
import { toast } from 'react-toastify';
import TranslationAgent from '../translations/TranslationAgent'
import ErrorService from '../services/ErrorService'
import IconService from '../services/IconService'
import '../assets/css/custom/PlantDetailView.css'
import EncryptStorage from './../storage/EncryptStorage'

class ReferencePeriodDetailView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isLoadingPeriods: true,
            periods: [],
            data: [],
            processedRows: []
        }

        this.createEditableRow = this.createEditableRow.bind(this);

        this.processPeriodRows = this.processPeriodRows.bind(this);
        this.repartTableColumn = this.repartTableColumn.bind(this);
        this.updateYearValue = this.updateYearValue.bind(this);
        this.changeAnnualValue = this.changeAnnualValue.bind(this);
        this.getInputByCoordinates = this.getInputByCoordinates.bind(this);
        this.changedPeriodItem = this.changedPeriodItem.bind(this);

        window.ReferencePeriodDetailView = this;
    }

    changedPeriodItem(e) {
        let current = window.location.href;
        window.location.href = current.split("=")[0] + "=" + e.target.value;
        this.setState({ data: [], isLoading: true });
        ApiService.getReferencePeriodDetail({ ReferencePeriodId: this.getPeriodId(), UserId: this.getUserId() }).then((res) => {
            this.setState({ data: (res == null) ? [] : res.parsed, isLoading: false });
            this.processPeriodRows();
        });
    }

    getInputByCoordinates(i, j) {
        let res = "input-editable-id-" + i.toString() + "-" + j.toString();
        return document.getElementById(res);
    }

    updateYearValue(node, i, j) {

        if (!node.value) {
            node.value = 0;
        }

        let rows = this.state.processedRows;
        rows[i + 2][j] = this.createEditableRow(i, j, node.value);


        let sum = 0;
        for (let k = 0; k < rows.length - 2; k++) {
            sum += +this.getInputByCoordinates(k, j).value;
        }
        rows[0][j] = "<input onfocus=\"this.value = this.value;\" id=\"input-editable-annual-id-" + (j).toString() + "\" type=\"number\" class=\"invisible-input-style\" value=\"" + (+sum).toFixed(2) + "\" onchange=\"window.ReferencePeriodDetailView.changeAnnualValue('" + (i).toString() + "')\" />"
        this.setState({ processedRows: rows });
    }

    changeAnnualValue(i) {
        this.repartTableColumn(i);
    }

    createEditableRow(i, j, value) {
        return "<input onfocus=\"this.value = this.value;\" id=\"input-editable-id-" + i.toString() + "-" + j.toString() + "\" type=\"number\" class=\"invisible-input-style\" value=\"" + (+value).toFixed(2) + "\" onchange=\"window.ReferencePeriodDetailView.updateYearValue(this, " + i + "," + j + ")\" />";
    }

    createEditableAnnualRow(i, value) {
        return "<input onfocus=\"this.value = this.value;\" id=\"input-editable-annual-id-" + i.toString() + "\" type=\"number\" class=\"invisible-input-style\" value=\"" + (+value).toFixed(2) + "\"/>";

    }

    repartTableColumn(column) {

        let rows = this.state.processedRows;

        let totCol = document.getElementById("input-editable-annual-id-" + column.toString());
        
        let columnTot = totCol.value;
        let perRow = +(columnTot / 12).toFixed(2);
        let rowRepart = [];
        for (let i = 0; i < 12; i++) {
            rowRepart.push(perRow);
        }

        if (columnTot != perRow * 12) {
            rowRepart[11] = columnTot - perRow * 11;
        }

        for (let i = 2; i < rows.length; i++) {
            rows[i][column] = this.createEditableRow(i - 2, column, rowRepart[i - 2]);
        }
        this.setState({ processedRows: rows });

    }

    processPeriodRows() {
        let referencePeriodRows = [];
        let supportRows = [];

        let sums = [0, 0, 0];
        for (let k = 0; k < this.state.data.length; k++) {
            supportRows.push([this.state.data[k].electrical, this.state.data[k].thermal, this.state.data[k].cooling]);
            sums[0] += this.state.data[k].electrical;
            sums[1] += this.state.data[k].thermal;
            sums[2] += this.state.data[k].cooling;
        }


        referencePeriodRows.push([TranslationAgent.localizeString("period_detail_annual_value"), this.createEditableAnnualRow(1, sums[0]), this.createEditableAnnualRow(2, sums[1]), this.createEditableAnnualRow(3, sums[2])]);
        referencePeriodRows.push([TranslationAgent.localizeString("period_detail_repart"),
        "<button class=\"table-inrow-button\" onclick=\"window.ReferencePeriodDetailView.repartTableColumn('1')\">" + TranslationAgent.localizeString("period_detail_repart") + "</button>",
        "<button class=\"table-inrow-button\" onclick=\"window.ReferencePeriodDetailView.repartTableColumn('2')\">" + TranslationAgent.localizeString("period_detail_repart") + "</button>",
        "<button class=\"table-inrow-button\" onclick=\"window.ReferencePeriodDetailView.repartTableColumn('3')\">" + TranslationAgent.localizeString("period_detail_repart") + "</button>"]);

        supportRows.forEach((item, i) => {
            referencePeriodRows.push([
                EncryptStorage.getItem("language") === "en" ? this.state.data[i].periodEn : this.state.data[i].periodIta,
                this.createEditableRow(i, 1, item[0]),
                this.createEditableRow(i, 2, item[1]),
                this.createEditableRow(i, 3, item[2])]);
        });

        this.setState({ processedRows: referencePeriodRows });
    }

    componentDidMount() {
        /* ApiCall to update status */
        ApiService.getReferencePeriods({ PlantId: this.getPlantId(), UserId: this.getUserId() }).then((res) => {
            this.setState({ periods: (res == null) ? [] : res.parsed, isLoadingPeriods: false });

            ApiService.getReferencePeriodDetail({ ReferencePeriodId: this.getPeriodId(), UserId: this.getUserId() }).then((res) => {
                this.setState({ data: (res == null) ? [] : res.parsed, isLoading: false });
                this.processPeriodRows();
            });


        });
    }

    getPeriodId() {
        const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
        return urlParams.get('periodId');
    }

    getPlantId() {
        let id = EncryptStorage.getItem('stored-plantId');
        if (id != null) {
            return +id;
        }

        return -1;
    }

    getUserId() {
        return JSON.parse(EncryptStorage.getItem("session-data"))?.userId;
    }

    saveRefPeriod() {
        const elements = [];

        const payload = {
            UserId: this.getUserId(),
            InputXML: {
                Elements: elements,
            }
        }


        for (let r = 0; r < 12; r++) {
            for (let c = 1; c < 4; c++) {
                elements.push({
                    ReferencePeriodId: this.getPeriodId(),
                    EnergyType: c === 1 ? 'Electrical' : c === 2 ? 'Thermal' : 'Cooling',
                    Date: this.state.data[r].period.split('T')[0],
                    Value: document.getElementById("input-editable-id-" + r + "-" + c).value
                })
            }
        }

        ApiService.toggleSpinner(true);
        ApiService.setReferencePeriodData(payload).then((res) => {
            if (res.raw.status === 200) {
                setTimeout(() => {
                    window.history.back();
                }, 1000);

                toast.success(TranslationAgent.localizeString("reference_period_update_success"), {
                    hideProgressBar: true,
                    autoClose: true,
                    draggable: true,
                })
            } else {
                let msg = ErrorService.extractError(res);
                if (msg)
                    toast.error(msg, {
                        hideProgressBar: true,
                        autoClose: true,
                        draggable: true,
                    })
            }

        }).finally(() => {
            ApiService.toggleSpinner(false);
        })
    }

    render() {


        let referencePeriodHeaders = [
            TranslationAgent.localizeString("period_detail_take_or_pay"),
            TranslationAgent.localizeString("period_detail_electric"),
            TranslationAgent.localizeString("period_detail_termic"),
            TranslationAgent.localizeString("period_detail_cooling"),
        ];

        let periodItems = [];
        this.state.periods.forEach((item, i) => {
            periodItems.push({ "text": item.startDate.split("T")[0] + " / " + item.endDate.split("T")[0], "value": item.referencePeriodId });
        });

        return (
            <div className="content mt-1">
                <div className="container-fluid">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-9">
                                <BackButtonComponent backurl={null} title={TranslationAgent.localizeString("period_detail")} />
                            </div>
                            <div className="col-md-3" style={{ display: "grid", justifyContent: "right" }} >
                                <img className={"eon-logo-icon"} src={IconService.eon_logo} />
                            </div>
                        </div>
                    </div>
                    <div className="admin-content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="admin-content-title">{TranslationAgent.localizeString("reference_period_composition")}</div>
                                {this.state.isLoading && (<LoadingSpinnerComponent />)}
                                {!this.state.isLoading && (
                                    <div>
                                        <ColoredTableComponent rows={this.state.processedRows} headers={referencePeriodHeaders} isLoading={this.state.isLoading} />
                                        <div onClick={() => this.saveRefPeriod()} className="button-boxed-widjet" style={{ "margin": "2em" }}>{TranslationAgent.localizeString("save")}</div>
                                    </div>)}
                                <div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default ReferencePeriodDetailView