import React, { Component } from 'react'
import ApiService from '../services/ApiService'
import BackButtonComponent from '../components/BackButtonComponent'
import ColoredTableComponent from '../components/ColoredTableComponent'
import moment from 'moment'
import DatePickerComponent from '../components/DatePickerComponent'
import TranslationAgent from '../translations/TranslationAgent'
import { toast } from 'react-toastify';
import ErrorService from '../services/ErrorService'
import '../assets/css/custom/PublishDataDetailView.css'
import IconService from '../services/IconService'
import EncryptStorage from './../storage/EncryptStorage'

class PublishDataDetailView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            from: EncryptStorage.getItem("publish-data-from"),
            to: EncryptStorage.getItem("publish-data-to"),
            searchText: EncryptStorage.getItem("publish-search-text"),
            publishedPeriods: [],
            rawPublishedPeriods: [],
            toggleSelect: true,
            refresh: true
        }
        window.PublishDateDetailView = this;
        this.changedDateTo = this.changedDateTo.bind(this);
        this.changedDateFrom = this.changedDateFrom.bind(this);
        this.getPublishedPeriodList = this.getPublishedPeriodList.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.deselectAll = this.deselectAll.bind(this);
        this.refreshState = this.refreshState.bind(this);
    }

    getUserId() {
        return JSON.parse(EncryptStorage.getItem("session-data"))?.userId;
    }

    refreshState(value) {
        this.setState({ refresh: value })
    }

    downloadFile(filePath) {

    }

    componentDidMount() {

    }

    changedDateTo(e) {
        this.setState({
            to: e,
        })

        EncryptStorage.setItem("publish-data-to", e);

    }

    
    onChangeSearchText(e) {
        this.setState({
            searchText: e,
        })

        EncryptStorage.setItem("publish-search-text", e);

    }

    changedDateFrom(e) {
        this.setState({
            from: e,
        })

        EncryptStorage.setItem("publish-data-from", e);
    }

    getPublishedPeriodList() {
        ApiService.toggleSpinner(true);
        ApiService.GetPublishedPeriodList({
            FirstPeriod: this.state.from + "-01",
            LastPeriod: this.state.to + "-01",
            UserId: this.getUserId(),
            SearchedWord: this.state.searchText,
        }).then((res) => {
            let data = [];
            let rawData = [];
            res.parsed?.forEach((item, i) => {
                const sel = "<select id=\"sel-opt-" + i + "\" ><option " + (item.publishData === "Pubblicato" ? "selected" : "") + " value=\"Pubblicato\" >" + TranslationAgent.localizeString("publish_data_opt_publish") + "</option><option " + (item.publishData === "Da Pubblicare" ? "selected" : "") + " value=\"Da Pubblicare\" >" + TranslationAgent.localizeString("publish_data_opt_hide") + "</option></select>"
                const check = "<input type='checkbox' onchange=\" window.PublishDateDetailView.refreshState(true) \" id=\"input-sel-" + i + "\" />"
                data.push([check, item.companyName, item.plantName, moment(item.period).format("MMMM YYYY"), sel]);
                rawData.push([check, item.companyName, item.plantName, item.period, sel]);
            });
            this.setState({ publishedPeriods: data })
            this.setState({ rawPublishedPeriods: rawData })
        }).finally(() => {
            ApiService.toggleSpinner(false);
        })
    }

    publishSelected() {
        const selRows = document.getElementsByTagName("input");
        for (let i = 0; i < selRows.length; i++) {
            const selRow = document.getElementById("input-sel-" + i);
            if (selRow && selRow.checked) {
                document.getElementById("sel-opt-" + i).value = "Pubblicato";
            }
        }
    }

    hideSelected() {
        const selRows = document.getElementsByTagName("input");
        for (let i = 0; i < selRows.length; i++) {
            const selRow = document.getElementById("input-sel-" + i);
            if (selRow && selRow.checked) {
                document.getElementById("sel-opt-" + i).value = "Da Pubblicare";
            }
        }
    }

    selectAll() {
        const selRows = document.getElementsByTagName("input");
        for (let i = 0; i < selRows.length; i++) {
            if (document.getElementById("input-sel-" + i))
                document.getElementById("input-sel-" + i).checked = true;
        }

        this.setState({ toggleSelect: false })
    }


    deselectAll() {
        const selRows = document.getElementsByTagName("input");
        for (let i = 0; i < selRows.length; i++) {
            if (document.getElementById("input-sel-" + i))
                document.getElementById("input-sel-" + i).checked = false;
        }

        this.setState({ toggleSelect: true })

    }

    getVisiblePublishButton() {
        const selRows = document.getElementsByTagName("input");
        for (let i = 0; i < selRows.length; i++) {
            const selRow = document.getElementById("input-sel-" + i);
            if (selRow?.checked)
                return true;
        }

        return false;
    }

    setPublishedPeriodList() {

        ApiService.toggleSpinner(true);
        const selElements = document.getElementsByTagName("select");
        const elements = [];

        for (let i = 0; i < selElements.length; i++) {
            const currSel = document.getElementById("sel-opt-" + i)
            if (currSel) {
                elements.push({
                    CompanyName: this.state.publishedPeriods[i][1],
                    PlantName: this.state.publishedPeriods[i][2],
                    Period: moment(this.state.rawPublishedPeriods[i][3]).format("YYYY-MM-DD").split('T')[0],
                    AvailableFor: currSel.value === "Pubblicato" ? 'U' : 'A'
                })
            }

        }

        const payload = {
            UserId: this.getUserId(),
            InputXML: {
                Elements: elements,
            }
        }

        ApiService.setPublishedPeriodList(payload).then((res) => {
            if (res.raw.status === 200) {
                toast.success(TranslationAgent.localizeString("publish_data_update_success"), {
                    hideProgressBar: true,
                    autoClose: true,
                    draggable: true,
                })
            } else {
                let msg = ErrorService.extractError(res);
                if (msg)
                toast.error(msg, {
                    hideProgressBar: true,
                    autoClose: true,
                    draggable: true,
                })
            }
        })
            .finally(() => {
                ApiService.toggleSpinner(false);
            });
    }

    render() {

        let publishedPeriodHeaders = [
            TranslationAgent.localizeString("publish_data_sel"),
            TranslationAgent.localizeString("publish_data_customer"),
            TranslationAgent.localizeString("publish_data_plant"),
            TranslationAgent.localizeString("publish_data_month"),
            TranslationAgent.localizeString("publish_data_publish"),
        ];


        return (
            <div className="content mt-1">
                <div className="container-fluid">
                    <div className="col-md-12">
                        <div className="row">

                            <div className="col-md-9">

                                <BackButtonComponent backurl={null} title={TranslationAgent.localizeString("publish_data_title")} />
                            </div>
                            <div className="col-md-3" style={{ display: "grid", justifyContent: "right" }} >
                                <img className={"eon-logo-icon"} src={IconService.eon_logo} />
                            </div>
                        </div>
                    </div>
                    <div className="admin-content">
                        <div className="row">
                            <div className="col-md-12">
                                <div style={{ "display": "inline-block" }}>
                                    <DatePickerComponent mode={"month"} currentValue={this.state.from} title={TranslationAgent.localizeString("publish_from")} requestMaxDate={this.state.to} changeCallback={this.changedDateFrom} />
                                </div>
                                <div style={{ "display": "inline-block" }}>
                                    <DatePickerComponent mode={"month"} requestMinDate={this.state.from} currentValue={this.state.to} title={TranslationAgent.localizeString("publish_to")} changeCallback={this.changedDateTo} />
                                </div>
                                <div className="form-group eon-select-wrap" style={{ "display": "inline-block" }}>
                                    <label className="eon-select-title" htmlFor="searchText">{TranslationAgent.localizeString("search_text_publish_data")}</label>
                                    <input className="form-control eon-select" id="searchText" defaultValue={this.state.searchText} onChange={(e) => {this.onChangeSearchText(e.currentTarget.value)}} />
                                </div>
                                <div style={{ "display": "inline-block" }}>
                                    <div onClick={() => this.getPublishedPeriodList()} className="button-boxed-widjet" style={{ "marginBottom": "2em" }}>{TranslationAgent.localizeString("search")}</div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            {
                                this.state.publishedPeriods.length > 0 ?

                                    <>
                                        {this.state.toggleSelect ?
                                            <div className="col-md-2 pr-0">
                                                <div onClick={() => this.selectAll()} className="button-boxed-widjet" style={{ "marginBottom": "2em" }}> {TranslationAgent.localizeString("publish_data_select_all")}</div>
                                            </div> :
                                            <div className="col-md-2 pr-0">
                                                <div onClick={() => this.deselectAll()} className="button-boxed-widjet" style={{ "marginBottom": "2em" }}> {TranslationAgent.localizeString("publish_data_deselect_all")}</div>
                                            </div>
                                        }
                                    </>
                                    : <div className="col-md-2"></div>
                            }
                            {
                                this.getVisiblePublishButton() ?
                                    <>

                                        <div className="col-md-2">
                                            <div onClick={() => this.publishSelected()} className="button-boxed-widjet" style={{ "marginBottom": "1em" }}>{TranslationAgent.localizeString("publish_data_action")}</div>
                                        </div>
                                        <div className="col-md-2">
                                            <div onClick={() => this.hideSelected()} className="button-boxed-widjet" style={{ "marginBottom": "1em" }}> {TranslationAgent.localizeString("hide_data_action")}</div>
                                        </div>
                                    </> : <div className="col-md-4"></div>

                            }
                            <div className="col-md-4"></div>
                            <div className="col-md-2">
                                <div onClick={() => this.setPublishedPeriodList()} className="button-boxed-widjet" style={{ "marginBottom": "2em" }}>{TranslationAgent.localizeString("save")}</div>
                            </div>
                        </div>
                        <ColoredTableComponent rows={this.state.publishedPeriods} headers={publishedPeriodHeaders} isLoading={this.state.isLoadingFiles} />
                    </div>
                </div>
            </div>
        )
    }
}

export default PublishDataDetailView