import React, { Component } from 'react'
import WidgetRenderComponent from '../components/WidgetRenderComponent'
import TranslationAgent from '../translations/TranslationAgent'
import DropdownComponent from '../components/DropdownComponent'
import ApiService from '../services/ApiService'
import '../assets/css/custom/DashboardView.css'
import IconService from '../services/IconService'
import { parseJwt } from './../services/ApiService'
import BackButtonComponent from '../components/BackButtonComponent'
import EncryptStorage from './../storage/EncryptStorage'

class DashboardView extends Component {
  constructor(props) {
    super(props);
    this.state = {
        companies: [],
    }

    this.changedCompany = this.changedCompany.bind(this);
    this.changeShowMode = this.changeShowMode.bind(this);

  }
  getUserId() {
    return JSON.parse(EncryptStorage.getItem("session-data"))?.userId;
  }

  getCompanyId() {
    return JSON.parse(EncryptStorage.getItem("session-data"))?.companyId;
  }

  changedCompany(e) {
    EncryptStorage.removeItem('can-see-economics')
    EncryptStorage.setItem('requested-company-location', e.target.value);
    EncryptStorage.removeItem('requested-reference-year');
    if (EncryptStorage.getItem('requested-company-location')) {
      ApiService.widgetEconomicsVisibility({ UserId: this.getUserId(), CompanyId: 
        this.isVisibleAdmin() ? EncryptStorage.getItem('requested-company-location') : this.getCompanyId() }).then((res) => {
        EncryptStorage.setItem('can-see-economics', res?.parsed?.showEconomicsWidget);
        ApiService.getRegisteredPlantsList({ CompanyId: e.target.value }).then((res) => {
          this.setState({ plants: (res == null) ? [] : res.parsed });

          let plantId = res?.parsed[0]?.plantId;
          EncryptStorage.setItem("requested-plant-location", plantId);
          EncryptStorage.setItem("requested-plant-location-billing", plantId);
        })
      }).finally(() => {
          window.App.reloadApp();
      })
    }
  }

  changeShowMode(e) {
    EncryptStorage.setItem('requested-show-mode', e.target.value);
    window.App.reloadApp();
  }


    componentDidMount() {

    if (!window.testIsSuperAdmin()) {
        let en = EncryptStorage.getItem('requested-show-mode');
        if (en == null) {
            EncryptStorage.setItem('requested-show-mode', 'A');
        }
    }

     window.getActivePeriod();

    if (JSON.parse(EncryptStorage.getItem("session-data"))?.isEon) {
        ApiService.getCustomerAnagraphics({ UserId: this.getUserId() }).then((res) => { this.setState({ companies: (res == null) ? [] : res.parsed }); })
     }

    if (window.testIsSuperAdmin()) {
        EncryptStorage.setItem('requested-show-mode', 'U');
    }


    setTimeout(() => {

      if (!this.isVisibleAdmin() && window.innerWidth > 1000) {
        let element1 = document.getElementById("MappaImpiantiWidjetComponent-TabellaImpiantiWidjetComponent");
        let element2 = document.getElementById("RecapSavingsWidjetComponent-RecapEconomicsWidjetComponent");
        if (element1 && element2) {
          element1.style.top = "-4em";
          element2.style.top = "-4em";
        }
      }
      else {
        let element1 = document.getElementById("MappaImpiantiWidjetComponent-TabellaImpiantiWidjetComponent");
        let element2 = document.getElementById("RecapSavingsWidjetComponent-RecapEconomicsWidjetComponent");
        if (element1 && element2) {
          element1.style.top = "-1.5em";
          element2.style.top = "-2.5em";
        }
      }
      window.onresize = () => {
        if (!this.isVisibleAdmin() && window.innerWidth > 1000) {
          let element1 = document.getElementById("MappaImpiantiWidjetComponent-TabellaImpiantiWidjetComponent");
          let element2 = document.getElementById("RecapSavingsWidjetComponent-RecapEconomicsWidjetComponent");
          if (element1 && element2) {
            element1.style.top = "-4em";
            element2.style.top = "-4em";
          }
        }
        else {
          let element1 = document.getElementById("MappaImpiantiWidjetComponent-TabellaImpiantiWidjetComponent");
          let element2 = document.getElementById("RecapSavingsWidjetComponent-RecapEconomicsWidjetComponent");
          if (element1 && element2) {
            element1.style.top = "-1.5em";
            element2.style.top = "-2.5em";
          }
        }
      }
    }, 500);
  }


  isVisibleAdmin() {

    const b2cToken = JSON.parse(EncryptStorage.getItem("session-data"))?.B2CToken;
    if (b2cToken) {
      const b2cToken = JSON.parse(EncryptStorage.getItem("session-data")).B2CToken;
      if (b2cToken) {
        const parsedToken = parseJwt(b2cToken);
        return parsedToken.idp != null;
      }

      return false;
    }
  }

  onChangeShow(value) {

  }

  render() {

    const companies = [];
    if (this.state.companies) {
      this.state.companies?.forEach((item, i) => {
        companies.push({ text: item.companyName, value: item.companyId });
      })
    }

    if (!EncryptStorage.getItem('requested-company-location') && companies.length > 0) {
      EncryptStorage.setItem('requested-company-location', companies[0].value);
      ApiService.widgetEconomicsVisibility({ UserId: this.getUserId(), CompanyId: this.isVisibleAdmin() ? EncryptStorage.getItem('requested-company-location') : this.getCompanyId() }).then((res) => {
        EncryptStorage.setItem('can-see-economics', res?.parsed?.showEconomicsWidget);
      }).finally(() => {
          window.App.reloadApp();
      })
    }
    else {
      ApiService.widgetEconomicsVisibility({ UserId: this.getUserId(), CompanyId: this.isVisibleAdmin() ? EncryptStorage.getItem('requested-company-location') : this.getCompanyId() }).then((res) => {
        EncryptStorage.setItem('can-see-economics', res?.parsed?.showEconomicsWidget);
      }).finally(() => {
      })
    }

    const showMode = [
      {
        value: "A",
        text: TranslationAgent.localizeString("show_only_published_mode_1")
      },
      {
        value: "U",
        text: TranslationAgent.localizeString("show_only_published_mode_2")
      }
    ]

    window.addEventListener("resize", () => {
      let selCompany = document.getElementById("select-company");
      let selRepCompany = document.getElementById("replace-select-company");
      if (window.innerWidth < 1025) {
          if (selCompany) {
              selCompany.classList.add("col-md-4")
              selCompany.classList.remove("col-md-3")
          }
          if (selRepCompany) {
              selRepCompany.classList.add("col-md-2")
              selRepCompany.classList.remove("col-md-3")
          }
      }
      else{
          if (selCompany) {
              selCompany.classList.remove("col-md-4")
              selCompany.classList.add("col-md-3")
          }
          if (selRepCompany) {
              selRepCompany.classList.remove("col-md-2")
              selRepCompany.classList.add("col-md-3")
          }
      }

  });

      let seeAdmin = JSON.parse(EncryptStorage.getItem("session-data"))?.isEon && this.isVisibleAdmin();


    return (
      <div className="content mt-1 fast-intro-fade">
        <div className="container-fluid">
                <div className="marginOnLargeScreens">
                    {seeAdmin ?
                        <>
                            <div className="row">
                                <div className="col-md-1" style={{ "opacity": "0" }}>
                                    <BackButtonComponent backurl={"#/home"} title={""} style={{"cursor": "unset"}}/>
                                </div>
                                <div id="select-company" className={window.innerWidth < 1025 ? "col-md-4" : "col-md-3"}>
                                    <DropdownComponent id={"id-select-company"} title={TranslationAgent.localizeString("select_company")} items={companies} currentValue={EncryptStorage.getItem('requested-company-location')} changeCallback={this.changedCompany} />
                                </div>
                                {!window.testIsSuperAdmin() && (
                                    <div className="col-md-3">
                                        <DropdownComponent title={TranslationAgent.localizeString("show_only_published_data")} items={showMode} currentValue={EncryptStorage.getItem('requested-show-mode')} changeCallback={this.changeShowMode} />
                                    </div>  
                                )}
                                <div id="replace-select-company" className={window.innerWidth < 1025 ? "col-md-4" : "col-md-3"}>
                                </div>
                                <div className="col-md-3" style={{ display: "grid", justifyContent: "right" }} >
                                    <img className={"eon-logo-icon"} src={IconService.eon_logo} />
                                </div>
                            </div>
                        </>
                        :

                        <>
                            <div className="row">
                                <div className="col-md-9">
                                </div>
                                <div className="col-md-3" style={{ display: "grid", justifyContent: "right" }}>
                                    <img className={"eon-logo-icon"} src={IconService.eon_logo} />
                                </div>
                            </div>
                        </>
                    }
                </div>
                {!seeAdmin && (<div style={{"marginTop": "6em"}}></div>)}
          <div className="col-md-12">
                <WidgetRenderComponent alignment="baseline" title="home" waitWidjets={true}/>
          </div>
        </div>
      </div>
    )
  }
}

export default DashboardView