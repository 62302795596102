import React, { Component } from 'react'
import LoadingSpinnerComponent from './../LoadingSpinnerComponent'
import ApiService from './../../services/ApiService'
import SmallBoxedWidjetComponent from './SmallBoxedWidjetComponent'
import TranslationAgent from './../../translations/TranslationAgent'
import {parseJwt} from './../../services/ApiService'
import './../../assets/css/custom/widjets/SavingsTotalWidjetComponent.css'
import EncryptStorage from './../../storage/EncryptStorage'


class FakeWidjetComponent extends Component {
    constructor(props) {
        // eslint-disable-next-line
        super(props);
        this.state = {
        }
    }


    componentDidMount() {
        setTimeout(() => {
            if (this.props.onIsReady) { this.props.onIsReady(); }
        }, 300);
    }

    render() {
        return (
            <div>
                
            </div>
        )
    }
}

export default FakeWidjetComponent