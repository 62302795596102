import React, { Component } from 'react';
import ReactSpeedometer from 'react-d3-speedometer'
import EncryptStorage from './../storage/EncryptStorage'

import LoadingSpinnerComponent from './LoadingSpinnerComponent';

import './../assets/css/custom/GaugeChartComponent.css'
import './../assets/css/custom/LoadingSpinnerComponent.css'

class GaugeChartComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            windowWidth: window.innerWidth,
        };
    }

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    };

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    render() {

        let ringWidth = this.state.windowWidth > 1200 ? 60 : 30;
        let title = <div className="gauge-title">{this.props.title}</div>
        let headTitle = this.state.windowWidth > 450 ? title : <></>;
        let footTitle = this.state.windowWidth > 450 ? <></> : <div className="subtitle-boxed-widget">{this.props.title}</div>;
        let style = {
            textAlign: "start",
            
        }
        return (
            <div>
                {this.props.isLoading && (<LoadingSpinnerComponent />)}
                {!this.props.isLoading && (
                    <div className="gauge-wrap ">
                        <div>
                            {
                                this.props.showRealPerc ? (
                                    <div>
                                        {headTitle}
                                        <div id="gaugeThermElect">
                                            <ReactSpeedometer id={'_' + Math.random().toString(36).substr(2, 9)}
                                                value={+parseInt(this.props.percent) >= this.props.maxValue ? this.props.maxValue / 100 : parseInt(this.props.percent) <= this.props.minValue ? this.props.minValue / 100 : (this.props.percent / 100)}
                                                minValue={this.props.minValue / 100}
                                                maxValue={this.props.maxValue / 100}
                                                height={180}
                                                currentValueText={Intl.NumberFormat(EncryptStorage.getItem("language")).format((this.props.percent).toString()) + "%"}
                                                customSegmentStops={this.props.segments}
                                                valueFormat=".0%"
                                                segmentColors={this.props.segColors}
                                                ringWidth={ringWidth}
                                            />
                                        </div>
                                        {footTitle}
                                    </div>
                                )
                                    :
                                    this.props.percent >= 50 && this.props.percent <= 150 ?
                                        <div>
                                            {title}
                                            <ReactSpeedometer id={'_' + Math.random().toString(36).substr(2, 9)}
                                                value={+((parseInt(this.props.percent) >= 150 ? 1.5 : (this.props.percent / 100)))}
                                                minValue={0.5}
                                                maxValue={1.5}
                                                height={180}
                                                currentValueText={Intl.NumberFormat(EncryptStorage.getItem("language")).format(this.props.percent.toFixed(2)) + "%"}
                                                customSegmentStops={[0.5, 0.75, 1, 1.25, 1.5]}
                                                valueFormat=".0%"
                                                segmentColors={["rgb(255, 71, 26)", "rgb(243, 168, 31)", "rgb(212, 231, 37)", "rgb(122, 218, 44)"]}
                                                ringWidth={ringWidth}
                                            />
                                        </div>
                                        :
                                        <div>
                                            {title}
                                            <ReactSpeedometer id={'_' + Math.random().toString(36).substr(2, 9)}
                                                value={+((parseInt(this.props.percent) >= 200 ? 2 : (this.props.percent / 100)))}
                                                minValue={0}
                                                maxValue={2}
                                                height={180}
                                                currentValueText={Intl.NumberFormat(EncryptStorage.getItem("language")).format(this.props.percent.toFixed(2)) + "%"}
                                                customSegmentStops={[0, 0.50, 1, 1.50, 2]}
                                                valueFormat=".0%"
                                                segmentColors={["rgb(255, 71, 26)", "rgb(243, 168, 31)", "rgb(212, 231, 37)", "rgb(122, 218, 44)"]}
                                                ringWidth={ringWidth}
                                            />
                                        </div>
                            }

                        </div>
                        <div className="gauge-subtitle">{this.props.subtitle}</div>
                    </div>
                )}
            </div>

        )
    }
}
export default GaugeChartComponent;
