import React, { Component } from 'react'
import { renderToString } from 'react-dom/server'
import EncryptStorage from './../storage/EncryptStorage'

class DropdownComponent extends Component {
    constructor(props) {
        super(props);
    }
    render() {

        let firstElement = this.props.items.length > 0 ? this.props.items[0].value : null;

        let defaultOption = (this.props.currentValue != null && this.props.currentValue != undefined) ? this.props.currentValue : firstElement;

        let options = []
        this.props.items.forEach(function (item, i) {
            options.push(<option selected={item.value == defaultOption} key={item.value} value={item.value}>{item.text}</option>);
        })

        let optionsAsString = renderToString(options);

        return (
            <div className="form-group eon-select-wrap">
                <label className="eon-select-title" htmlFor="date">{this.props.title}</label>
                <select className="form-control eon-select" id={this.props.id ? this.props.id : "date"}  defaultValue={defaultOption} dangerouslySetInnerHTML={{ __html: optionsAsString }} onChange={this.props.changeCallback}></select>
            </div>
        )
    }
}

export default DropdownComponent