import React, { Component } from 'react'
import ColoredTableComponent from './../ColoredTableComponent'
import { toast } from 'react-toastify';
import ApiService from './../../services/ApiService'
import ErrorService from './../../services/ErrorService'
import IconService from './../../services/IconService'
import TranslationAgent from './../../translations/TranslationAgent'
import {parseJwt} from './../../services/ApiService'
import EncryptStorage from './../../storage/EncryptStorage'

import './../../assets/css/custom/widjets/DettaglioIndiciTariffeWidjetComponent.css'

class DettaglioIndiciTariffeWidjetComponent extends Component {
    constructor(props) {
        // eslint-disable-next-line
        super(props);

        this.state = {
            isLoading: true,
            data: [],
        }
        this.testValidPeriod = this.testValidPeriod.bind(this);
        this.processSinglePlantData = this.processSinglePlantData.bind(this);
    }

    isVisibleAdmin() {

        const b2cToken = JSON.parse(EncryptStorage.getItem("session-data"))?.B2CToken;
        if (b2cToken) {
            const b2cToken = JSON.parse(EncryptStorage.getItem("session-data")).B2CToken;
            if (b2cToken) {
                const parsedToken = parseJwt(b2cToken);
                return parsedToken.idp != null;
            }

            return false;
        }
    }

    testValidPeriod() {
        let from = EncryptStorage.getItem('requested-max-range-from');
        let to = EncryptStorage.getItem('requested-max-range-to');

        function monthDiff(d1, d2) {
            var months;
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth();
            months += d2.getMonth();
            return months <= 0 ? 0 : months;
        }

        if (from != null && to != null) {
            return monthDiff(new Date(from), new Date(to)) < 12;
        }

        return true;
    }

    componentDidMount() {

        let plantsIds = [];

        let pid = EncryptStorage.getItem('requested-plant-location-billing')
        if (pid != null) {

            if (+pid == -1) {
                let tmp = JSON.parse(EncryptStorage.getItem('all-plants-ids'));
                tmp.forEach((item, i) => {
                    plantsIds.push(item.plantId);
                });
            } else {
                plantsIds.push((+pid));
            }

            let userId = JSON.parse(EncryptStorage.getItem("session-data"))?.userId
            let periodId = EncryptStorage.getItem('requested-reference-year-index');
            let role = this.isVisibleAdmin() ? EncryptStorage.getItem("requested-show-mode") : "";

            if (periodId != null) {

                if (!this.testValidPeriod()) {
                    setTimeout(() => {
                        toast.error(TranslationAgent.localizeString("max_period_12m"), {
                            hideProgressBar: true,
                            autoClose: true,
                            draggable: true,
                        })
                    }, 1000);
                    return;
                }

                ApiService.toggleSpinner(true)
                /* ApiCall to update status */
                ApiService.widgetApi({
                    "customPeriod": {
                        "FirstPeriod": EncryptStorage.getItem('requested-max-range-from'),
                        "LastPeriod": EncryptStorage.getItem('requested-max-range-to'),
                        "IsCustom": true
                    },
                    "ReferencePeriodId": (+periodId),
                    "name": "DettaglioIndiciTariffeWidjetComponent",
                    "payload": { UserId: userId, PlantsIds: plantsIds, Role: role }
                }).then((res) => {
                    if (this.props.onIsReady) { this.props.onIsReady(); }
                    this.setState({ data: (res == null) ? [] : res.parsed, isLoading: false });
                })
                    .finally(() => {
                        ApiService.toggleSpinner(false);
                    })
            }
        }

    }

    base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    saveByteArray(reportName, byte, type = "application/pdf") {
        var blob = new Blob([byte], { type: type });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = reportName;
        link.download = fileName;
        link.click();
    };



    processSinglePlantData(plant) {

        let tmp = JSON.parse(EncryptStorage.getItem('all-plants-ids'));

        if (plant == null || tmp == null) {
            return <p></p>;
        }

        if (plant.dettagli == null) {

            let name = "";
            tmp.forEach((item, i) => {
                if (item.plantId == plant.plantId) {
                    name = item.plantName;
                }
            });

            return <p style={{ "background": "var(--eon-dark-red)", "color": "white", "padding": "0.2em 1em", "borderRadius": "0.5em", "fontSize": "0.9em" }}>{TranslationAgent.localizeString("no_data_for") + " " + name}</p>;
        }

        let rows = [];
        let extrarows = (plant.rata.values) ? [[
            TranslationAgent.localizeString('rate'),
            ""
        ]] : [[]];


        if (plant.rata.values) {
            plant.rata.values.forEach((item, i) => {
                extrarows[0].push(item);
            })
        }


        let headers = [
            plant.dettagli[1]?.plantName,
            "Rif."
        ];

        if (plant.dettagli[0]?.values != null) {
            plant.dettagli[0]?.values.forEach((item, i) => {
                headers.push(item);
            });
        }


        plant.dettagli.forEach((item, i) => {
            if (i != 0) {

                let row = [item.rateName, item.rif];

                // ISTAT 1 decimale
                if (item.rateName && (item.rateName.toString().indexOf("ISTAT") >= 0 || item.rateName.toString().indexOf("Indice") >= 0)) {
                    if (item.values != null) {
                        item.values.forEach((item, i) => {
                            if (item && item != "0.00")
                                row.push(Intl.NumberFormat(EncryptStorage.getItem("language"), { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(item));
                            else {
                                row.push("-")
                            }
                        });
                    } else {
                        for (let i = 0; i < 12; i++) {
                            row.push("-")
                        }
                    }
                }
                // Quote Fixing no decimali
                else if (item.rateName && item.rateName.toString().indexOf("Quote Fixing") >= 0) {
                    if (item.values != null) {
                        item.values.forEach((item, i) => {
                            if (item && item != "0.00")
                                row.push(Intl.NumberFormat(EncryptStorage.getItem("language"), { maximumFractionDigits: 0 }).format(item * 100));
                            else {
                                row.push("-")
                            }
                        });

                    } else {
                        for (let i = 0; i < 12; i++) {
                            row.push("-")

                        }
                    }
                }
                // Tariffe 2 decimali
                else if (item.rateName && item.rateName.toString().indexOf("Tariffa") >= 0) {
                    if (item.values != null) {

                        item.values.forEach((item, i) => {
                            if (item && item != "0.00")
                                row.push(Intl.NumberFormat(EncryptStorage.getItem("language"), { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item));
                            else {
                                row.push("-")
                            }
                        });


                    } else {
                        for (let i = 0; i < 12; i++) {
                            row.push("-")
                        }
                    }
                }
                else if (item.rateName && item.rateName.toString().indexOf("TTF") >= 0) {
                    if (item.values != null) {
                        if (item)
                            item.values.forEach((item, i) => {
                                if (item && item != "0.00")
                                    row.push(Intl.NumberFormat(EncryptStorage.getItem("language"), { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item));
                                else {
                                    row.push("-")
                                }
                            });
                    } else {
                        for (let i = 0; i < 12; i++) {
                            row.push("-")
                        }
                    }
                }
                else {
                    if (item.values != null) {
                        item.values.forEach((item, i) => {
                            if (item && item != "0.00")
                                row.push(Intl.NumberFormat(EncryptStorage.getItem("language"), { maximumFractionDigits: 2 }).format(item));
                            else
                                row.push("-")
                        });
                    } else {
                        for (let i = 0; i < 12; i++) {
                            row.push("-");
                        }
                    }
                }
                rows.push(row);

            }
        });

        let indexHighlight = 0;

        let isCritical = true;
        plant.dettagli.forEach((item, i) => {
            if (i == 0 && item.values != null) {
                item.values.forEach((item2, j) => {
                    if (item2 != null && (j + 2) > indexHighlight) {
                        indexHighlight = (j + 2);
                        if (item2?.split(' ')[1] != '2020') {
                            isCritical = false;
                        }
                    }
                });
            }
        });

        let currentYear = plant.dettagli[0]?.values[0]?.split(' ')[1];

        let find = false;

        let FirstPeriod = EncryptStorage.getItem('requested-max-range-from').split("-")[0];
        let LastPeriod = EncryptStorage.getItem('requested-max-range-to').split("-")[0];

        if (isCritical) {
            indexHighlight = 1000;
        }
        else {

            let inconsistentPeriod = false;
            if (FirstPeriod == LastPeriod) {
                plant.dettagli[0].values.forEach((item, i) => {
                    if (!find && item && currentYear !== item?.split(' ')[1]) {
                        indexHighlight = i + 2;
                        find = true;
                    }
                });
            } else {
                indexHighlight = 0;
                plant.dettagli[0].values.forEach((item, i) => {
                    if (item != null) {
                        indexHighlight++;
                    }
                });
                indexHighlight++;
            }
        }

        return <ColoredTableComponent tableName="DettaglioIndiciTariffe" highLightColumn={indexHighlight} isLoading={this.state.isLoading} headers={headers} extraRows={extrarows} rows={rows} />;
    }

    render() {

        let isCustom = EncryptStorage.getItem('requested-reference-year-index');
        if (isCustom == null) {
            isCustom = -1;
        }

        let titleDivs = [
            <div key={0} style={{ "color": "var(--eon-blue)", "margin": "0 0.5em" }}>{isCustom == -1 ? "Custom" : isCustom == 0 ? TranslationAgent.localizeString("custom_periods_current_year") : TranslationAgent.localizeString("custom_periods_last_year")}</div>,
            <div key={1}>{TranslationAgent.localizeString("visualizzation")}</div>
        ];

        if (EncryptStorage.getItem("language") == "it") {
            titleDivs.reverse();
        }

        let plants = [];
        if (this.state.data != null && this.state.data.length > 0) {
            this.state.data.forEach((item, i) => {
                plants.push(
                    <div style={{ "marginTop": "2em" }} key={i}>
                        {this.processSinglePlantData(item)}
                    </div>
                );
            });
        }



        return (
            <div>
                <div className="index-table-title">
                    <div style={{ "display": "flex" }}>
                        {titleDivs}
                    </div>
                    <div className="general-top-widjet-button-holder">
                        <div>
                            <div className="general-top-widjet-button" onClick={() => {

                                let list = EncryptStorage.getItem("on-page-tables");
                                if (list == null) {
                                    toast.error(TranslationAgent.localizeString("no_tables_found"), {
                                        hideProgressBar: true,
                                        autoClose: true,
                                        draggable: true,
                                    })
                                    return;
                                }
                                let knownTables = JSON.parse(list);



                                let excelPayload = {
                                    Excel: [],
                                    Language: EncryptStorage.getItem("language") == "it" ? "it-IT" : "en-US",
                                };
                                let excelName = "Rates_" + (new Date().toISOString().replace("T", "_").split(".")[0]).toString() + ".xlsx";

                                for (var key in knownTables) {
                                    if (knownTables.hasOwnProperty(key)) {
                                        excelPayload.Excel.push({
                                            Name: key,
                                            Xml: knownTables[key]
                                        });
                                    }
                                }

                                /* ApiCall to update status */
                                ApiService.toggleSpinner(true);
                                ApiService.generateExcelRates(excelPayload).then((res) => {

                                    if (res.raw.status === 200) {

                                        if (res && res.parsed) {
                                            var sampleArr = this.base64ToArrayBuffer(res.parsed);
                                            this.saveByteArray(excelName, sampleArr, "application/vnd.ms-excel");
                                        }

                                    } else {

                                        let msg = ErrorService.extractError(res);
                                            if (msg)
                                            toast.error(msg, {
                                            hideProgressBar: true,
                                            autoClose: true,
                                            draggable: true,
                                        })
                                    }
                                }).finally(() => {
                                    ApiService.toggleSpinner(false);
                                });

                            }}><img src={IconService.excel_icon} className="smallico-in-button" /><span>Download Excel</span></div>
                        </div>
                    </div>
                </div>
                <div style={{ "overflow": "auto" }}>
                    {plants}
                </div>
            </div>
        )
    }
}

export default DettaglioIndiciTariffeWidjetComponent