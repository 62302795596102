

// decription hash to get better performances
const knownDecription = [];


function Encrypt(message = '', key = '') {
    var message = window.CryptoJS.AES.encrypt(message, key);
    return message.toString();
}
function Decrypt(message = '', key = '') {
    try {
        var code = window.CryptoJS.AES.decrypt(message, key);
        var decryptedMessage = code.toString(window.CryptoJS.enc.Utf8);

        return decryptedMessage;

    } catch (e) {
        localStorage.clear();
        return null;
    }
}

function setItem(key, value) {

    // encrypt
    if (process.env.REACT_APP_ENVIRONMENT !== "local" && value != null) {
        value = Encrypt(value.toString(), process.env.REACT_APP_SECRET_KEY);
    }

    localStorage.setItem(key, value);

}

function getItem(key) {

    let value = localStorage.getItem(key);

    if (process.env.REACT_APP_ENVIRONMENT !== "local" && value != null) {

        // this avoids using AES on every read of the encrypted storage
        // and gets better performances overhaul
        for (let i = 0; i < knownDecription.length; i++) {
            if (knownDecription[i].cypher === value) {
                return knownDecription[i].plaintext;
            }
        }

        let cypher = value;
        value = Decrypt(value, process.env.REACT_APP_SECRET_KEY);

        // remember for lather this cypher decription
        knownDecription.push({ "plaintext": value, "cypher": cypher });
    }

    return value;

}

function removeItem(key) {
    localStorage.removeItem(key);
}

function clear() {
    localStorage.clear();
}


const EncryptStorage = {
    setItem,
    getItem,
    removeItem,
    clear
}

window.EncryptStorage = EncryptStorage;

export default EncryptStorage;













