import React, { Component } from 'react'
import LoadingSpinnerComponent from '../components/LoadingSpinnerComponent'
import ApiService from '../services/ApiService'
import TranslationAgent from '../translations/TranslationAgent'
import '../assets/css/custom/LoginView.css'
import EncryptStorage from './../storage/EncryptStorage'

class PostRegistrationView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            message: ""
        }
    }

    componentDidMount() {
        let spl = window.location.href.split("id_token=");
        if (spl.length > 1) {
            let token = spl[1];

            const sessionData = {}
            sessionData["B2CToken"] = token;

            EncryptStorage.setItem("session-data", JSON.stringify(sessionData));

            ApiService.postRegistration().then(res => {
                if (res.raw.status === 200) {
                    setTimeout(() => { window.location.href = "#/login"; }, 100);
                } else {
                    let m = res.parsed.message;
                    if (m == null) {
                        m = res.raw.message;
                    }
                    this.setState({ isLoading: false, message: m });
                }
            });

        } else {
            this.setState({ isLoading: false, message: TranslationAgent.localizeString('cannot_find_b2c_token') });
        }
    }

    render() {
        return (
            <div>
                {this.state.isLoading && (<LoadingSpinnerComponent />)}
                {!this.state.isLoading && (<div style={{
                    "height": "100vh",
                    "textAlign": "center",
                    "padding": "5em"
                }}>{this.state.message}</div>)}
            </div>
        )
    }
}

export default PostRegistrationView