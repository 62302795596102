import React, { Component } from 'react'
import '../assets/css/custom/MainView.css'
import EncryptStorage from './../storage/EncryptStorage'


class StalePageView extends Component {
    render() {
        setTimeout(() => {
            let p = EncryptStorage.getItem('prev-page-is');
            if (p != null) {
                window.location.href = p;
            }
        }, 5);
        return (
            <div>

            </div>
        )
    }
}

export default StalePageView