import React, { Component } from 'react'
import ApiService from '../services/ApiService'
import BackButtonComponent from '../components/BackButtonComponent'
import LoadingSpinnerComponent from '../components/LoadingSpinnerComponent'
import moment from 'moment'
import ErrorService from '../services/ErrorService'
import ColoredTableComponent from '../components/ColoredTableComponent'
import TranslationAgent from '../translations/TranslationAgent'
import '../assets/css/custom/ClientDetailView.css'
import { toast } from 'react-toastify';
import IconService from '../services/IconService'
import EncryptStorage from './../storage/EncryptStorage'

class ClientDetailView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emails: [],
            plants: [],
            customer: null,
            isLoadingTable: true,
            isLoadingEmails: true,
            isLoadingCustomer: true
        }

        window.ClientDetailView = this;

        this.deleteEmail = this.deleteEmail.bind(this);
        this.editEmail = this.editEmail.bind(this);
        this.validateEditButton = this.validateEditButton.bind(this);

    }

    validateEditButton(i) {

        let b = document.getElementById("email-edit-button_" + i.toString());
        let vel = document.getElementById("email-input-" + i.toString());
        if (vel == null || b == null) {
            return;
        }
        let v = vel.value;


        let email = v;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let res = re.test(String(email).toLowerCase());

        if (res) {
            b.disabled = false;
        } else {
            b.disabled = true;
        }
    }

    editEmail(i, userId) {
        let vel = document.getElementById("email-input-" + i.toString());
        if (vel == null) {
            return;
        }
        let v = vel.value;

        ApiService.toggleSpinner(true);
        ApiService.updateUserEmail({
            UpdatingUserId: this.getUserId(),
            UserId: userId,
            NewEmail: v
        }).then((res) => {


            if (res.raw.status === 200) {
                toast.success(TranslationAgent.localizeString("email_edited_success"), {
                    hideProgressBar: true,
                    autoClose: true,
                    draggable: true,
                })
            } else {
                let msg = ErrorService.extractError(res);
                if (msg)
                toast.error(msg, {
                    hideProgressBar: true,
                    autoClose: true,
                    draggable: true,
                })
            }


            ApiService.getAdmittedEmails({
                CompanyId: this.getCompanyId(),
                UserId: this.getUserId()
            }).then((res) => {
                this.setState({ emails: (res == null) ? [] : res?.parsed, isLoadingEmails: false })
            }).finally(() => {
                ApiService.toggleSpinner(false);
            })
        })
    }

    getCompanyId() {
        const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
        return +urlParams.get('companyId');
    }

    getUserId() {
        return JSON.parse(EncryptStorage.getItem("session-data"))?.userId;
    }

    componentDidMount() {

        /* ApiCall to update status */
        ApiService.getPlantAnagraphics({
            CompanyId: this.getCompanyId(),
            UserId: this.getUserId()
        }).then((res) => { this.setState({ plants: (res == null) ? [] : res?.parsed, isLoadingTable: false }) });

        ApiService.getAdmittedEmails({
            CompanyId: this.getCompanyId(),
            UserId: this.getUserId()
        }).then((res) => { this.setState({ emails: (res == null) ? [] : res?.parsed, isLoadingEmails: false }) });

        ApiService.getCustomerProfile({
            CompanyId: this.getCompanyId(),
            UserId: this.getUserId()
        }).then((res) => { this.setState({ customer: (res == null) ? null : res.parsed, isLoadingCustomer: false }); });

    }

    onChangeProperty(propName, value) {
        this.setState(prevState => {
            let cust = Object.assign({}, prevState.customer);
            cust[propName] = value;
            return { customer: cust };
        })
    }

    deleteEmail(userId) {

        ApiService.toggleSpinner(true);
        ApiService.deleteEmail({
            UserId: userId,
            DeletedBy: this.getUserId()
        }).then((res) => {

            if (res.raw.status === 200) {
                toast.success(TranslationAgent.localizeString("email_delete_success"), {
                    hideProgressBar: true,
                    autoClose: true,
                    draggable: true,
                })
            } else {
                let msg = ErrorService.extractError(res);
                if (msg)
                toast.error(msg, {
                    hideProgressBar: true,
                    autoClose: true,
                    draggable: true,
                })
            }

            ApiService.getAdmittedEmails({
                CompanyId: this.getCompanyId(),
                UserId: this.getUserId()
            }).then((res) => {
                this.setState({ emails: (res == null) ? [] : res?.parsed, isLoadingEmails: false });
            }).finally(() => {
                ApiService.toggleSpinner(false);
            })
        })
    }

    render() {

        let anagraphicsRows = [];
        this.state.plants?.forEach((item, i) => {
            anagraphicsRows.push([item.plantName, item.address, "<span>" + item.plantCode + "</span>", moment(item.endDate).format("LL"), "<button class=\"table-inrow-button\" onclick=\"window.location.href = '.#/plant_detail?plantId=" + item.plantId + "' \">" + TranslationAgent.localizeString("details") + "</button>"]);
        });

        let anagraphicsHeaders = [
            TranslationAgent.localizeString("plantname"),
            TranslationAgent.localizeString("plantaddress"),
            TranslationAgent.localizeString("plantcode"),
            TranslationAgent.localizeString("endfurnish"),
            TranslationAgent.localizeString("viewdetail"),
        ];

        let emailsHeaders = [
            TranslationAgent.localizeString("email"),
            ""
        ];

        let emails = [];
        this.state.emails?.forEach((item, i) => {
            let editableEmail = "<input id=\"email-input-" + i.toString() + "\" onkeyup=\"ClientDetailView.validateEditButton(" + i.toString() + ")\" class=\"invisible-input-style\" value=\"" + item.email + "\" type=\"text\" />";
            let buttons = "<button class=\"table-inrow-button\" onclick=\"ClientDetailView.editEmail(" + i.toString() + "," + item.userId.toString() + ")\"; id=\"email-edit-button_" + i.toString() + "\">" + TranslationAgent.localizeString("save_lower") + "</button><span style=\"width:2em;\"></span><button onclick=\"ClientDetailView.deleteEmail(" + item.userId.toString() + ")\"; class=\"table-inrow-button\">" + TranslationAgent.localizeString("erease") + "</button>";
            emails.push([editableEmail, buttons]);
        });

        return (
            <>
                <div className="content mt-1">
                    <div className="container-fluid">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-9">
                                    <div style={{ "display": "inline-block" }}>
                                        <BackButtonComponent backurl={"#/admin"} title={TranslationAgent.localizeString("customer_detail")} />
                                    </div>
                                </div>
                                <div className="col-md-3" style={{ display: "grid", justifyContent: "right" }} >
                                    <img className={"eon-logo-icon"} src={IconService.eon_logo} />
                                </div>
                            </div>
                        </div>
                        <div className="admin-content">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="admin-content-title">{TranslationAgent.localizeString("plant_anagraphic")}</div>
                                    <div className="button-boxed-widjet" style={{ "marginTop": "2em" }} onClick={() => {
                                        window.location.href = ".#/add_new_plant?companyId=" + this.getCompanyId();
                                    }}>{TranslationAgent.localizeString("add_plant")}</div>
                                    <ColoredTableComponent fixHeight={"28em"} rows={anagraphicsRows} headers={anagraphicsHeaders} isLoading={this.state.isLoadingTable} />
                                </div>
                                <div className="col-md-4">
                                    <div className="admin-content-title" style={{ "marginBottom": "4em" }}>{TranslationAgent.localizeString("customers_infos")}</div>

                                    <div>
                                        {this.state.isLoadingCustomer && (<LoadingSpinnerComponent />)}
                                        {!this.state.isLoadingCustomer && (
                                            <div className="admin-detail-box">
                                                <div className="admin-detail-box-title">{TranslationAgent.localizeString("customers_infos_name")}</div>
                                                <div className="admin-detail-box-input-wrap">
                                                    <input onChange={(e) => this.onChangeProperty("companyName", e.target?.value)} maxLength={50} defaultValue={this.state.customer?.companyName} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("customers_infos_name")} type="text" />
                                                </div>
                                                <div className="admin-detail-box-title">{TranslationAgent.localizeString("customers_infos_socialreason")}</div>
                                                <div className="admin-detail-box-input-wrap">
                                                    <input onChange={(e) => this.onChangeProperty("businessName", e.target?.value)} maxLength={50} defaultValue={this.state.customer?.businessName} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("customers_infos_socialreason")} type="text" />
                                                </div>
                                                <div className="admin-detail-box-title">{TranslationAgent.localizeString("customers_infos_sap")}</div>
                                                <div className="admin-detail-box-input-wrap">
                                                    <input onChange={(e) => this.onChangeProperty("sapCode", e.target?.value)} maxLength={10} defaultValue={this.state.customer?.sapCode} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("customers_infos_sap")} type="text" />
                                                </div>
                                                <div className="admin-detail-box-title">{TranslationAgent.localizeString("customers_infos_piva")}</div>
                                                <div className="admin-detail-box-input-wrap">
                                                    <input onChange={(e) => this.onChangeProperty("vatNumber", e.target?.value)} maxLength={50} defaultValue={this.state.customer?.vatNumber} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("customers_infos_piva")} type="text" />
                                                </div>
                                                <div className="admin-detail-box-title">{TranslationAgent.localizeString("customers_infos_legaladdress")}</div>
                                                <div className="admin-detail-box-input-wrap">
                                                    <input onChange={(e) => this.onChangeProperty("registeredOffice", e.target?.value)} maxLength={200} defaultValue={this.state.customer?.registeredOffice} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("customers_infos_legaladdress")} type="text" />
                                                </div>
                                                <div className="admin-detail-box-title">{TranslationAgent.localizeString("plant_infos_show_widget_economics")}</div>
                                                <div className="admin-detail-box-input-wrap">
                                                    <select id="showEconomicsWidget" style={{ "height": "1.9em" }} defaultValue={this.state.customer?.showEconomicsWidget == "S"} className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("plant_infos_show_widget_economics")} type="text">
                                                        <option value="true">{TranslationAgent.localizeString("yes")} </option>
                                                        <option value="false">{TranslationAgent.localizeString("no")} </option>
                                                    </select>
                                                </div>
                                                <div className="button-boxed-widjet" style={{ "marginTop": "2em" }}
                                                    onClick={() => {
                                                        ApiService.toggleSpinner(true);
                                                        ApiService.updateCompany({
                                                            CompanyId: this.getCompanyId(),
                                                            CompanyName: this.state.customer?.companyName,
                                                            BusinessName: this.state.customer?.businessName,
                                                            VATNumber: this.state.customer?.vatNumber,
                                                            SAPCode: this.state.customer?.sapCode,
                                                            RegisteredOffice: this.state.customer?.registeredOffice,
                                                            ShowEconomicsWidget: document.getElementById("showEconomicsWidget").value == "true" ? "S" : "N",
                                                            UpdatedBy: this.getUserId(),
                                                        }).then((res) => {
                                                            if (res.raw.status === 200) {
                                                                EncryptStorage.removeItem("can-see-economics");

                                                                toast.success(TranslationAgent.localizeString("client_save_ok"), {
                                                                    hideProgressBar: true,
                                                                    autoClose: true,
                                                                    draggable: true,
                                                                })
                                                            } else {
                                                                let msg = ErrorService.extractError(res);
                                    if (msg)
                                    toast.error(msg, {
                                                                    hideProgressBar: true,
                                                                    autoClose: true,
                                                                    draggable: true,
                                                                })
                                                            }
                                                        }).finally(() => {
                                                            ApiService.toggleSpinner(false);
                                                        })
                                                    }
                                                    }
                                                > {TranslationAgent.localizeString("save")}
                                                </div>

                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="admin-content-title">{TranslationAgent.localizeString("allowed_emails")}</div>
                                    <div>
                                        {this.state.isLoadingEmails && (<LoadingSpinnerComponent />)}
                                        {!this.state.isLoadingEmails && (
                                            <div style={{ "overflow": "auto" }}>
                                                <div id="new-email-form" className="new-email-form">
                                                    <div id="new-email-button" className="fade-button-holder">
                                                        <div className="button-boxed-widjet" onClick={() => {

                                                            let e1 = document.getElementById("new-email-form");
                                                            let e2 = document.getElementById("new-email-button");
                                                            let e3 = document.getElementById("new-allowed-email-input");

                                                            if (e1 == null || e2 == null || e3 == null) {
                                                                return;
                                                            }

                                                            e1.style.maxHeight = "10em";
                                                            e2.style.maxHeight = "0";
                                                            e3.value = "";
                                                        }}>{TranslationAgent.localizeString("add_allowed_emails")}</div>
                                                    </div>
                                                    <div style={{ "marginLeft": "1em" }}>
                                                        <div className="admin-detail-box-title">{TranslationAgent.localizeString("email")}</div>
                                                        <div className="admin-detail-box-input-wrap" style={{ "maxWidth": "20em" }} >
                                                            <input onKeyUp={() => {

                                                                let eb = document.getElementById("add_email_button");
                                                                let emel = document.getElementById("new-allowed-email-input");
                                                                if (emel == null || eb == null) {
                                                                    return;
                                                                }

                                                                let email = emel.value;
                                                                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                                                let res = re.test(String(email).toLowerCase());

                                                                if (res) {
                                                                    eb.classList.remove("add_email_button_disabled");
                                                                } else {
                                                                    eb.classList.add("add_email_button_disabled");
                                                                }

                                                            }} id="new-allowed-email-input" className="admin-detail-box-input" placeholder={TranslationAgent.localizeString("email")} type="text" />
                                                        </div>
                                                        <div className="button-on-rows">
                                                            <div className="button-boxed-widjet" onClick={() => {

                                                                let e1 = document.getElementById("new-email-form");
                                                                let e2 = document.getElementById("new-email-button");
                                                                let eb = document.getElementById("add_email_button");

                                                                if (e1 == null || e2 == null || eb == null) {
                                                                    return;
                                                                }

                                                                e1.style.maxHeight = "";
                                                                e2.style.maxHeight = "";
                                                                eb.classList.add("add_email_button_disabled");

                                                            }}>{TranslationAgent.localizeString("cancel")}</div>
                                                            <div id="add_email_button" className="button-boxed-widjet add_email_button_disabled" onClick={() => {

                                                                let email = document.getElementById("new-allowed-email-input")?.value;
                                                                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                                                let res = re.test(String(email).toLowerCase());

                                                                if (!res) {
                                                                    return;
                                                                }

                                                                let e1 = document.getElementById("new-email-form");
                                                                let e2 = document.getElementById("new-email-button");

                                                                if (e1 == null || e2 == null) {
                                                                    return;
                                                                }

                                                                e1.style.maxHeight = "";
                                                                e2.style.maxHeight = "";

                                                                ApiService.toggleSpinner(true);
                                                                ApiService.createNewUser({
                                                                    Email: document.getElementById("new-allowed-email-input")?.value,
                                                                    CompanyId: this.getCompanyId(),
                                                                    CreatedBy: this.getUserId()
                                                                }).then((res) => {


                                                                    if (res.raw.status === 200) {
                                                                        toast.success(TranslationAgent.localizeString("email_added_ok"), {
                                                                            hideProgressBar: true,
                                                                            autoClose: true,
                                                                            draggable: true,
                                                                        })
                                                                    } else {
                                                                        let msg = ErrorService.extractError(res);
                                    if (msg)
                                    toast.error(msg, {
                                                                            hideProgressBar: true,
                                                                            autoClose: true,
                                                                            draggable: true,
                                                                        })
                                                                    }



                                                                    ApiService.getAdmittedEmails({
                                                                        CompanyId: this.getCompanyId(),
                                                                        UserId: this.getUserId()
                                                                    }).then((res) => {
                                                                        document.getElementById("add_email_button").classList.add("add_email_button_disabled");
                                                                        this.setState({ emails: (res == null) ? [] : res?.parsed, isLoadingEmails: false });
                                                                    }).finally(() => {
                                                                        ApiService.toggleSpinner(false);
                                                                    })
                                                                })
                                                            }}>{TranslationAgent.localizeString("save")}</div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <ColoredTableComponent headers={emailsHeaders} rows={emails} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }
}

export default ClientDetailView