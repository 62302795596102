import React, { Component } from 'react'
import NavbarComponent from '../components/NavbarComponent'
import SidebarComponent from '../components/SidebarComponent'
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import DashboardView from './DashboardView'
import ChartsView from './ChartsView'
import AdminView from './AdminView'
import LoginView from './LoginView'
import PlantsView from './PlantsView'
import PlantDetailView from './PlantDetailView'
import ClientDetailView from './ClientDetailView'
import InvoicesDetailsView from './InvoicesDetailsView'
import BillingDetailsView from './BillingDetailsView'
import DataImportView from './DataImportView';
import IconsService from './../services/IconService'
import ReferencePeriodDetailView from './ReferencePeriodDetailView'
import FormulasDetailView from './FormulasDetailView'
import ContactCustomerCareWidjetComponent from './../components/widjets/ContactCustomerCareWidjetComponent'
import AddCustomerView from './AddCustomerView'
import AddFormulaView from './AddFormulaView'
import AddPlantView from './AddPlantView'
import PostLoginView from './PostLoginView'
import PostRegistrationView from './PostRegistrationView'
import PublishDataDetailView from './PublishDataDetailView'
import ImportDataView from './ImportDataView'
import AddReferencePeriodView from './AddReferencePeriodView'
import StalePageView from './StalePageView'
import '../assets/css/custom/MainView.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'
import localizationIt from 'moment/locale/it'
import localizationEn from 'moment/locale/en-gb'
import { parseJwt } from './../services/ApiService'
import EncryptStorage from './../storage/EncryptStorage'

class MainView extends Component {
    isVisibleAdmin() {

        const b2cToken = JSON.parse(EncryptStorage.getItem("session-data"))?.B2CToken;
        if (b2cToken) {
            const b2cToken = JSON.parse(EncryptStorage.getItem("session-data")).B2CToken;
            if (b2cToken) {
                const parsedToken = parseJwt(b2cToken);
                return parsedToken.idp != null;
            }

            return false;
        }
    }

    render() {

        let innerPage = true;
        if (window.location.href.indexOf("/post-registration") != -1 ||
            window.location.href.indexOf("/post-login") != -1 ||
            window.location.href.indexOf("/login") != -1) {
            innerPage = false;
        }

        moment.locale(EncryptStorage.getItem("language"),
            EncryptStorage.getItem("language") == 'it' ? localizationIt : localizationEn);

        let mainPanelStyleFixes = {
        }

        let isLoggedIn = EncryptStorage.getItem("logged-in") == "true";
        let showLeftPanel = isLoggedIn && (window.location.href.indexOf("#/login") == -1);

        let currUrl = window.location.href;
        if (currUrl.indexOf("/hash/") != -1) {
            currUrl = currUrl.replace("/hash/", "#");
            window.location.href = currUrl;
            return (<p></p>);
        }

        EncryptStorage.removeItem("on-page-tables");

        return (
            <div className={showLeftPanel ? "" : "login-wrap-spacer"}>
                {showLeftPanel && (<SidebarComponent />)}
                <div className="color-bar">
                    <div className="turquoise"></div>
                    <div className="lime-yellow"></div>
                    <div className="red"></div>
                </div>
                <div className={(window.showExtraPadding ?"extra-end-padder ":"") +  " main-panel" + (innerPage ? "" : " main-panel-nopad")} style={showLeftPanel ? mainPanelStyleFixes : { "overflowY": "hidden" }}>
                    {showLeftPanel && (<NavbarComponent />)}
                    <Router>
                        <Switch>
                            <Route exact path="/login">
                                <LoginView />
                            </Route>

                            <Route exact path="/post-login">
                                <PostLoginView />
                            </Route>

                            <Route exact path="/post-registration">
                                <PostRegistrationView />
                            </Route>

                            {!isLoggedIn && (
                                <Redirect from='*' to='/login' />
                            )}
                            <Route exact path="/home">
                                <DashboardView />
                            </Route>
                            <Route exact path="/plant">
                                <PlantsView />
                            </Route>
                            <Route exact path="/charts">
                                <ChartsView />
                            </Route>
                            <Route exact path="/rates">
                                <BillingDetailsView />
                            </Route>
                            <Route exact path="/invoices">
                                <InvoicesDetailsView />
                            </Route>
                            {

                                (!window.testIsSuperAdmin() && this.isVisibleAdmin()) &&
                                <Route exact path="/customer_detail">
                                    <ClientDetailView />
                                </Route>
                            }
                            {

                                (!window.testIsSuperAdmin() && this.isVisibleAdmin()) && <Route exact path="/plant_detail">
                                    <PlantDetailView />
                                </Route>
                            }
                            {

                                (!window.testIsSuperAdmin() && this.isVisibleAdmin()) &&
                                <Route exact path="/period_detail">
                                    <ReferencePeriodDetailView />
                                </Route>
                            }
                            {
                                (!window.testIsSuperAdmin() && this.isVisibleAdmin()) &&
                                <Route exact path="/add_customer">
                                    <AddCustomerView />
                                </Route>
                            }
                            {

                                (!window.testIsSuperAdmin() && this.isVisibleAdmin()) &&
                                <Route exact path="/edit_formula">
                                    <FormulasDetailView />
                                </Route>
                            }
                            {

                                (!window.testIsSuperAdmin() && this.isVisibleAdmin()) &&
                                <Route exact path="/add_new_formula">
                                    <AddFormulaView />
                                </Route>
                            }
                            {

                                (!window.testIsSuperAdmin() && this.isVisibleAdmin()) &&
                                <Route exact path="/add_new_plant">
                                    <AddPlantView />
                                </Route>
                            }
                            {
                                (!window.testIsSuperAdmin() && this.isVisibleAdmin()) &&
                                <Route exact path="/import_file">
                                    <DataImportView />
                                </Route>
                            }
                            {
                                (!window.testIsSuperAdmin() && this.isVisibleAdmin()) &&
                                <Route exact path="/admin">
                                    <AdminView />
                                </Route>
                            }
                            <Route exact path="/loading">
                                <StalePageView />
                            </Route>
                            {
                                (!window.testIsSuperAdmin() && this.isVisibleAdmin()) &&
                                <Route exact path="/importdata">
                                    <ImportDataView />
                                </Route>
                            }
                            {

                                (!window.testIsSuperAdmin() && this.isVisibleAdmin()) &&
                                <Route exact path="/add_reference_period">
                                    <AddReferencePeriodView />
                                </Route>
                            }
                            {

                                (!window.testIsSuperAdmin() && this.isVisibleAdmin()) &&
                                <Route exact path="/publish_data">
                                    <PublishDataDetailView />
                                </Route>
                            }
                            <Redirect from='*' to='/home' />
                        </Switch>
                    </Router>
                </div>

                {innerPage && (<ContactCustomerCareWidjetComponent />)}

                <ToastContainer />
            </div >

        )
    }
}

export default MainView