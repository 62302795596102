import React, { Component } from 'react'
import TranslationAgent from './../../translations/TranslationAgent'
import ApiService from './../../services/ApiService'
import Chart from "react-apexcharts";
import './../../assets/css/custom/widjets/EnergiaElettricaQMGStagionaleWidjetComponent.css'
import localesIt from 'apexcharts/dist/locales/it.json'
import localesEn from 'apexcharts/dist/locales/en.json'
import { parseJwt } from './../../services/ApiService'
import LoadingSpinnerComponent from '../LoadingSpinnerComponent';
import '../../assets/css/custom/LoadingSpinnerComponent.css'
import EncryptStorage from './../../storage/EncryptStorage'

class EnergiaElettricaQMGStagionaleWidjetComponent extends Component {
    constructor(props) {
        // eslint-disable-next-line
        super(props);
        this.state = {
            isLoading: true,
            data0: [],
            data1: []
        }
    }

    isVisibleAdmin() {

        const b2cToken = JSON.parse(EncryptStorage.getItem("session-data"))?.B2CToken;
        if (b2cToken) {
            const b2cToken = JSON.parse(EncryptStorage.getItem("session-data")).B2CToken;
            if (b2cToken) {
                const parsedToken = parseJwt(b2cToken);
                return parsedToken.idp != null;
            }

            return false;
        }
    }

    componentDidMount() {

        let plantId = EncryptStorage.getItem('requested-plant-location');
        let userId = JSON.parse(EncryptStorage.getItem("session-data"))?.userId
        let periodId = EncryptStorage.getItem('requested-reference-year');
        let enType = "Electrical";
        let viewType = EncryptStorage.getItem('requested-view-type');
        let role = this.isVisibleAdmin() ? EncryptStorage.getItem("requested-show-mode") : "";

        if (plantId != null && periodId != null) {

            /* ApiCall to update status */
            ApiService.widgetApi({
                "viewType": 0,
                "name": "EnergiaElettricaQMGStagionaleWidjetComponent",
                "payload": { Role: role, PlantId: (+plantId), UserId: userId, ReferencePeriodId: (+periodId), EnergyType: enType }
            }).then((res) => {
                if (this.props.onIsReady) { this.props.onIsReady(); }
                this.setState({ data0: (res == null) ? [] : res.parsed, isLoading: false });
                EncryptStorage.setItem("QMG_EletttricaStag-0", JSON.stringify(res.parsed))
            });

            /* ApiCall to update status */
            ApiService.widgetApi({
                "viewType": 1,
                "name": "EnergiaElettricaQMGStagionaleWidjetComponent",
                "payload": { Role: role, PlantId: (+plantId), UserId: userId, ReferencePeriodId: (+periodId), EnergyType: enType }
            }).then((res) => {
                if (this.props.onIsReady) { this.props.onIsReady(); }
                this.setState({ data1: (res == null) ? [] : res.parsed, isLoading: false });
                EncryptStorage.setItem("QMG_EletttricaStag-1", JSON.stringify(res.parsed))
            });
        }
    }


    render() {


        let language = EncryptStorage.getItem("language");
        if (language == null) {
            language = "en";
        }

        let baselines = [];
        let consumes = [];
        let labels = [];

        let viewType = EncryptStorage.getItem('requested-view-type').toString();
        let data = this.state["data" + viewType];
        if (data != null) {
            data.forEach((item, i) => {
                baselines.push(Math.floor(item.baseLine));
                consumes.push(Math.floor(item.consume));
                if (language == "en") {
                    labels.push(item.dateEn);
                } else {
                    labels.push(item.dateIta);
                }
            });
        }



        let findBaseline = false;
        let findConsumes = false;

        if (baselines.find((value) => { return value != 0 })) {
            findBaseline = true;
        }

        if (consumes.find((value) => { return value != 0 })) {
            findConsumes = true;
        }

        const fromAPI = {
            "labels": labels,
        }


        let locales = EncryptStorage.getItem("language") == "it" ? [localesIt] : [localesEn]

        let customLegendItems = [];
        let colors = [];

        if (findConsumes) {
            if (EncryptStorage.getItem('requested-view-type') == 0)
                customLegendItems.push("YTD " + TranslationAgent.localizeString("consumes"))
            else
                customLegendItems.push(TranslationAgent.localizeString("consumes"))
            colors.push('#E4E000')//("#EAE740")
        }

        if (findBaseline) {

            if (EncryptStorage.getItem('requested-view-type') == 0)
                customLegendItems.push("YTD " + TranslationAgent.localizeString("baseline"))
            else
                customLegendItems.push(TranslationAgent.localizeString("baseline"))
            // colors.push("#D3D3D3")
            colors.push("#fffd94")

            if (EncryptStorage.getItem('requested-view-type') == 0) {
                customLegendItems.push(TranslationAgent.localizeString("minimal_level"))
                // colors.push("#F2F088")
                colors.push("#D3D3D3")
            }
        }

        customLegendItems = window.specialLegendFix(customLegendItems);

        let max = 0;
        baselines.forEach((item, i) => {
            if (item > max) {
                max = item;
            }
        });

        EncryptStorage.setItem("QMG_ElettricaStagThresold", JSON.stringify(Math.floor(max)))


        let thresSize = Array.from({ length: 12 }, () => Math.floor(max));
        let series = [];
        if (EncryptStorage.getItem('requested-view-type') == 0) {
            if (findConsumes) {
                series.push({
                    name: TranslationAgent.localizeString("consumes"),
                    data: consumes,
                    type: "column"
                })
            }
            if (findBaseline) {
                series.push({
                    name: TranslationAgent.localizeString("baseline"),
                    data: baselines,
                    type: "column",
                })
                series.push({
                    name: TranslationAgent.localizeString('minimal_level'),
                    type: "line",
                    data: thresSize
                })
            }
        }
        else {
            if (findConsumes) {
                series.push({
                    name: TranslationAgent.localizeString("consumes"),
                    data: consumes,
                    type: "column",
                })
            }
            if (findBaseline) {
                series.push({
                    name: TranslationAgent.localizeString("baseline"),
                    data: baselines,
                    type: "column",
                })

            }
        }

        return (
            <div className="row">
                {this.state.isLoading && (<LoadingSpinnerComponent />)}
                {!this.state.isLoading && (findBaseline || findConsumes) &&
                    <>
                        <div className="col-md-12">
                            <p className="mb-1" style={{ fontWeight: "bold" }}><span>{TranslationAgent.localizeString("energy")} </span><span style={{ color: 'var(--eon-yellow)' }} >{TranslationAgent.localizeString("electric")}</span></p>
                        </div>
                        <div className="col-md-12" style={{ height: "250px" }} >
                            {
                                <Chart type={findBaseline && findConsumes ? "line" : "bar"} width={"90%"} height={"100%"} series={series} options={
                                    {
                                        series: series,
                                        responsive: [
                                            {
                                                breakpoint: 799,
                                                options: {
                                                    plotOptions: {
                                                        bar: {
                                                            horizontal: false,
                                                            columnWidth: 50,
                                                            borderRadius: 1,
                                                        }
                                                    },
                                                }
                                            },
                                            {
                                                breakpoint: 1025,
                                                options: {
                                                    legend: {
                                                        position: "top",
                                                        show: true,
                                                        showForSingleSeries: true,
                                                        customLegendItems: customLegendItems,
                                                    },
                                                    chart: {
                                                        locales: locales,
                                                        stacked: false,
                                                        toolbar: {
                                                            show: false
                                                        },
                                                        defaultLocale: EncryptStorage.getItem("language"),

                                                    },
                                                    tooltip: {
                                                        fixed: {
                                                            enabled: true,
                                                            position: 'bottomRight',
                                                        },
                                                    }
                                                }
                                            }],
                                        stroke: {
                                            width: 1
                                        },
                                    legend: {
                                            width: 150,
                                            position: "right",
                                            show: true,
                                            customLegendItems: customLegendItems,
                                            fontSize: "10px",
                                            showForSingleSeries: true,
                                        },
                                        plotOptions: {
                                            bar: {
                                                horizontal: false,
                                                columnWidth: 50,
                                                borderRadius: 5,
                                            }
                                        },
                                        chart: {
                                            locales: locales,
                                            stacked: false,

                                            toolbar: {
                                                show: false
                                            },
                                            type: "line",
                                            defaultLocale: EncryptStorage.getItem("language"),
                                            zoom: {
                                                enabled: false
                                            },
                                        },
                                        colors: colors,
                                        xaxis: {
                                            type: 'category',
                                            categories: fromAPI.labels.map((item) => {
                                                return (item)
                                            }),
                                            labels: {
                                                rotate: -45,
                                                show: true,
                                                rotateAlways: true
                                            }
                                        },
                                        dataLabels: {
                                            enabled: false,
                                            style: {
                                                colors: ["#000000", "#000000", "#000000"],
                                                fontWeight: "bolder"
                                            },
                                            formatter: function (value) {
                                                return Intl.NumberFormat(EncryptStorage.getItem("language"), { maximumFractionDigits: 2 }).format(value);
                                            },

                                        },
                                        yaxis: {
                                            labels: {
                                                formatter: function (value) {
                                                    return Intl.NumberFormat(EncryptStorage.getItem("language"), { maximumFractionDigits: 2 }).format(value);
                                                },
                                            },
                                            showAlways: true,
                                            title: {
                                                text: "MWh",
                                                style: {
                                                    fontWeight: "normal",
                                                    fontSize: "13px",
                                                    cssClass: "spacing-y-axis"
                                                }
                                            }
                                        },
                                        fill: {
                                            colors: [function ({ value, seriesIndex, w }) {
                                                if (seriesIndex == 0 && findConsumes) {
                                                    return '#E4E000'//"#EAE740"
                                                } else if ((seriesIndex == 1 && findBaseline) || (seriesIndex == 0 && findBaseline && !findConsumes)) {
                                                    // return '#D3D3D3'
                                                    return '#F2F191'
                                                }

                                            }]
                                        },
                                        states: {
                                            hover: {
                                                filter: {
                                                    type: 'none',
                                                }
                                            },
                                        }
                                    }
                                }></Chart>}
                        </div>
                    </>
                }
            </div>
        )
    }
}
export default EnergiaElettricaQMGStagionaleWidjetComponent