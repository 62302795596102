import React, { Component } from 'react'
import ApiService from '../services/ApiService'
import ColoredTableComponent from '../components/ColoredTableComponent'
import TranslationAgent from '../translations/TranslationAgent'
import IconService from '../services/IconService'
import EncryptStorage from './../storage/EncryptStorage'

import '../assets/css/custom/AdminView.css'

class AdminView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customers: [],
            isLoadingCustomers: true,
        }
    }

    componentDidMount() {
        /* ApiCall to update status */
        ApiService.getCustomerAnagraphics({ UserId: ApiService.getSessionKey("userId") }).then((res) => { this.setState({ customers: (res == null) ? [] : res?.parsed, isLoadingCustomers: false }); });

    }

    render() {

        let anagraphicsRows = [];

        this.state.customers?.forEach((item, i) => {
            anagraphicsRows.push([item.companyName, item.businessName, "<span>" + item.sapCode + "</span>", "<span>" + item.vatNumber + "</span>", "<button class=\"table-inrow-button\" onclick=\"window.location.href = '.#/customer_detail?companyId=" + item.companyId + "' \">" + TranslationAgent.localizeString("details") + "</button>"]);
        });

        let anagraphicsHeaders = [
            TranslationAgent.localizeString("customername"),
            TranslationAgent.localizeString("socialreason"),
            TranslationAgent.localizeString("codicesap"),
            TranslationAgent.localizeString("piva"),
            TranslationAgent.localizeString("viewdetail"),
        ];

        return (
            <div className="content mt-1">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-9">
                            <div className="admin-title">{TranslationAgent.localizeString("admin_title")}</div>
                        </div>
                        <div className="col-md-3" style={{ display: "grid", justifyContent: "right" }} >
                                <img className={"eon-logo-icon"} src={IconService.eon_logo} />
                            </div>
                    </div>
                    <div className="col-md-12">
                        <div className="admin-content">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="admin-content-title">{TranslationAgent.localizeString("customers_anagraphic")}</div>
                                    {!this.state.isLoadingCustomers && <div className="button-boxed-widjet" style={{ "marginBottom": "2em" }} onClick={() => { window.location.href = ".#/add_customer" }}>{TranslationAgent.localizeString("add_new_customer")}</div>}
                                    <ColoredTableComponent rows={anagraphicsRows} headers={anagraphicsHeaders} isLoading={this.state.isLoadingCustomers} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminView